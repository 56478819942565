import React from "react";
// Customizable Area Start

import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { logo, userP, bag, earth } from "./assets";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import { styled, Box, Typography, CircularProgress } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import StarIcon from '@material-ui/icons/Star';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ExpandLess, Star } from "@material-ui/icons";

// Customizable Area End

import ClientLawyerCatalogueController, { Props } from "./ClientLawyerCatalogueController";

export default class ClientLawyerCatalogue extends ClientLawyerCatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  drawer = () => (
    <DrawerBox style={{ display: this.state.mobileOpen ? 'block' : '' }}>
      <div className="toolbar" />
      <Divider />
      <div className="logobox">
        <img src={logo.default} alt="logos7" className="logoimag" />
      </div>
      {this.state.mobileOpen && <CloseIcon style={{ position: 'fixed', top: '30px', left: '260px', cursor: 'pointer' }} onClick={this.handleclosesidebar} />}
      <div className="settingbox" data-test-id="settingid" 
        onClick={this.handleNavigationClientDashboard}>
        <ChevronLeftIcon className="lefticon" />
        <Typography className="settingtext">Dashboard</Typography>
      </div>
          <Box style={{
              backgroundColor: '#ECF1FF',
              padding: '10px 50px',
              display: 'flex',
              gap: '10px',
              alignItems: 'center',
              justifyContent: 'space-between'
          }}>
              <Box style={{display: 'flex',alignItems:'center',gap:'10px'}}>
                  <Box><PermIdentityIcon style={{ color: '#355EE7' }} /></Box>
                  <Box style={{ color: '#355EE7' }}>Lawyer Catalogue</Box>
              </Box>
              <Box><ExpandLess style={{ color: '#355EE7' }} /></Box>
          </Box>
    </DrawerBox>
  )

  topbox = () => {
    return (
        <Box style={{
            margin: '30px 20px 30px 20px',
            display: 'flex',
            gap:'100px',
            alignItems:'center'
        }}>
            <Box style={{ display: 'flex', gap: '10px',alignItems:'center' }}>
                <Box onClick={this.handleNavigation}>
                    <ArrowBackIcon />
                </Box>
                <Box style={{color:'#393737'}}>Back to lawfirm</Box>
            </Box>
            <Box style={{ display: 'flex', gap: '10px',fontSize:'20px' }}>
                <Box>Lawyers Catalogue in</Box>
                <Box style={{color:'#3434d9'}}>{`${this.state.lawfirmName} Law Firm`}</Box>
            </Box>
        </Box>
    );
  };

  buttonMenu = () => (
    <ButtonBox className="buttonbox">
      <IconButton
        className="iconbtn"
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={this.handleDrawerToggle}
        style={{ marginRight: '24px', color: '#000' }}
      >
        <MenuIcon />

      </IconButton>
    </ButtonBox>
  )

  userMultiValues = (attributes: any) => {
    return (
      <div className="userbox2">
        <Box style={{ display: 'flex', marginBottom: '7px', alignItems: 'center' }}>
          <img src={bag} alt="Bag" className="bagCss" />
          <Typography className="servicesLegalCss">Legal Services</Typography>
        </Box>
        <Box style={{ display: 'flex' }}>
          {(!attributes?.legel_service_type || attributes.legel_service_type.length == 0) ? (
            <Typography className="FamilyTextCss" style={{ padding: '3px 5px' }}>
              Family Law
            </Typography>
          ) : (
            <>
              {attributes.legel_service_type.slice(0, 2).map((e: { legal_service_name: string }, i: number) => (
                <Typography key={i} className="FamilyTextCss" style={{ padding: '3px 5px', marginRight: '7px' }}>
                  {e.legal_service_name || "Family Law"}
                </Typography>
              ))}
              {attributes.legel_service_type.length > 2 && (
                <Typography className="FamilyTextCss" style={{ padding: '3px 5px' }}>
                  +{attributes.legel_service_type.length - 2}
                </Typography>
              )}
            </>
          )}
        </Box>
      </div>
    );
  };

  UserMapBox = () => {
    return(
    <Box className="userContainer">
      {this.state.userdata.map((data:any, index:number) => {
        const  attributes  =  data.attributes.associated_lawyer_details?.data.attributes
        return (
          <Box key={index} className="userBoxData" onClick={()=>this.handleDetailsPopUp(index)}>
            <div className="userbox1">
              <Box className="userProfile">
                <Box>
                  <img
                    src={attributes.profile_image?.url || userP}
                    alt="Profile"
                    className="imageBox"
                  />
                </Box>
                <Box style={{ marginTop: '4px' }}>
                  <Typography className="userName">
                    {attributes?.first_name || "Maya Diab"}
                  </Typography>
                  <Typography className="userContent">
                    <span style={{ paddingRight: '4px' }}>
                      {attributes?.firm || "Perly Lawfirm"}
                    </span>
                    <StarIcon className="starIconCss" />
                    <span style={{ paddingLeft: '15px', color: '#8C8C8C' }}>
                      4.2
                    </span>
                  </Typography>
                </Box>
              </Box>
            </div>
            {this.userMultiValues(attributes)}
            <div className="userbox3">
              <Box style={{ display: 'flex', marginBottom: '7px', alignItems: 'center' }}>
                <img src={earth} alt="Earth" className="bagCss" />
                <Typography className="servicesLegalCss">Jurisdiction</Typography>
              </Box>
              <Box>
                <Typography className="Jurisditincss" style={{ padding: '3px 10px', marginRight: '7px' }}>
                  {attributes?.jurisdiction || "USA"}
                </Typography>
              </Box>
            </div>
            <div className="userbox4">
              <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Box style={{ display: 'flex' }}>
                    <StarIcon style={{ fontSize: '20px', color: '#F59E0B', marginTop: '3px' }} />
                    <Typography className="text4css">
                      4.2
                    </Typography>
                  </Box>
                  <Typography className="yearExpCss">
                    {`${attributes?.years_of_experience || "0"} Years of experience`}
                  </Typography>
                </div>
              </Box>
            </div>
          </Box>
        );
      })}
    </Box>
    )
  }

  userBox = () => {
    return (
      <>
          {this.topbox()}
        {this.state.noUserData === "" ? (
          <>
            {this.state.userdata.length > 0 ? (
              <>
                {this.UserMapBox()}
              </>
            ) : (
              <span style={{ display: 'flex', margin: '0px auto', alignItems: 'center', height: '100vh', justifyContent: 'center' }}>
                {this.state.isLoading ? <CircularProgress/>:<Typography>No lawyers found</Typography>}
              </span>
            )}
          </>
        ) : (
          <Typography className="nouserdatacss">
            {this.state.noUserData}
          </Typography>
        )}
      </>
    );
  }

  DetailsPopUp = () =>{
    return(
      <>
            <PopUp className="active">
                <Box className="popUpBody2">
                    <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <Box style={{ width: '40px', height: '40px', borderRadius: '50%' }}>
                                <img src={userP} width={'100%'} height={'100%'} style={{ borderRadius: '50%' }} />
                            </Box>
                            <Box style={{ fontSize: '1rem' }}>
                                {`${this.state.showDetails.firstName.charAt(0).toUpperCase() + this.state.showDetails.firstName.slice(1).toLowerCase()} 
                                ${this.state.showDetails.lastName.charAt(0).toUpperCase() + this.state.showDetails.lastName.slice(1).toLowerCase()}`}</Box>
                        </Box>
                        <CloseIcon className="cursorPointer"
                            style={{ fontSize: '30px' ,cursor:'pointer'}}
                        onClick={this.handleCloseDetailsPopUp} 
                        />
                    </Box>
                    <Box style={{ margin: '0 -40px' }}>
                        <hr style={{ height: '1px', border: 'none', backgroundColor: '#d0cbcb' }} />
                    </Box>
                    <Box style={{ display: 'flex',justifyContent:'start' }}>
                        <Box style={{ marginTop: '1rem', color: 'gray', minWidth: '25%' }}>Law Firm Details</Box>
                        <Box style={{ display: 'flex', flexDirection: 'column' ,gap:'10px'}}>
                            <Box className="txtHeading"
                                style={{ padding: '0 30px', marginTop: '1rem', textWrap: 'nowrap' }}>
                                First Name
                            </Box>
                            <Box
                                style={{ padding: '0 30px', marginTop: '1rem', color: 'gray', textWrap: 'nowrap' }}>
                                {`${this.state.showDetails.firstName.charAt(0).toUpperCase() + this.state.showDetails.firstName.slice(1).toLowerCase()}`}
                            </Box>
                            <Box className="txtHeading"
                                style={{ padding: '0 30px', marginTop: '1rem', textWrap: 'nowrap' }}>
                                Jurisdiction
                            </Box>
                            <Box style={{
                                width: "160%",
                                height: "3rem",
                                display:'flex',
                                gap:'10px',
                                padding:'5px',
                                margin:'15px 0px 0px 20px',
                            }}>
                                    <Box 
                                    style={{
                                        backgroundColor: 'rgba(145, 183, 221, 0.21)',
                                        fontSize: '1.5rem',
                                        padding: '5px 0 5px 10px',
                                        display: 'flex',
                                        gap: '25px',
                                        justifyContent:'space-between',
                                        alignItems:'center',
                                        borderRadius: '5px',
                                        minWidth: '10.5rem',
                                    }}>
                                        <Box style={{
                                            display:'flex',
                                            gap:'10px'
                                        }}>
                                            <Box style={{
                                                display:'flex',
                                                alignItems:'center',
                                                fontSize: '16px'
                                            }}>{this.state.jurisdiction.countryName}</Box>
                                        </Box>
                                    </Box>
                            </Box>
                            <Box className="txtHeading"
                                style={{ padding: '0 30px', marginTop: '1rem',textWrap: 'nowrap' }}>
                                Rate
                            </Box>
                            <Box style={{display:'flex',gap:'10px' , alignItems:'center'}}>
                                <Box marginLeft='25px'><Star style={{color:'gold'}}/></Box>
                                <Box>4.2</Box>
                            </Box>
                        </Box>
                        <Box style={{ display: 'flex', flexDirection: 'column' ,gap:'10px'}}>
                            <Box className="txtHeading"
                                style={{ padding: '0 30px', marginTop: '1rem', textWrap: 'nowrap' }}>
                                Last Name
                            </Box>
                            <Box 
                                style={{ padding: '0 30px', marginTop: '1rem', color: 'gray', textWrap: 'nowrap' }}>
                                {`${this.state.showDetails.lastName.charAt(0).toUpperCase() + this.state.showDetails.lastName.slice(1).toLowerCase()}`}
                            </Box>
                        </Box>
                    </Box>
                    
                    <Box style={{ margin: '0 -40px' }}>
                        <hr style={{ height: '1px', border: 'none', backgroundColor: '#d0cbcb' }} />
                    </Box>
                    <Box className="cursorPointer" onClick={this.handleCloseDetailsPopUp}  style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '20px 0px 10px 0px' }}>
                        <button className="browseBtn" data-test-id="applyFilterId" style={{height:'20px'}}>OK</button>
                    </Box>
                </Box>
            </PopUp>
      </>
    )
  }

  // Customizable Area End

  render() {
    return (
        // Customizable Area Start
        <MainDiv data-test-id="resultScreen" onClick={this.handleCloseOption}
            style={{
                maxHeight: this.state.mobileOpen ? '100vh' : '',
                overflow: this.state.mobileOpen ? "hidden" : ""
            }}>
            <Box className="sidebar">
                {this.drawer()}
                {this.buttonMenu()}
                {this.state.mobileOpen &&
                    <>
                        <div className="offcanvascss" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                            {this.drawer()}
                        </div>
                    </>
                }
            </Box>
            {this.state.mobileOpen && <div className="offcanvasbackdrop" ></div>}
            <Box className="rightbar" data-test-id="rightbar" onClick={this.handleCloseBox}>
                {this.userBox()}
            </Box>
            <Box>{this.state.detailsPopUp && this.DetailsPopUp()}</Box>
        </MainDiv>
        // Customizable Area End
    );
}
}

// Customizable Area Start

const MainDiv = styled('div')({
    width: '100%',
    height: '100%',
    display: 'flex',
    overflow:"scroll",
    fontFamily: 'DM Sans',

    " ::-webkit-scrollbar": {
      width: "10px !important"
    },
    " ::-webkit-scrollbar-track": {
      background: "red !important"
    },
    " ::-webkit-scrollbar-thumb": {
      background: "red !important"
    },
    " ::-webkit-scrollbar-thumb:hover": {
      background: "red !important"
    },
    "@media (max-width:991px)": {
        display: "block",
    },
    "& .MuiSelect-selectMenu:focus": {
        backgroundColor: "#fff"
    },
    "& .sidebar": {
        width: '355px',
        height: '100vh',
        position: 'fixed',
        top: '0',
        left: '0',
        borderRight: "1px solid #C3D1FF",
        "@media (max-width: 1100px)": {
            width: "355px"
        },
        "@media (max-width: 991px)": {
            width: '100vw',
            height: '80px'
        }
    },
    "& .userContainer":{
      display: 'flex',
       flexWrap: 'wrap', 
       gap: '30px',
       marginBottom:"30px" 
    },
    "& .rightbar": {
        width: 'calc(100% - 355px)',
        padding: "20px 70px",
        marginLeft: '355px',
        marginTop: '50px',
        "@media (max-width: 1100px)": {
            padding: '20 40px',
            width: 'calc(100% - 355px)',
            marginLeft: '355px',
        },
        "@media (max-width: 991px)": {
            width: 'auto !important',
            marginLeft: '0px',
            marginTop: '25px',
            padding: "10px 50px",
        }
    },
    '& .topbox': {
        display: 'flex',
        width: "auto",
        height: '70px',
        marginBottom:"20px",
        justifyContent: 'space-between',
        "@media (max-width: 991px)": {
            width: "auto !important",
        },
    },
    "& .titleuser": {
      marginLeft:'6px',
        fontSize: '22px',
        fontWeight: 400,
        fontFamily: "DM Sans",
        color: '#000',
        "@media (max-width: 450px)": {
            fontSize: '16px',
        },
    },
    "& .titlbtn": {
        height: '44px',
        width: '163px',
        border: '1px solid #355EE7',
        borderRadius: '8px',
        padding: '10px 12px',
        fontSize: '14px',
        fontWeight: 400,
        color: '#355EE7',
        fontFamily: 'DM Sans',
        "@media (max-width: 450px)": {
            height: '40px',
            width: '140px',
            padding: '7px 9px',
        },
    },
    "& .titlbtn1": {
        height: '44px',
        width: '163px',
        border: '1px solid #355EE7',
        borderRadius: '8px',
        padding: '10px 12px',
        fontSize: '14px',
        fontWeight: 400,
        color: '#fff',
        backgroundColor: "#355EE7",
        fontFamily: 'DM Sans',
        textTransform: "none",
        '&:hover': {
            border: '2px solid #B9C5FF',
            boxShadow: 'none !important'
        },
        "@media (max-width: 450px)": {
            height: '40px',
            width: '140px',
            padding: '7px 9px',
        },
    },
   
    "& .offcanvascss": {
        position: 'fixed',
        top: '0px !important',
        bottom: '0px !important',
        left: '0% !important',
        width: '355px !important',
        height: '100vh !important',
        backgroundColor: '#fff',
        zIndex: 9999,
    },
    "& .offcanvasbackdrop": {
        position: "fixed",
        top: '0px',
        left: '355px',
        width: 'calc(100% - 355px)',
        height: '100vh',
        zIndex: 999,
        backgroundColor: '#000',
        opacity: 0.5,
    },
    "& .userBoxData": {
      boxShadow: "2px 3px 6px 3px #0000000F",
      padding: '20px',
      borderRadius: '16px',
      width:'245px',
    },
    "& .userbox1":{
      marginBottom:'10px'
    },
    "& .userProfile":{
      display: "flex",
      width:'252px',
      position:'relative',
    },
    "& .imageBox":{
      width:'56px !important',
      height:'56px !important',
      borderRadius:'50% !important',
      border:'1px solid #F0E5FF',
      marginRight:'10px',
    },
    "& .userName":{
      fontFamily:'DM Sans',
      fontSize:'14px',
      fontWeight:400,
      color:'#0F172A',
      lineHeight:'18px',
      marginBottom:'5px',
      marginLeft:'2px'
    },
    "& .userContent":{
      fontFamily:'DM Sans',
    fontSize: '11px',
    fontWeight: 400,
    color: '#355EE7',
    padding:'2px 6px',
    background:'#ECF1FF',
    borderRadius:'6px',
    position:'relative'

  },
  "& .starIconCss":{
    fontSize: '14px', color: '#F59E0B',
    position:'absolute',
    top:'3px'
  },
  "& .tripledotcss":{
    position:'absolute',
    fontSize:'30px',
    color:'#595959',
    top:'7px',
    right:'8px'
  },
  "& .userbox2":{
    marginBottom:'15px'
  },
  "& .bagCss":{
    width:'18px',
    height:'18px'
  },
  "& .servicesLegalCss":{
    fontFamily:'DM Sans',
      fontSize:'14px',
      fontWeight:400,
      color:'#1F1F1F',
      marginLeft:'10px',
  },
  "& .FamilyTextCss":{
    border:'1px solid #8C8C8C',
    borderRadius:'6px',
    color:'#8C8C8C',
    fontFamily:'DM Sans',
      fontSize:'14px',
      fontWeight:400,
      textAlign:'center',
  },
  "& .Jurisditincss":{
    border:'1px solid #8C8C8C',
    borderRadius:'6px',
    color:'#8C8C8C',
    fontFamily:'DM Sans',
      fontSize:'14px',
      fontWeight:400,
      textAlign:'center',
      width:"max-content"
  },
  "& .userbox3":{
    marginBottom:'15px'
  },
  "& .text4css":{
    fontFamily:'DM Sans',
      fontSize:'14px',
      fontWeight:400,
      color:'#8C8C8C',
      marginLeft:'7px',
      marginTop:'3px'
  },
  "& .yearExpCss":{
    fontFamily:'DM Sans',
      fontSize:'14px',
      fontWeight:400,
      color:'#595959',
      marginLeft:'3px'
  },
  "& .activebtnCss":{
    textTransform:"capitalize",
    marginTop:'15px',
    fontFamily:'DM Sans',
      fontSize:'14px',
      fontWeight:400,
      color:'#fff',
      background:'#4872FF',
      borderRadius:'6px',
      padding:'5px 15px',
      border:"2px solid #4872FF",
      boxShadow:'none !important',
      cursor:'pointer',
      '&:hover': {
        border:'2px solid #B9C5FF',
        boxShadow:'none !important'
      },
  },
  "& .inactivebtnCss":{
    textTransform:"capitalize",
    marginTop:'15px',
    fontFamily:'DM Sans',
      fontSize:'14px',
      fontWeight:400,
      color:'  #8C8C8C',
      background:"#D9D9D9",
      borderRadius:'6px',
      padding:'5px 15px',
      border:"2px solid #D9D9D9",
      boxShadow:'none !important',
      cursor:'pointer',
  },
  "& .EditBtnCss":{
    width:'155px',
    height:'44px',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    fontFamily:'DM Sans',
    fontSize:'16px',
    fontWeight:400,
    color:'#595959',
    borderRadius:'8px',
    border:'none',
    background:'#fff',
    '&:hover': {
      background:'#ECF1FF',
    },
  },
    "& .optionCss":{
      width:'155px',
      padding:'8px',
      position:'absolute',
      top:'30px',
      left:'180px',
      borderRadius:'8px',
      boxShadow: "2px 3px 6px 3px #0000000F",
      zIndex:9999,
      background:'#fff'
  },
  "& .nouserdatacss":{
    height:'84vh',
    fontFamily:'DM Sans',
    fontSize:'29px',
    fontWeight:400,
    color:'grey',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
  },

})

const DrawerBox = styled('div')({
    minHeight: '100vh',
    width: "355px",
    "& .logobox": {
        display: 'flex', justifyContent: "start",
        height: "76px", marginTop: '20px',
        marginLeft: '20px'
    },
    "& .logoimag":
    {
        height: '42px',
        width: '118px',
    },
    "& .settingtext": {
        color: '#262626', fontSize: '22px',
        marginLeft: '6px',
        fontWeight: 400, fontFamily: 'DM Sans',
    },
    "& .settingbox":
    {
        display: 'flex',
        height: "65px",
        cursor: 'pointer'
    },

    "& .lefticon":
    {
        marginTop: '4px',
        marginLeft: '30px',
    },
    "& .listbox": {
        height: '110px',
        borderRadius: '8px',
        "&:hover": {
            backgroundColor: "#ECF1FF",
            color: "#355EE7",
            "& .icondrawer2":
            {
                backgroundColor: "#ECF1FF",
                color: "#355EE7",
                cursor:'pointer'
            },
            "& .icondrawer3":
            {
                backgroundColor: "#ECF1FF",
                color: "#355EE7",
            },
            "& .listtile": {
                color: "#355EE7",
            }
        },
    },
    "& .listbox1": {
        height: '50px',
        backgroundColor: '#ECF1FF',
        borderRadius: '8px',
    },
    "& .icondrawer1": {
        marginBottom: '45px',
        color: '#355EE7',
        marginLeft: '18px',
        cursor:'pointer'
    },
    "& .icondrawer3": {
        marginLeft: '18px',
        marginBottom: '25px',
        color: '#434343',
    },
    "& .icondrawer2": {
        marginLeft: '18px',
        marginBottom: '42px',
        color: '#434343',
        cursor:'pointer'
    },

    "& .listtext": {
        fontSize: '14px',
        color: '#334155',
        fontFamily: 'DM Sans',
        fontWeight: 400,
    },
    "& .listtile1": {
        fontSize: "16px",
        color: '#355EE7',
        fontFamily: 'DM Sans',
        fontWeight: 400,
        cursor: 'pointer'
    },
    "& .listtile":
    {
        fontSize: "16px",
        fontFamily: 'DM Sans',
        fontWeight: 400,
        cursor: 'pointer',
        color: '#434343',
    },
    "@media (max-width: 1100px)":
    {
        zIndex: 999,
        width: '355px',
    },
    "@media (max-width: 991px)":
    {
        zIndex: 999,
        width: '355px',
        display: 'none',
    },
});

const ButtonBox = styled('div')({
    display: 'none',
    "@media (max-width: 991px)":
    {
        width: '100%',
        display: 'block', minHeight: '70px',
        "& .buttonbox":
        {
            height: '70px', width: '100vw'
        },

        "& .iconbtn":
        {
            marginTop: '10px', marginLeft: '10px'
        }
    }
})

const PopUp = styled('div')({
    width: "100vw",
    height: "100vh",
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 100,
    opacity: 0,
    visibility: 'hidden',
    transition: 'opacity 0.3s ease, visibility 0.3s ease',
    fontFamily: 'DM Sans',

    "& .cursorPointer":{
      cursor:'pointer !important'
    },

    '&.active': {
        opacity: 1,
        visibility: 'visible',
    },

    '& .popUpBody': {
        backgroundColor: "white",
        width: "700px",
        borderRadius: "20px",
        padding: "20px 40px",
    },

    '& .popUpBody2': {
        backgroundColor: "white",
        maxWidth: "50%",
        borderRadius: "10px",
        padding: "20px 40px",
        fontFamily: 'DM Sans',
    },

    '& .hoverCountrydata:hover': {
        backgroundColor: "rgba(145, 183, 221, 0.21)",
    },

    "& .browseBtn": {
        width: '100%',
        background: '#355EE7',
        padding: '20px 40px',
        marginLeft: '20px',
        color: "#fff",
        borderRadius: '8px',
        textTransform: 'none',
        fontSize: '20px',
        fontWeight: 400,
        fontFamily: 'DM Sans',
        boxShadow: "none",
        border: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    '& #slider': {
        accentColor: "#355EE7"
    },

    "& .statusError": {
        borderLeft: "4px solid #F87171", fontFamily: "DM Sans", fontWeight: "400", fontSize: "12px", textAlign: "left",
        backgroundColor: "#FEE2E2", color: "#DC2626", boxShadow: "none", marginBottom: "20px"
    },

    "& .statusErrorCustom": {

        fontFamily: "DM Sans", fontWeight: "400", fontSize: "14px", textAlign: "left",
        color: "red", boxShadow: "none", marginBottom: "20px"

    },
    "& .errorOutLine": {
        border: '1px solid red',
    },

    "& .txtHeading": {
        color: 'black',
        fontWeight: 'bold'
    },

    "& .textHeadingValue": {
        color: 'gray'
    }

});

// Customizable Area End