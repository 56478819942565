import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { groupD } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
interface Details {
  Title: string;
  Data: string;
  Imag: string;
  date:string;
}
export interface LegalServiceType {
  id: number,
  name: string
}
interface Task {
  dotColor:string,
  spantext:string,
  title:string,
  data:string,
  backgroundData:string,
  colordata:string,
  checkedFirst:boolean,
  checkedSecond:boolean}

export interface TaskProfile { isSelected: boolean;
  id: string;
  attributes: {  task_name: string;
    legel_service_type: any; trademark_name: string;
    trademark_application_number: string; state: string;
    status: string;
    id: string;   account_id: number;
    name: string;
    created_at: Date;
    updated_at: Date;
    tasks: ITask[];
    deadline:string;
    message_count:number
  };
}
export type Status = {
  id: number; name: "to_do" | "in_progress" | "complete";
};

export type Priority = {
  id: number; name: "low" | "medium" | "high";
};

export interface IAccount {
  isSelected: boolean;
  id: string;
  type: "account";
  attributes: { first_name: string;
    last_name: string;};
}



export interface ServicesDataType{
  id: string,
  legal_service_name: string, legel_service_description: string|null,
  created_at: string,
  updated_at: string
}

export interface IGroupAccount {
  id: number;
  type: "account"; first_name: string;
  last_name: string;
}

export interface IGroup {
  id: string; type: "group";
  attributes: {
    name: string;
    accounts: IGroupAccount[];
  };
}

export interface ITask {
  isSelected: boolean; id: string;
  attributes: {
    id: number;
    account_id: number;
    title: string;
    description: string;
    status: Status["name"];
    priority: Priority["name"];
    created_at: Date;
    updated_at: Date;
    assigned_to: IAccount | IGroup;
  };
}


interface Data{
  name: string,
  value: number 
}
interface TableData{
  id: string,
  lawfirm: string,
  task: string,
  amount: string,
  status: string,
  background: string,
  color: string,
  img:string
}
export interface CountryDatatype {
  id: string;
  type: string;
  attributes: {
    name: string;
  };
}

export interface LegalServices{
  id: string,
  legal_service_name: string,
  legel_service_description: string|null,
  created_at: string,
  updated_at: string
}

export interface DataLegal{
  value:string,
  label:string,
  id:string,
}

export interface ServicesDataType{
  id: string,
  legal_service_name: string,
  legel_service_description: string|null,
  created_at: string,
  updated_at: string
}

interface RootFolder {
  id: number;
  gallery_id: number | null;
  folder_name: string;
  folder_type: string | null;
  created_at: string;
  updated_at: string;
  is_archived: boolean;
  parent_id: number | null;
  account_id: number;
  task_id: number;
  archived_at: string | null;
  restore_id: string | null;
}

interface ProjectTemplate {
  id: string;
  type: string;
  attributes: {
      task_name: string;
      description: string;
      trademark_name: string;
      trademark_application_number: string;
      deadline: string;
      jurisdiction: string;
      state: string;
      status: string;
      account_id: number;
      parent_task_id: number | null;
      created_at: string;
      request: string | null;
      account: {
          data: Account;
      };
      legel_service_type: LegalServiceType;
      root_folder: RootFolder;
      chat_rooms: any[];
      lawfirm_as_lawyer: boolean;
      message_count: number;
  };
}
interface Account {
  id: string;
  type: string;
  attributes: {
      activated: boolean;
      country_code: string;
      email: string;
      full_phone_number: string;
      first_name: string;
      last_name: string;
      phone_number: string;
      type: string | null;
      created_at: string;
      updated_at: string;
      device_id: string | null;
      unique_auth_id: string;
      firm: string | null;
      user_role: string;
  };
}
interface ResponseData {
  id: number;
  data: ProjectTemplate[];
}
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  details: Details;
  DetailsUser: Details[]
  upcomingTask:ProjectTemplate[];
  taskProfile:TaskProfile[],
  loading:boolean
  drawerOpen:boolean
  taskViewId:string
  selectedLawyers: string
  isTaskPosted:boolean
  isInprogress:boolean
  taskView:{
    id: string;
    attributes: {
      task_name: string;
      legel_service_type: LegalServiceType;
      trademark_name: string;
      trademark_application_number: string;
      state: string;
      status: string;
      jurisdiction:string,
      deadline:string,
      description:string
      created_at:string,
      request: {

        data: {
          id: string,
          type: string,
          attributes: {
            price: string,
          }
        }
      }
    };
  },
  TaskAll:Task[],
  lastFiles:{
    id: number,
    name: string,
    byte_size: number,
    record_type: string,
    record_id: number,
    created_at: string,
    filename: string,
    content_type: string,
    url: string
  }[],
  activeTab:number,
  accepted:boolean,
  rejectTask:boolean,
  acceptTask:boolean
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getLawfirmListApiCallId:string=""
  getTasksApiCallId:string ="";
  assignLawyerCallId:string="";
  getUpcomingTaskCallID:string="";
  getLasetFilesAPIcallID:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      details: {
        Title: "",
        Data: "",
        Imag: "",
        date:""
      },
      DetailsUser: [],
      taskView:{
        id:"1",
        attributes: {
          task_name: "",
          legel_service_type: {
            id:1,
            name:""
          },
          trademark_name: "",
          trademark_application_number: "",
          state: "",
          status: "",
          jurisdiction:"" ,
          deadline:"",
          description:"",
          created_at:"",
          request: {
  
            data: {
              id: "",
              type: "",
              attributes: {
                price: "",
              }
            }
          }
            }
        },
        taskProfile:[],
        loading:true,
        drawerOpen:false,
        taskViewId:'',
        selectedLawyers:'',
        isInprogress:false,
        isTaskPosted:false,
        TaskAll:[],
        activeTab:1,
        upcomingTask:[],
        accepted:false,
       rejectTask:false,
       acceptTask:false,
       lastFiles:[],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.setState({
      DetailsUser: [
    {
      Title:"Digip has approved your task",
      Data:"Your task is now posted, please finish the last de...",
      Imag:`${groupD}`,
      date:"16/11/2024"
    },
    {
      Title:"Digip has approved your task",
      Data:"Your task is now posted, please finish the last de...",
      Imag:`${groupD}`,
       date:"16/11/2024"
    },
    {
      Title:"Digip has approved your task",
      Data:"Your task is now posted, please finish the last de...",
      Imag:`${groupD}`,
       date:"16/11/2024"
    }],
    TaskAll:[
      {
        dotColor:"#355EE7",
        spantext:"12",
        title:"In Progress",
        data:"High",
        backgroundData:"#FEE2E2",
        colordata:"#DC2626",
        checkedFirst:true,
        checkedSecond:false
      },
      {
        dotColor:"#8C8C8C",
        spantext:"5",
        title:"Overdue",
        data:"Normal",
        backgroundData:"#FEF3C7",
        colordata:"#D97706",
        checkedFirst:true,
        checkedSecond:false
      },{
        dotColor:"#059669",
        spantext:"20",
        title:"Completed",
        data:"Low",
        backgroundData:"#F5F5F5",
        colordata:"#8C8C8C",
        checkedFirst:true,
        checkedSecond:true
      },{
        dotColor:"#F87171",
        spantext:"0",
        title:"Declined",
        data:"Low",
        backgroundData:"#F5F5F5",
        colordata:"#8C8C8C",
        checkedFirst:false,
        checkedSecond:false
      },

    ]
  })
  this.getTaskLists()
  this.getUpcomingTask()
  this.assignLawyer()
  this.getLastFiles()
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.getLawfirmListApiCallId) {
      if (responseJson.data) {
        this.setState({ taskProfile: responseJson.data,
        }); 
      }
      
    }
    this.handleUpcomingTask(apiRequestCallId,responseJson)
    this.handleLastFileResponse(apiRequestCallId,responseJson)
    
    // Customizable Area End
  }
  // Customizable Area Start
  handleUpcomingTask =(apiRequestCallId:any,responseJson:any)=>{
    if (apiRequestCallId === this.getUpcomingTaskCallID) {
      const upcomingTask = responseJson?.data ?? []; 
      const taskId = responseJson.data[0].id;
    this.setState({ upcomingTask, loading: false ,taskViewId:taskId});
    }
  }
  handleLastFileResponse =(apiRequestCallId:any,responseJson:any)=>{
    if (apiRequestCallId === this.getLasetFilesAPIcallID) {
      if (responseJson) {
        this.setState({ lastFiles: responseJson, loading: false });
      }
    }
  }
  handleDocumentFolderMenu = async() => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Documentation");   
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    sessionStorage.setItem("Document", "ture")
    this.send(message);
  };
  handleLawfirm = () => {
    const Notimessage: Message  = new Message(getName(MessageEnum.NavigationMessage));
    Notimessage.addData(getName(MessageEnum.NavigationTargetMessage),
      "lawfirmManagement");

    Notimessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(Notimessage);
  }
  handleNavigation = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "tasklist-lawfirm");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);

    this.send(message);
  }
  handleRejectTask = () => {
    this.setState((prevState) => ({
      rejectTask: !prevState.rejectTask,
    }));
  };

  handleAcceptTask = () => {
    this.setState((prevState) => ({
      accepted: !prevState.accepted,
    }));
  };  
  handleTaskClick = (taskId:string) => {
    this.setState({ taskViewId: taskId });
  };
  handleClosePopup = () => {
    this.setState({ accepted: false});
}
handleClosed = () => {
  this.setState({rejectTask: false });
}
  setActiveTab = (tabIndex:number) => {
    this.setState({ activeTab: tabIndex });
  };
  handleLawyer = () => {
    const Notimessage2: Message
      = new Message(getName(MessageEnum.NavigationMessage));
    Notimessage2.addData(getName(MessageEnum.NavigationTargetMessage),"lawyerManagement");

    Notimessage2.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);  this.send(Notimessage2);
  }
  toggleDrawer = (task: { id: string }) => {
    const taskId = String(task.id);
    sessionStorage.setItem("TaskID" , taskId)
    this.setState(prevState => ({  drawerOpen: !prevState.drawerOpen, taskViewId: taskId}));
    this.getTasks()
  };

  handleAdminLawyer = () => {
    const message11: Message
      = new Message(getName(MessageEnum.NavigationMessage));
    message11.addData(getName(MessageEnum.NavigationTargetMessage),"AdminLawyerManagement");

    message11.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);  this.send(message11);
  }
  handleService = () => {
    const Notimessage1: Message = new Message(getName(MessageEnum.NavigationMessage));
    Notimessage1.addData(getName(MessageEnum.NavigationTargetMessage),
      "ServicesManagement");

    Notimessage1.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);
    this.send(Notimessage1);
  }
  handleNavigationTask = () => {    
    const Notimessage1: Message = new Message(getName(MessageEnum.NavigationMessage));
    Notimessage1.addData(getName(MessageEnum.NavigationTargetMessage),
      "TaskListLawfirm");

    Notimessage1.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);
    this.send(Notimessage1);
  }
  determineMainToken1 = (
    metaSignUpToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } },
    metaLoginToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } }
  ): { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } } | null => {
    if (metaSignUpToken?.data?.id) {
      if (metaLoginToken?.id && metaSignUpToken.data.id !== metaLoginToken.id) {
        return metaLoginToken;
      }
      return metaSignUpToken;
    }

    if (metaLoginToken?.id) {
      return metaLoginToken;
    }

    return null; 
  };
  getTaskLists = async() => {
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);
    const header = {
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getLawfirmListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${webConfigJSON.tasksApiLawfirmEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  toggleDrawerClose = () => {
    this.setState({ drawerOpen: false }); 
  };

    getTasks = async () => {
      const signupTokens: string = await getStorageData("userdetails");
      const loginTokens: string = await getStorageData("token");
      const metaSignUpToken = JSON.parse(signupTokens);
      const metaLoginToken = JSON.parse(loginTokens);
  
      const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);
      const header = {
        "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
      "Content-Type": "application/json",
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getTasksApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_projecttemplates/project_templates/${this.state.taskViewId}`
      );
   
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    assignLawyer = async () => {
      const signupTokens: string = await getStorageData("userdetails");
      const loginTokens: string = await getStorageData("token");
      const metaSignUpToken = JSON.parse(signupTokens);
      const metaLoginToken = JSON.parse(loginTokens);
  
      const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);
      const header = {
        "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
      "Content-Type": "application/json",
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.assignLawyerCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_joblisting/jobs/task_assigned_lawyers?project_template_id=${this.state.taskViewId}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    getUpcomingTask = async () => {
      const signupTokens: string = await getStorageData("userdetails");
      const loginTokens: string = await getStorageData("token");
      const metaSignUpToken = JSON.parse(signupTokens);
      const metaLoginToken = JSON.parse(loginTokens);
  
      const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);
      const header = {
        "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
      "Content-Type": "application/json",
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getUpcomingTaskCallID = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_dashboard/lawyers_dashboard/upcoming_task_requests"
      );
   
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    getLastFiles = async () => {
      const signupTokens: string = await getStorageData("userdetails");
      const loginTokens: string = await getStorageData("token");
      const metaSignUpToken = JSON.parse(signupTokens);
      const metaLoginToken = JSON.parse(loginTokens);
  
      const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);
      const header = {
        "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
      "Content-Type": "application/json",
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getLasetFilesAPIcallID = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_dashboard/lawyers_dashboard/last_uploaded_files"
      );
   
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };
  // Customizable Area End
}
