export const logo = require("../assets/image_logo.svg");
export const userpic = require("../assets/userPic.png");
export const HomeLogo = require("../assets/homeLogo.png");
export const DocLibrary = require("../assets/doc_lib.png");
export const FilledDocLibrary = require("../assets/threefolder.png");
export const Billing = require("../assets/billing.png");
export const secondlogo = require("../assets/secondlogo.png");
export const taskChat = require("../assets/taskChat.png");
export const lawfirm = require("../assets/lawfirmIcon.svg");
export const lawyers = require("../assets/Lawyers.svg");
export const project = require("../assets/ProjectReporting.svg");
export const services = require("../assets/Services.svg");
