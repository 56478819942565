import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { pdfI} from "./assets";
import React,{ChangeEvent} from "react";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
interface Sender {
  id: number;
  type: string;
  name: string;
  profile: string | null;
  message: string;
  created_at: string;
  attachments:[]
}
export interface CustomFile {
  url: string;
  filename: string;
  content_type: string;
  size?: number;
  lastModified?: number;
  
}
export type DateInput = string | number | Date;
interface Receiver {
  id: number;
  type: string;
  name: string;
  profile: string | null;
  message: string;
  created_at: string;
  attachments:[]
}
export interface Attachment {
  id: number;
  name: string;
  byte_size: number;
  record_type: string;
  record_id: number;
  created_at: string;
  filename: string;
  content_type: string;
  url: string;
}


export interface MessageData {
  id: number;
  created_at: string; 
  sender: { 
    id: number;
    type: string;
    name: string;
    profile: { url: string | null };
    message: string;
    created_at: string; 
    unreadCount: number;
    lastMessage: string;
    attachments:Attachment[]
  };
  receiver: { 
    id: number;
    type: string;
    name: string;
    profile: { url: string | null };
    message: string | null;
    created_at: string; 
    unreadCount: number;
    lastMessage: string;
    attachments:Attachment[]
  };
  unreadCount: number;
  lastMessage: string;
  images?: { name: string; url: string }[];
  videos?: { name: string; url: string }[];
  pdfs?: { name: string; url: string }[];
  text: string;
  time: string;
}

export interface IChat {
  muted: any;
  name: string;
  unreadCount: any;
  lastMessage: string;
  id: string; 
}
export interface IChat2 {
  images?: { name: string; url: string }[];
  videos?: { name: string; url: string }[]; 
  pdfs?:   {name:string,url:string}[];
  id: number; 
  sender: string;
  text: string;
  time: string;
}
interface IChatResponse {
  id: string;
  attributes: {
    name: string;
    accounts_chats: [
      {
        id: string;
        attributes: {
          account_id: number;
          muted: boolean;
          unread_count: number;
        };
      }
    ];
    messages: {
      id: string;
      attributes: { id: number; message: string };
      message: string;
    };
  };
}
export interface ApiResponse {
  attributes: {
    associated_lawyer_details: {
      data: {
        id:number,
        attributes: {
          first_name: string;
          last_name: string;
          website: string;
          firm: string;
          jurisdiction: string;
          full_phone_number: string;
          country_code: number;
          email: string;
          user_role: string;
          accomplishments: string;
          lawyer_status: string;
          legel_service_type: [{
            id: string,
            legal_service_name: string,
            legel_service_description: string|null,
            created_at: string,
            updated_at: string
          },
          {
            id: string,
            legal_service_name: string,
            legel_service_description: string|null,
            created_at: string,
            updated_at: string
          },{
            id: string,
            legal_service_name: string,
            legel_service_description: string|null,
            created_at: string,
            updated_at: string
          }];
          years_of_experience: number;
          profile_image: {
            url?: string | null; 
          } | null;
        };
      }
    };
    legal_service_count: number;
  };
}
export interface DataResponse{
    id: number,
    name: string,
    profile:{url:string} | null,
    type:string| null
}
export interface Account {
  id: string;
  type: string;
  attributes: {
    activated: boolean;
    country_code: string;
    email: string;
    full_phone_number: string;
    first_name: string;
    last_name: string;
    phone_number: string;
    type: string;
    created_at: string;
    updated_at: string;
    device_id: string | null;
    unique_auth_id: string;
    firm: string;
    user_role: string;
  };
}
export interface DocumentMedia {
  id: number; 
  name: string; 
  byte_size: number; 
  record_type: string;
  record_id: number;
  created_at: string; 
  filename: string; 
  content_type: string; 
  url: string; 
}



export interface FolderAttributes {
  folder_name: string;
  parent_id: number | null;
  is_archived: boolean;
  account_id: number | null;
  task_id: number;
  archived_at: string | null;
  created_at: string;
  restore_id: string | null;
  profile: string;
  account: {
    data: Account | null;
  };
  subfolder: Folder[];
  files: Files[];
}

export interface Folder {
  id: string;
  type: string;
  attributes: FolderAttributes;
}

export interface Files {
  id: number;
  name: string;
  account_id: number | null;
  user_role: string;
  account_name: string | null;
  account_profile_image: string;
  byte_size: number;
  record_type: string;
  record_id: number;
  created_at: string;
  filename: string;
  content_type: string;
  url: string;
}

export interface FolderData {
  data: Folder[];
}
export interface LegalServiceType {
  id: number,
  name: string
}
export interface ChatRoom {
  chat_room_id: number ;
  channel_sid: string;
  user_id: number;
  user_first_name: string;
  user_last_name: string;
  user_profile: {
    url:string,
  } | null
  user_jurisdiction: string | null;
  is_new_message: boolean;
  is_read_message: boolean;
  last_message: string;
  last_message_at: string;
  unread_count: number;
}


// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  open:boolean,
  token: string;
  accountId: number;
  chatName: string;
  chatList: IChat[];
  muted: boolean;
  unreadCount: number;
  lastMessage: string;
  isVisibleModal: boolean;
  mobileOpen:boolean;
  documentOpen:string | null;
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    loader: boolean;
    addFolderModal: boolean;
    uploadedFiles: [];
    folderName: string;
    renameValue: string;
    menuOption: string;
    folderMenuOptions: boolean;
    folderId: number;
    galleryId: number | string;
    blobId: number;
    isSizeExceeded:boolean;
    openCollapse:boolean;
    openSecondCollapse:boolean;
    activeTab:string
    loading: boolean;
    filterState: boolean;
    selectedTask: string;
    chatDialog:boolean,
     searchQuery: string;
     searchLawyer:string;
      selectedLawyers: DataResponse[];
      selectedLawyerName: string;
      selectedUser: ChatRoom[];
      searchResults: DataResponse[];
      openPop:boolean;
      uploadDialog:boolean;
  isWrongFileMimeType: boolean;
  folderPath:Array<{id: string, folderName: string}>;
  LoadedFiles: File[],
  LoadedFilesPreview: any,
  createChat:{
    taskId: number,
    receiverId: number|null,
    message:string,
   };
   hasError:boolean,
   showChat: boolean, 
   selectedChatLawyer:{
      id: number,
      name: string
   },
   newMessage: string,
   isInviteSent:boolean,
   userdata: {
    first_name: string;
    last_name: string;
    profile_image: {
      url:string
    };
    
  };
  sendData:{
    channelId:string
  }
  channelSID:string,
  messageList:MessageData[],
  selectedFiles:File[],
  selectedUploadedFile:CustomFile[]
  attachments:File[],
  filePreviews:string[],
  videoPreviews:string[],
  validFilesPreview:{
    image:string
  }[],
  pdfPreviews:{
    image:string
  }[],
  uploadedFil:DocumentMedia[],
  userList:ChatRoom[],
  sending:boolean,
   taskView:{
      id: number;
      attributes: {
        task_name: string;
        legel_service_type: LegalServiceType;
        trademark_name: string;
        trademark_application_number: string;
        state: string;
        status: string;
        jurisdiction:string,
        deadline:string,
        description:string
        created_at:string,
        request: {
  
          data: {
            id: string,
            type: string,
            attributes: {
              price: string,
            }
          }
        }
      };
    },
    anchorEl:null,
    taskViewId: string | null;
    archived:boolean,
    isImageLoaded:boolean,
    uploadProgress: { [key: number]: number };
    searchFiles:string,
    isSaveEnabled:boolean
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getChatListApiCallId: string = "";
  createChatRoomApiCallId: string = "";
  getSearchApiCallId:string = "";
  getUserProfileApiCallId:string ="";
  postSendInviteApiCallId:string ="";
  postCreateChannelId:string="";
  getMessageListId:string ="";
  getAllFoldersApiCallId:string="";
  getUserListId:string=""
  chatContainerRef: React.RefObject<HTMLUListElement>;
  getTasksApiCallId:string="";
  putArchivedChat:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      open:false,
      accountId: -1,
      chatName: "",
      chatList: [],
      muted: false,
      unreadCount: 1,
      selectedUploadedFile:[],
      lastMessage: "",
      isVisibleModal: false,
      mobileOpen:false,
      documentOpen:"",
      isSizeExceeded:false,
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      addFolderModal: false,
      uploadedFiles: [],
      loader: false,
      folderName: "",
      galleryId: 0,
      renameValue: "",
      menuOption: "",
      folderMenuOptions: false,
      folderId: 0,
      blobId: 0,
      openCollapse:false,
      openSecondCollapse:false,
      activeTab: 'active',
      loading: true,
      filterState: false,
      selectedTask: "",
      newMessage:"",
      chatDialog:false,
      searchQuery: "",
      searchLawyer:"",
      selectedLawyers: [],
      isSaveEnabled:false,
      openPop:false,
      searchResults:[],
      uploadDialog:false,
      folderPath:[],
  isWrongFileMimeType: false,

  LoadedFiles: [],
  LoadedFilesPreview: [],
  createChat:{
    taskId: 655,
    receiverId: 1,
    message:"",
   },
   showChat: false, 
   selectedChatLawyer:{
    id: 1,
    name: "",
 }, 
   hasError:false,
   isInviteSent:false,
   userdata: {
    first_name: "",
    last_name: "",
    profile_image:{
      url:""
    },
   
  },
  sendData:{
    channelId:""
  },
  channelSID:"",
  messageList:[],
  selectedFiles:[],
  attachments:[],
  filePreviews:[],
  videoPreviews:[],
  pdfPreviews:[],
  uploadedFil: [],
  userList:[],
  selectedUser: [],
  sending:false,
  taskView:{
    id:1,
    attributes: {
      task_name: "",
      legel_service_type: {
        id:1,
        name:""
      },
      trademark_name: "",
      trademark_application_number: "",
      state: "",
      status: "",
      jurisdiction:"" ,
      deadline:"",
      description:"",
      created_at:"",
      request: {

        data: {
          id: "",
          type: "",
          attributes: {
            price: "",
          }
        }
      }
        }
    },
    anchorEl:null,
    taskViewId:"",
    archived:false,
    isImageLoaded:false,
    selectedLawyerName: "",
    uploadProgress: {},
    searchFiles:"",
    validFilesPreview:[],

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
     this.chatContainerRef = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getUserList()
    this.getToken();
    this.getUserProfile()
    this.getTasks();
    this.getAllFolders()
   
    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }
  async componentDidUpdate(prevProps: any, prevState: { messageList: string | any[]; isInviteSent: boolean; }) {
      if (prevState.messageList.length !== this.state.messageList.length) {
        this.scrollToBottom();
      }
  
  }

 
  scrollToBottom = () => {
    if (this.chatContainerRef.current) {
      this.chatContainerRef.current.scrollTo({
        top: this.chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };


  handleUpdateCalls = () => {
    this.getChatList();
    this.setState((prevState) => ({
      messageList: [...prevState.messageList], 
    }));
  };
  handleUpdateCalls2 = () => {
    setTimeout(() => {
      this.getChatList();
    }, 5000); 
  };
  
  simulateUploadProgress = (index: number) => {
    let progress = 0;
    const interval = setInterval(() => {
      if (progress >= 100) {
        clearInterval(interval);
      } else {
        progress += 10;
        this.setState((prevState) => ({
          uploadProgress: {
            ...prevState.uploadProgress,
            [index]: progress,
          },
        }));
      }
    }, 500);
  };
  
 
handleSearchChange = (event: { target: { value: string; }; }) => {
  const query = event.target.value;
  this.setState({ searchQuery: query, openPop: query.length > 0 }, () => {
    this.getSearchData(query);
  });
};
  
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: true });
  }
  toggleSidebar = (id: string) => {
    this.setState((prevState) => ({
      openCollapse: id === 'openCollapse' ? !prevState.openCollapse : false,
      openSecondCollapse: id === 'openSecondCollapse' ? !prevState.openSecondCollapse : false,
    }));
  };
  handleclosesidebar = () => {
    this.setState({ 
      mobileOpen: false 
    });
  }
handleOpenChatBox=()=>{
  this.setState({chatDialog:true,isInviteSent:false,selectedLawyers:[]})
}

handleCloseChatBox=()=>{
  this.setState({chatDialog:false,selectedLawyers:[]})
}

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  isStringNullOrBlank = (string: string) => {
    return string === undefined || string === null || string.length === 0;
  };

  showModal = () => {
    this.setState({ isVisibleModal: true });
  };

  hideModal = () => {
    this.setState({ isVisibleModal: false });
  };
  handleOpenUploadDialog=()=>{
    this.setState({uploadDialog:true})
    this.getAllFolders()
 
  }
  handleClosUploadDialog=()=>{
    this.setState({uploadDialog:false,selectedUploadedFile:[],selectedFiles:[],
      pdfPreviews:[],
      filePreviews:[],videoPreviews:[],
      validFilesPreview:[],
      isSaveEnabled:false
    })
    this.getAllFolders()
  }

  navigateToChatView = (chatId: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ViewChat");

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      chatId: chatId,
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  

  createChatRoom = (chatName: string) => {
    if (this.isStringNullOrBlank(chatName)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
        "Access-Control-Allow-Origin": "*",
      };
      const bodyData = {
        name: chatName,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createChatRoomApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createChatRoomApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(bodyData)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  inputRoomNameProps = {
    onChangeText: (chatName: string) => {
      this.setState({ chatName });
    },
  };

  btnAddRoomProps = {
    onPress: () => this.createChatRoom(this.state.chatName),
  };

  btnCloseModalProps = {
    onPress: () => this.hideModal(),
  };

  btnShowAddModalProps = {
    onPress: () => {
      this.showModal();
    },
  };

  handleChatNameChange = (chatName: string) => {
    this.setState({ chatName });
  };
  formatFileSize(bytes: number): string {
    if (bytes < 1024) return `${bytes} B`;
    else if (bytes < 1048576) return `${(bytes / 1024).toFixed(2)} KB`;
    else if (bytes < 1073741824) return `${(bytes / 1048576).toFixed(2)} MB`;
    else return `${(bytes / 1073741824).toFixed(2)} GB`;
  }

  handleDragOver = (event: React.DragEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  
    const files = event.dataTransfer.files;
    const doesFileMimeTypeExist = this.isValidFileMimeType(files);
  
    if (!doesFileMimeTypeExist) {
      this.setState({ 
        isWrongFileMimeType: true, 
        isSizeExceeded: false,
        uploadDialog: false
      });
      return;
    }
  
    if (files && files.length > 0) {
      const maxFileSize = 52428800; 
      const isSizeExceeded = Array.from(files).some((file) => file.size > maxFileSize);
  
      if (isSizeExceeded) {
        this.setState({ 
          isSizeExceeded: true, 
          isWrongFileMimeType: false,
          uploadDialog: false
        });
        return;
      }
  
      this.setState({ isSizeExceeded: false, isWrongFileMimeType: false });
      this.processFiles(files);
    }
  };
  

  isValidFileMimeType = (files: FileList) => {
    const allowedTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", 
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", 
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation", 
      "image/jpeg",
      "image/png",
      "video/mp4",
    ];
    return Array.from(files).some((file) => allowedTypes.includes(file.type));
  };

 
  
  handleCloseErrorBox = () => {
    this.setState({
      isSizeExceeded: false,
      isWrongFileMimeType: false,
    });
  };

  handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (!files || files.length === 0) return;
  
    const fileArray = Array.from(files);
    const allowedTypes = [
      "application/pdf",
      "application/zip",
      "application/x-ole-storage",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "image/jpeg",
      "image/png",
      "video/mp4",
    ];
  
    const validFiles = fileArray.filter((file) => allowedTypes.includes(file.type));
  
    if (validFiles.length === 0) {
      this.setState({
        isWrongFileMimeType: true,
        isSizeExceeded: false,
        uploadDialog: true
      });
      return;
    }
  
    const maxFileSize = 52428800; // 50MB
    const isSizeExceeded = validFiles.some((file) => file.size > maxFileSize);
  
    if (isSizeExceeded) {
      this.setState({
        isSizeExceeded: true,
        isWrongFileMimeType: false,
        uploadDialog: true
      });
      return;
    }
  
    // Get file names to be included in the message box
  
    this.setState((prevState) => ({
      isSizeExceeded: false,
      isWrongFileMimeType: false,
      newMessage: prevState.newMessage
        ? `${prevState.newMessage}`
        : "", // If there's no existing message, just set the file names
    }));
  
    this.processFiles(files);
    this.setState({uploadDialog:false})
  };
  
  
  processFiles = (files: FileList) => {
    const fileArray = Array.from(files);

    const allowedTypes = [
      "application/pdf",
      "application/zip",
      "application/msword",
      "application/x-ole-storage",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "image/jpeg",
      "image/png",
      "video/mp4",
    ];
    const previewableTypes = [
      "application/zip",
      "application/msword",
      "application/x-ole-storage",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ];
    
    const validFiles = fileArray.filter(
      (file) => allowedTypes.includes(file.type) && !this.state.selectedFiles.some((f) => f.name === file.name)
    );
  

    const imagePreviews = validFiles
      .filter((file) => file.type.startsWith("image/"))
      .map((file) => URL.createObjectURL(file)); 

    const videoPreviews = validFiles
      .filter((file) => file.type.startsWith("video/"))
      .map((file) => URL.createObjectURL(file));

    const pdfPreviews = validFiles
      .filter((file) => file.type === "application/pdf")
      .map((file) => ({
        image: pdfI, 
      }));

      const validFilesPreview = validFiles
      .filter((file) => previewableTypes.includes(file.type))
      .map((file) => ({
        image: URL.createObjectURL(file), 
      }));

    const documentFiles = validFiles
      .filter(
        (file) =>
          !file.type.startsWith("image/") &&
          !file.type.startsWith("video/") &&
          file.type !== "application/pdf"
      )
      .map((file) => file.name);

    this.setState((prevState) => ({
      newMessage: documentFiles.length > 0
        ? `${prevState.newMessage} (${documentFiles.join(", ")})`
        : prevState.newMessage,
      selectedFiles: [...prevState.selectedFiles, ...validFiles],
      filePreviews: [...prevState.filePreviews, ...imagePreviews],
      videoPreviews: [...prevState.videoPreviews, ...videoPreviews],
      pdfPreviews: [...prevState.pdfPreviews, ...pdfPreviews],
      validFilesPreview: [...prevState.validFilesPreview, ...validFilesPreview],
     
    }));
  };

  handleLawyerSelect = (lawyer: DataResponse) => {
    const { selectedLawyers} = this.state;
   
    const lawyerId = lawyer?.id;
    const index = selectedLawyers.findIndex(selected => 
      selected?.id === lawyerId
    );
  
    if (index > -1) {
      this.setState(prevState => {
        const updatedLawyers = prevState.selectedLawyers.filter((_, i) => i !== index);
        
        return { selectedLawyers: updatedLawyers };
      });
    } else {
      const updatedLawyers = [...this.state.selectedLawyers, lawyer];
        this.setState({ selectedLawyers: updatedLawyers }, this.createChannelID);
      this.setState({ openPop: false,searchQuery:"",searchLawyer:"" }); 
    }
  };
  handleLawyerSelect2 = (chatRoomId: number) => {
    let { userList } = this.state;
  
    if (!Array.isArray(userList)) {
      console?.error("Error: userList is not an array", userList);
      userList = [];
    }
  
    const updatedUserList = userList?.map((user) => {
      if (user.chat_room_id === chatRoomId) {
        return {
          ...user,
          is_new_message: false,
          is_read_message: true,
        };
      }
      return user;
    });
  
    const matchedUser = updatedUserList?.find((user) => user.chat_room_id === chatRoomId);
  
    if (matchedUser) {
      if (!matchedUser.chat_room_id) {
        console?.error("Error: chat_room_id is missing for the selected user");
        return; 
      }const name = `${matchedUser.user_first_name} ${matchedUser.user_last_name}`;
      const profile = matchedUser.user_profile?.url ? { url: matchedUser.user_profile.url } : null;

      sessionStorage.setItem("chat_room_id", String(matchedUser.chat_room_id));
      const selectedLawyerDataResponse: DataResponse = {
        id: matchedUser.user_id,
        name,
        profile,
        type: matchedUser.user_jurisdiction,
      };
      

      const selectedChatRoom: ChatRoom = {
        chat_room_id: matchedUser.chat_room_id,
        user_id: matchedUser.user_id,
        user_first_name: matchedUser.user_first_name,
        user_last_name: matchedUser.user_last_name,
        user_profile: matchedUser.user_profile ? { url: matchedUser.user_profile.url } : null,
        user_jurisdiction: matchedUser.user_jurisdiction,
        is_new_message: false,
        is_read_message: true,
        last_message: "",
        last_message_at: "",
        unread_count: 0,
        channel_sid: matchedUser.channel_sid,
      };
      


      this.setState(
        {
          userList: updatedUserList,
          selectedUser: [selectedChatRoom],
          selectedLawyers: [selectedLawyerDataResponse],
          channelSID: matchedUser.channel_sid,
          isInviteSent: true,
          searchQuery: "",
          searchLawyer: "",
        },
        () => {
          if (typeof this.getUserList === "function") {
            setTimeout(() => {
              this.getUserList();
            }, 500);
          }
          this.getUserProfile();
          this.getChatList();
        }
      );
    } else {
      console?.error("Error: No matched user found for the selected chat room ID");
    }
  };

  

  
  closePop=()=>{
    this.setState({openPop:false})
   
  }
  
  handleClick = (event: { currentTarget: any; }) => {
    const taskId = sessionStorage.getItem("TaskID");
    this.setState({
      anchorEl: event.currentTarget, 
      taskViewId: taskId,
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false, 
      anchorEl: null, 
    });
  };
  
    handleRemoveSelectedLawyer = (lawyer: DataResponse) => {
      this.setState((prevState) => ({
        selectedLawyers: prevState.selectedLawyers.filter(
          (selected) => selected?.id !== lawyer?.id
        ),
      }));
    };


 
  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    
       
  
   
      if (apiRequestCallId === this.postCreateChannelId) {
        if (responseJson) {
            const channelSID = responseJson.channel_sid;
            
            this.setState({ channelSID });
        }
    }
    
    if (apiRequestCallId === this.getUserListId) {
      if (responseJson) {
        this.setState({ userList: responseJson,loading:false });        
      }
  }
 
  this.handleFilesData(apiRequestCallId,responseJson);
  this.handleSearchApiREsponse(apiRequestCallId,responseJson)
  this.handleGetUserProfile(apiRequestCallId,responseJson)
  this.handleMessageResponse(apiRequestCallId, responseJson);
  this.handleTaskResponse(apiRequestCallId, responseJson);
  this.handleArchiveResponse(apiRequestCallId,responseJson)
  }

   handleSearchApiREsponse=(apiRequestCallId: string,responseJson: any)=>{
    if(apiRequestCallId === this.getSearchApiCallId){
      if (responseJson) {
        this.setState({ searchResults: responseJson});
      } else {
        this.setState({ searchResults: [] });
      }

  }
   }
  handleGetUserProfile=(apiRequestCallId: string,responseJson: any)=>{
    if (apiRequestCallId === this.getUserProfileApiCallId) {
      if (responseJson) {
        this.setState({ userdata: responseJson?.user?.data?.attributes })
      }}
  }
 

  handleFilesData=(apiRequestCallId:string,responseJson: any)=>{
    if (apiRequestCallId === this.getAllFoldersApiCallId) {
    if (responseJson) {
      this.setState({uploadedFil:[...responseJson]});
    }}
   }
  
 handleArchiveResponse = (apiRequestCallId: string, responseJson: any) => {
  if (apiRequestCallId === this.putArchivedChat) {
    if (responseJson && responseJson.success) {
      if (responseJson.message === "Chat Restored Successfully") {
        this.setState({ archived: true });
        this.getUserList()
      } else if (responseJson.message === "Chat Archieved Successfully") {
        this.setState({ archived: false });
        this.getUserList()
      }
    } else {
      console.error("Error: Archive or Restore action failed", responseJson);
    }
  }
};
toggleArchive = () => {
  this.setState(
    (prevState) => ({ archived: !prevState.archived, loading: true ,open:false,isInviteSent:false,searchQuery:"",searchLawyer:""}),
    () => {
      this.getUserList()
      this.getUserList()
    }
  );
};


 handleTaskResponse = (apiRequestCallId: string, responseJson: any) => {
  if (apiRequestCallId === this.getTasksApiCallId) {
    if (responseJson?.data) {
      this.setState({ taskView: responseJson.data });
    }
  }
};
handleSearchChange2 = (event: { target: { value: string; }; }) => {
  const query = event.target.value;
  this.setState({   searchLawyer: query ,isInviteSent:false}, () => {
    this.getUserList();
  });
};
handleSearchFiles = (event: { target: { value: string } }) => {
  const query = event.target.value;
  this.setState({ searchFiles: query }, () => {
 
    if (query.trim() !== "") {
      this.getAllFolders();
    }
  });
};

  handleMessageResponse=(apiRequestCallId: string, responseJson: any)=>{
    if (apiRequestCallId === this.getMessageListId) {
      if (responseJson) {
        this.setState({ messageList: responseJson.messages })
      }
    }
  }
  handleChangeMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const messages = e.target.value;
    this.setState({
      createChat: {
        ...this.state.createChat,
        message: messages,
      },
      hasError: false,
    });
  };
  determineMainToken1 = (
    metaSignUpToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } },
    metaLoginToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } }
  ): { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } } | null => {
    if (metaSignUpToken?.data?.id) {
      if (metaLoginToken?.id && metaSignUpToken.data.id !== metaLoginToken.id) {
        return metaLoginToken;
      }
      return metaSignUpToken;
    }

    if (metaLoginToken?.id) {
      return metaLoginToken;
    }

    return null; 
  };

  getSearchData= async (query:any) => {

    const ctoken: string = await getStorageData("clientTokes");

    const header = {
      "token": ctoken,
      "Content-Type": "application/json",
    };

    const taskId = sessionStorage.getItem("TaskID")
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSearchApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_projecttemplates/project_templates/task_owner_names_list?id=${taskId}&first_name=${query}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
 
  getAllFolders= async () => {
    const signupTokens: string = await getStorageData("userdetails");
  const loginTokens: string = await getStorageData("token");
  const metaSignUpTokens = JSON.parse(signupTokens);
  const metaLoginTokens = JSON.parse(loginTokens);

  const maintoken = this.determineMainToken1(metaSignUpTokens, metaLoginTokens);
  const ctoken: string = await getStorageData("clientTokes");
  const header = {
    "Content-Type": "application/json",
    "token": ctoken || maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token}
    const taskId = sessionStorage.getItem("TaskID");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllFoldersApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_chat/chat_rooms/all_medias_of_task?task_id=${taskId}&search=${this.state.searchFiles}`,
     
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  getUserProfile = async () => {
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);
  
    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);
    const ctoken: string = await getStorageData("clientTokes");
    
    const header = {
      "Content-Type": "application/json",
      "token": ctoken || maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserProfileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userDetalsEndPoint}${ maintoken?.id || maintoken?.data?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleBackNavigationOrDashboardNavigation = (type: "back" | "dashboard") => async() => {
    const navigationObj: Record<typeof type, Record<string, string>> = {
      "back": {
        client: "TaskList",
        lawyer: "TaskListLawyer",
        lawfirm_admin: "TaskListLawfirm"
      },
      "dashboard": {
        client: "ClientDashboard",
        lawyer: "LawyerDashboard",
        lawfirm_admin: "DashBoard"
      },
    }

    const userDetail = await getStorageData("token", true)
    let baseNav = navigationObj[type].client;
    let userRole = "client"
    
    if(userDetail) {
      userRole = userDetail.user_role
      baseNav = navigationObj[type][userRole]
    }

    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
    baseNav);
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);

    this.send(message);

    sessionStorage.removeItem("taskID")
  }
  

createChannelID = async () => {
  const { selectedLawyers } = this.state;

  const receiverId = selectedLawyers[0]?.id; 

  const ctoken: string = await getStorageData("clientTokes");
  const header = {
      "token": ctoken,
      "Content-Type": "application/json",
  };

  const taskId = sessionStorage.getItem("TaskID");
  const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.postCreateChannelId = requestMessage.messageId;

  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_chat/chat_rooms/create_channel?task_id=${taskId}&receiver_id=${receiverId}`
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};
sendInvite = async (newMessage: string) => {
  const { channelSID, selectedFiles } = this.state;

  const encodedMessage = encodeURIComponent(newMessage);

  const signupTokens: string = await getStorageData("userdetails");
  const loginTokens: string = await getStorageData("token");
  const metaSignUpToken = JSON.parse(signupTokens);
  const metaLoginToken = JSON.parse(loginTokens);

  const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);
  const ctoken: string = await getStorageData("clientTokes");

  const header = {
    "token": ctoken || maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
  };

  const formData = new FormData();
  formData.append("message", newMessage);
  formData.append("channel_sid", channelSID);

  selectedFiles.forEach((file) => {
    formData.append("attachments[]", file, file.name);
  });

  const url = `bx_block_chat/chat_rooms/send_message_to_channel?channel_sid=${channelSID}&message=${encodedMessage}`;

  const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.postSendInviteApiCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    url
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formData
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.postApiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};


getChatList = async () => {
  const signupTokens: string = await getStorageData("userdetails");
  const loginTokens: string = await getStorageData("token");
  const metaSignUpToken = JSON.parse(signupTokens);
  const metaLoginToken = JSON.parse(loginTokens);

  const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);
  const ctoken: string = await getStorageData("clientTokes");
  const header = {
    "Content-Type": "application/json",
    "token": ctoken || maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
  }
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getMessageListId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
  `bx_block_chat/chat_rooms/fetch_messages?channel_sid=${this.state.channelSID}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getApiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};

getUserList= async ()=>{
  const signupTokens: string = await getStorageData("userdetails");
  const loginTokens: string = await getStorageData("token");
  const metaSignUpTokens = JSON.parse(signupTokens);
  const metaLoginTokens = JSON.parse(loginTokens);

  const maintoken = this.determineMainToken1(metaSignUpTokens, metaLoginTokens);
  const ctoken: string = await getStorageData("clientTokes");
  const header = {
    "Content-Type": "application/json",
    "token": ctoken || maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token, }
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)  );
  const taskId = sessionStorage.getItem("TaskID")
  const archiveStatus = this.state.archived ? "true" : "false";
  this.getUserListId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
  `bx_block_chat/chat_rooms/chat_room_list?archived=${archiveStatus}&task_id=${taskId}&search=${this.state.searchLawyer}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getApiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}

getTasks = async () => {
  const signupTokens: string = await getStorageData("userdetails");
  const loginTokens: string = await getStorageData("token");
  const metaSignUpToken = JSON.parse(signupTokens);
  const metaLoginToken = JSON.parse(loginTokens);

  const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);
  const ctoken: string = await getStorageData("clientTokes");
  const header = {
    "Content-Type": "application/json",
    "token": ctoken || maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
  }

  const taskId = sessionStorage.getItem("TaskID")
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getTasksApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.tasksApiEndPoint}/${taskId}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getApiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};

handleArchiveStatus = (type: "Archive" | "Restore") => {
  this.handleArchive(type); 
this.toggleArchive()
this.toggleArchive()
};
handleArchive = async (type: "Archive" | "Restore") => {
  const chatRoomId = sessionStorage.getItem("chat_room_id");
  const parsedChatRoomId = chatRoomId ? Number(chatRoomId) : 0;

  const ctoken: string = await getStorageData("clientTokes");
  const header = {
    "Content-Type": "application/json",
    "token": ctoken
  };

  const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_chat/chat_rooms/archive_or_restore_chat?chat_room_id=${parsedChatRoomId}&type=${type}`
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.putApiMethod
  );

  this.putArchivedChat = requestMessage.messageId;
  runEngine.sendMessage(requestMessage.id, requestMessage);

  this.setState(prevState => ({
    archived: type === "Archive", 
    open: false, 
    isInviteSent:false
  }));
};


  // Customizable Area End
}
