import React from "react";

// Customizable Area Start
import {
  Box,
  TextField,
  InputAdornment,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Typography,
  Chip,
  Grid,
  Drawer,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import { styled ,createTheme,MuiThemeProvider} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { logo, userpic, right, file, filter, backk,Services, request ,tooltipIconpage, DocLibrary, FilledDocLibrary,chatV} from "./assets";
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import EditIcon from "@material-ui/icons/Edit";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import SearchIcon from '@material-ui/icons/Search';
import JobListing from "../../../blocks/joblisting/src/JobListing.web";
import StarRateIcon from '@material-ui/icons/StarRate';
import VpnLockIcon from '@material-ui/icons/VpnLock';
import { StyledChip } from "./Tasks.web";
import TasksTableContent from "./components/TasksTableContent.web";
import Filter from './components/Filter.web'
import TaskForm from "./components/TaskForm.web";
import AuditTrail from "../../../blocks/audittrail/src/AuditTrail.web";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import { NotificationsNoneOutlined } from "@material-ui/icons";


// Customizable Area End

import TaskListController, { configJSON, Props } from "./TaskListController";

// Customizable Area Start
const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "2em",
        backgroundColor: "#fff",
        zIndex:999,
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        width:"450px",
        borderRadius:"12px",
        padding:"10px"
      }
    }
  }
});

const DeadlineTypography = styled(Typography)({
  color: "#262626",
  '&[data-state="false"]': {
    color: "#DC2626"
  },
})
// Customizable Area End


export default class TaskList extends TaskListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getStatus = (task:any) => {
    return task?.attributes?.status?.replace(/_/g, " ") || "unknown_status";
  };
  getSeeMoreButtonText = () => {
    return this.state.isExpanded ? "See less" : "See more...";
  };
  shouldShowSeeMoreButton = () => {
    const description = this.state.taskView?.attributes?.description;
    return this.checkIfContentIsLong(description);
  };
  fullText = () => {
    const desc = this.state.taskView?.attributes?.description;
    return desc;
  };
  renderDescription = () => {
    const fullText = this.fullText();

    if (this.state.isExpanded) {
      return fullText; }
 const truncatedText = `${fullText?.substring(0, 200)}...`;
    return (
      <>
        {truncatedText}{this.shouldShowSeeMoreButton() && this.renderSeeMoreButton()}
      </>); };

  renderSeeMoreButton = () => (
    <button onClick={this.handleSeeMoreClick}
      style={{
        position: "absolute",
        bottom: "2px",
        right: "-8px",
        padding: "3px",
        color: "#355EE7",
        border: "none",
        background: "white",
        cursor: "pointer",
        fontSize: "11px",
        fontWeight: 400,
        zIndex: 1,
        width: "12%",
        display: "flex",
        justifyContent: "flex-start",}}>
      {this.getSeeMoreButtonText()}</button>
  );

  checkIfContentIsLong = (description: string) => {
    const maxLength = 200;
    return description && description.length > maxLength;};
  drawer = () => (
    <DrawerBox style={{ display: this.state.mobileOpen ? 'block' : '' }
    }>
      <div className="toolbar" />
      <Divider />
      <div style={{display:'flex', justifyContent:'end', margin:"10px 10px 0px 0px "}}>
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.33056 7L7.51415 0.376953C7.59423 0.275391 7.52197 0.125 7.39111 0.125H5.88134C5.78564 0.125 5.69384 0.169922 5.63525 0.244141L0.648918 6.61523C0.562561 6.72527 0.515625 6.8611 0.515625 7.00098C0.515625 7.14085 0.562561 7.27668 0.648918 7.38672L5.63525 13.7559C5.69384 13.832 5.78564 13.875 5.88134 13.875H7.39111C7.52197 13.875 7.59423 13.7246 7.51415 13.623L2.33056 7ZM8.26806 7L13.4517 0.376953C13.5317 0.275391 13.4595 0.125 13.3286 0.125H11.8188C11.7231 0.125 11.6313 0.169922 11.5727 0.244141L6.58642 6.61523C6.50006 6.72527 6.45313 6.8611 6.45313 7.00098C6.45313 7.14085 6.50006 7.27668 6.58642 7.38672L11.5727 13.7559C11.6313 13.832 11.7231 13.875 11.8188 13.875H13.3286C13.4595 13.875 13.5317 13.7246 13.4517 13.623L8.26806 7Z" fill="#BFBFBF"/>
</svg>

        </div>
      <div className="logobox">
        <img src={logo.default} alt="logoj2" className="logoimag" data-test-id="logoImg" /></div>
      {this.state.mobileOpen && <CloseIcon style={{ position: 'fixed', top: '30px', left: '210px', cursor: 'pointer' }}
       onClick={this.handleclosesidebar} />}
       <Typography style={{marginLeft:"22px", color:'#BFBFBF' , fontFamily:'DM Sans', marginBottom:"10px", marginTop:'12px'}}>Actions</Typography>
      <List className="MainListBox">
        <ListItem onClick={this.handleNavigateDashboard} className="listbox">
          <ListItemIcon style={{ minWidth: 0 }}><HomeOutlinedIcon className="icondrawer2" />
          </ListItemIcon>
          <Typography className="listtile" data-test-id="Dashboard">
            Dashboard</Typography>
        </ListItem>
        <ListItem className="listbox1">
          <ListItemIcon style={{ minWidth: 0 , marginBottom:'5px'}}> 
              <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.0297 5.76641C17.1703 5.90703 17.25 6.09687 17.25 6.29609V20.75C17.25 21.1648 16.9148 21.5 16.5 21.5H1.5C1.08516 21.5 0.75 21.1648 0.75 20.75V1.25C0.75 0.835156 1.08516 0.5 1.5 0.5H11.4539C11.6531 0.5 11.8453 0.579688 11.9859 0.720313L17.0297 5.76641ZM15.5203 6.64062L11.1094 2.22969V6.64062H15.5203ZM4.5 10.2969C4.45027 10.2969 4.40258 10.3166 4.36742 10.3518C4.33225 10.387 4.3125 10.4346 4.3125 10.4844V11.6094C4.3125 11.6591 4.33225 11.7068 4.36742 11.742C4.40258 11.7771 4.45027 11.7969 4.5 11.7969H13.5C13.5497 11.7969 13.5974 11.7771 13.6326 11.742C13.6677 11.7068 13.6875 11.6591 13.6875 11.6094V10.4844C13.6875 10.4346 13.6677 10.387 13.6326 10.3518C13.5974 10.3166 13.5497 10.2969 13.5 10.2969H4.5ZM4.5 13.4844C4.45027 13.4844 4.40258 13.5041 4.36742 13.5393C4.33225 13.5745 4.3125 13.6221 4.3125 13.6719V14.7969C4.3125 14.8466 4.33225 14.8943 4.36742 14.9295C4.40258 14.9646 4.45027 14.9844 4.5 14.9844H8.8125C8.86223 14.9844 8.90992 14.9646 8.94508 14.9295C8.98025 14.8943 9 14.8466 9 14.7969V13.6719C9 13.6221 8.98025 13.5745 8.94508 13.5393C8.90992 13.5041 8.86223 13.4844 8.8125 13.4844H4.5Z" fill="#355EE7"/>
</svg>

          </ListItemIcon>
          <Typography className="listtile1" data-test-id="Dashboard">
            Tasks List</Typography>
        </ListItem>
        <ListItem className="listbox" >
          <ListItemIcon style={{ minWidth: 0 }}>
            <svg width="24" height="24" viewBox="0 0 24 24"  className="icondrawer2" fill="currentColor"xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M13.0653 14.25C13.0653 14.564 13.19 14.8651 13.4121 15.0872C13.6341 15.3092 13.9352 15.4339 14.2492 15.4339C14.5632 15.4339 14.8643 15.3092 15.0864 15.0872C15.3084 14.8651 15.4331 14.564 15.4331 14.25C15.4331 13.936 15.3084 13.6349 15.0864 13.4128C14.8643 13.1908 14.5632 13.0661 14.2492 13.0661C13.9352 13.0661 13.6341 13.1908 13.4121 13.4128C13.19 13.6349 13.0653 13.936 13.0653 14.25ZM16.7483 14.25C16.7483 14.564 16.873 14.8651 17.0951 15.0872C17.3171 15.3092 17.6182 15.4339 17.9322 15.4339C18.2462 15.4339 18.5473 15.3092 18.7694 15.0872C18.9914 14.8651 19.1161 14.564 19.1161 14.25C19.1161 13.936 18.9914 13.6349 18.7694 13.4128C18.5473 13.1908 18.2462 13.0661 17.9322 13.0661C17.6182 13.0661 17.3171 13.1908 17.0951 13.4128C16.873 13.6349 16.7483 13.936 16.7483 14.25ZM9.38231 14.25C9.38231 14.564 9.50705 14.8651 9.72907 15.0872C9.9511 15.3092 10.2522 15.4339 10.5662 15.4339C10.8802 15.4339 11.1814 15.3092 11.4034 15.0872C11.6254 14.8651 11.7501 14.564 11.7501 14.25C11.7501 13.936 11.6254 13.6349 11.4034 13.4128C11.1814 13.1908 10.8802 13.0661 10.5662 13.0661C10.2522 13.0661 9.9511 13.1908 9.72907 13.4128C9.50705 13.6349 9.38231 13.936 9.38231 14.25Z"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.7991 14.2883C22.8048 13.1265 22.5796 11.9982 22.135 10.9372C21.7037 9.91249 21.0851 8.99208 20.2974 8.20245C19.5146 7.4168 18.5853 6.79229 17.5622 6.36425C16.5122 5.92326 15.3973 5.7 14.2492 5.7L14.2124 5.7L14.2109 5.7C13.0558 5.70572 11.9352 5.9346 10.8812 6.38519C9.86681 6.81759 8.94623 7.44322 8.17077 8.2272C7.39075 9.01489 6.77776 9.93133 6.35386 10.9529C5.91494 12.0101 5.69361 13.1344 5.69933 14.2902C5.70568 15.5914 6.00809 16.8739 6.58324 18.0405V20.769C6.58324 21.0732 6.70409 21.365 6.91921 21.5801C7.13433 21.7952 7.4261 21.9161 7.73033 21.9161H10.4606C11.6272 22.4912 12.9097 22.7936 14.2109 22.8H14.2511C15.3934 22.8 16.5025 22.5786 17.5464 22.1454C18.5644 21.7225 19.4902 21.1054 20.2721 20.3284C21.0601 19.548 21.6807 18.6353 22.114 17.6178C22.5646 16.5639 22.7934 15.4434 22.7991 14.2883ZM11.1714 20.0347C12.1018 20.5305 13.1569 20.7951 14.2188 20.8004H14.2492C15.9935 20.8004 17.6301 20.128 18.8646 18.9058C20.1042 17.6767 20.7926 16.0347 20.8033 14.2797C20.8067 13.3878 20.6358 12.5254 20.2944 11.7124C19.9652 10.9273 19.4901 10.2214 18.8857 9.6171C18.2796 9.01096 17.5754 8.53752 16.7903 8.20833C15.986 7.8704 15.1324 7.69955 14.2511 7.69955H14.2204C12.4638 7.70675 10.8222 8.39344 9.59335 9.6346C8.36263 10.8759 7.69186 12.5229 7.69886 14.2799C7.70416 15.342 7.96868 16.3973 8.46464 17.3279L8.58278 17.5484V19.9165H10.9509L11.1714 20.0347Z"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M13.0653 14.25C13.0653 14.564 13.19 14.8651 13.4121 15.0872C13.6341 15.3092 13.9352 15.4339 14.2492 15.4339C14.5632 15.4339 14.8643 15.3092 15.0864 15.0872C15.3084 14.8651 15.4331 14.564 15.4331 14.25C15.4331 13.936 15.3084 13.6349 15.0864 13.4128C14.8643 13.1908 14.5632 13.0661 14.2492 13.0661C13.9352 13.0661 13.6341 13.1908 13.4121 13.4128C13.19 13.6349 13.0653 13.936 13.0653 14.25ZM16.7483 14.25C16.7483 14.564 16.873 14.8651 17.0951 15.0872C17.3171 15.3092 17.6182 15.4339 17.9322 15.4339C18.2462 15.4339 18.5473 15.3092 18.7694 15.0872C18.9914 14.8651 19.1161 14.564 19.1161 14.25C19.1161 13.936 18.9914 13.6349 18.7694 13.4128C18.5473 13.1908 18.2462 13.0661 17.9322 13.0661C17.6182 13.0661 17.3171 13.1908 17.0951 13.4128C16.873 13.6349 16.7483 13.936 16.7483 14.25ZM9.38231 14.25C9.38231 14.564 9.50705 14.8651 9.72907 15.0872C9.9511 15.3092 10.2522 15.4339 10.5662 15.4339C10.8802 15.4339 11.1814 15.3092 11.4034 15.0872C11.6254 14.8651 11.7501 14.564 11.7501 14.25C11.7501 13.936 11.6254 13.6349 11.4034 13.4128C11.1814 13.1908 10.8802 13.0661 10.5662 13.0661C10.2522 13.0661 9.9511 13.1908 9.72907 13.4128C9.50705 13.6349 9.38231 13.936 9.38231 14.25Z"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.75919 3.80026C2.97463 4.58765 2.3582 5.50358 1.93189 6.52463L3.75919 3.80026Z" />
<path d="M6.48431 1.95964C5.46451 2.39168 4.53893 3.01681 3.75919 3.80026C2.97463 4.58765 2.3582 5.50358 1.93189 6.52463C1.49043 7.58149 1.26779 8.7053 1.27355 9.86086C1.27997 11.1677 1.58691 12.4555 2.17054 13.6259V16.3956C2.17054 16.6824 2.28515 16.957 2.48871 17.1593C2.69222 17.3616 2.96795 17.475 3.25517 17.475H5.24829C4.87329 16.875 4.75367 16.125 4.74335 15.6134H4.04079V13.1666L3.92988 12.9608C3.42049 12.011 3.14921 10.9347 3.14379 9.8512C3.13661 8.05917 3.82526 6.37829 5.08897 5.11153C6.35084 3.8448 8.03704 3.14386 9.84108 3.13655H9.8724C10.7774 3.13655 11.6544 3.31102 12.4806 3.656C12.8955 3.82892 13.359 4.02435 13.8733 4.44283C14.9387 4.36432 15.469 4.39717 16.4589 4.50003C16.3729 4.3894 16.2731 4.27308 16.164 4.15449C15.9212 3.89057 15.6197 3.60227 15.2912 3.32077C14.6381 2.7612 13.8586 2.212 13.2014 1.9387C12.1458 1.49808 11.0249 1.275 9.87053 1.275L9.83316 1.27499L9.83205 1.275C8.67038 1.28072 7.54387 1.50943 6.48431 1.95964Z" />
</svg>



          </ListItemIcon>
          <Typography className="listtile" data-test-id="task">
            Live Chat
          </Typography>
        </ListItem>
        <ListItem className="listbox" >
          <ListItemIcon style={{ minWidth: 0 }} >
            <svg width="22" height="20" className="icondrawer2" viewBox="0 0 22 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M21.5 8.98913C21.5 8.95652 21.4674 8.82609 21.4348 8.72826L18.5978 2.76087C18.5326 2.56522 18.3043 2.40217 18.0761 2.40217H11.6522V0.934783C11.6522 0.543478 11.3587 0.25 10.9674 0.25C10.5761 0.25 10.2826 0.51087 10.2826 0.869565V2.36957H3.95652C3.76087 2.36957 3.53261 2.56522 3.43478 2.76087L0.565217 8.69565C0.532609 8.79348 0.5 8.95652 0.5 8.95652C0.5 10.913 2.06522 12.4783 4.02174 12.4783C5.97826 12.4783 7.54348 10.913 7.54348 8.95652C7.54348 8.92391 7.54348 8.79348 7.47826 8.69565L5.09783 3.70652H10.2826V18.1522H3.95652C3.95652 18.1522 3.95652 18.4457 3.95652 18.837C3.95652 19.2283 3.95652 19.5217 3.95652 19.5217H17.9783V18.837V18.1522H11.6522V3.70652H16.837L14.4565 8.69565C14.3913 8.79348 14.3913 8.95652 14.3913 8.95652C14.3913 10.913 15.9565 12.4783 17.913 12.4783C19.8696 12.5109 21.5 10.9457 21.5 8.98913ZM4.02174 4.61957L5.78261 8.30435H2.26087L4.02174 4.61957ZM4.02174 11.1413C3.07609 11.1413 2.26087 10.5217 1.96739 9.67391H6.04348C5.78261 10.5217 4.96739 11.1413 4.02174 11.1413ZM19.7391 8.30435H16.1848L17.9457 4.61957L19.7391 8.30435ZM17.9457 11.1413C17 11.1413 16.1848 10.5217 15.8913 9.67391H19.9674C19.7391 10.5217 18.8913 11.1413 17.9457 11.1413Z" />
            </svg>


          </ListItemIcon>
          <Typography className="listtile" data-test-id="task">
            Catalog
          </Typography>
        </ListItem>
        <ListItem className="listbox" data-test-id="paymentTestTab" onClick={this.handleRedirectPayment} >
          <ListItemIcon style={{ minWidth: 0 }} >
          <svg width="22" height="23" viewBox="0 0 22 23"
                className="icondrawer2"
                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.6251 6.31249V5.18749C16.6251 5.08437 16.5407 4.99999 16.4376 4.99999H7.4376C7.33448 4.99999 7.2501 5.08437 7.2501 5.18749V6.31249C7.2501 6.41562 7.33448 6.49999 7.4376 6.49999H16.4376C16.5407 6.49999 16.6251 6.41562 16.6251 6.31249ZM7.4376 8.37499C7.33448 8.37499 7.2501 8.45937 7.2501 8.56249V9.68749C7.2501 9.79062 7.33448 9.87499 7.4376 9.87499H11.7501C11.8532 9.87499 11.9376 9.79062 11.9376 9.68749V8.56249C11.9376 8.45937 11.8532 8.37499 11.7501 8.37499H7.4376Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.8132 12.125C14.0171 12.125 11.7507 14.3914 11.7507 17.1875C11.7507 19.9836 14.0171 22.25 16.8132 22.25C19.6093 22.25 21.8757 19.9836 21.8757 17.1875C21.8757 14.3914 19.6093 12.125 16.8132 12.125ZM16.8132 20.75C17.7648 20.75 18.6601 20.3797 19.3328 19.707C20.0054 19.0344 20.3757 18.1391 20.3757 17.1875C20.3757 16.2359 20.0054 15.3406 19.3328 14.668C18.6601 13.9953 17.7648 13.625 16.8132 13.625C15.8617 13.625 14.9664 13.9953 14.2937 14.668C13.621 15.3406 13.2507 16.2359 13.2507 17.1875C13.2507 18.1391 13.621 19.0344 14.2937 19.707C14.9664 20.3797 15.8617 20.75 16.8132 20.75Z" />
                <path d="M16.5627 20.3056V14.3724H17.0014V20.3056H16.5627ZM16.8394 19.7994C16.5019 19.7994 16.1959 19.7431 15.9214 19.6306C15.6514 19.5136 15.4377 19.3426 15.2802 19.1176C15.1227 18.8926 15.0372 18.6159 15.0237 18.2874H16.1037C16.1082 18.4179 16.1419 18.5326 16.2049 18.6316C16.2679 18.7306 16.3534 18.8094 16.4614 18.8679C16.5694 18.9219 16.6954 18.9489 16.8394 18.9489C16.9609 18.9489 17.0667 18.9309 17.1567 18.8949C17.2512 18.8544 17.3254 18.7981 17.3794 18.7261C17.4334 18.6496 17.4604 18.5551 17.4604 18.4426C17.4604 18.3256 17.4289 18.2266 17.3659 18.1456C17.3029 18.0601 17.2174 17.9881 17.1094 17.9296C17.0014 17.8666 16.8754 17.8104 16.7314 17.7609C16.5919 17.7069 16.4412 17.6551 16.2792 17.6056C15.9057 17.4841 15.6177 17.3199 15.4152 17.1129C15.2172 16.9059 15.1182 16.6291 15.1182 16.2826C15.1182 15.9946 15.1879 15.7494 15.3274 15.5469C15.4714 15.3399 15.6672 15.1824 15.9147 15.0744C16.1622 14.9664 16.4434 14.9124 16.7584 14.9124C17.0824 14.9124 17.3659 14.9686 17.6089 15.0811C17.8564 15.1891 18.0522 15.3489 18.1962 15.5604C18.3447 15.7674 18.4234 16.0149 18.4324 16.3029H17.3457C17.3412 16.2039 17.3119 16.1139 17.2579 16.0329C17.2084 15.9519 17.1387 15.8866 17.0487 15.8371C16.9632 15.7876 16.8619 15.7629 16.7449 15.7629C16.6414 15.7584 16.5469 15.7741 16.4614 15.8101C16.3759 15.8416 16.3084 15.8934 16.2589 15.9654C16.2094 16.0329 16.1847 16.1184 16.1847 16.2219C16.1847 16.3209 16.2094 16.4086 16.2589 16.4851C16.3129 16.5571 16.3849 16.6201 16.4749 16.6741C16.5694 16.7236 16.6774 16.7709 16.7989 16.8159C16.9249 16.8609 17.0622 16.9059 17.2107 16.9509C17.4492 17.0319 17.6674 17.1286 17.8654 17.2411C18.0679 17.3491 18.2299 17.4931 18.3514 17.6731C18.4774 17.8486 18.5404 18.0826 18.5404 18.3751C18.5404 18.6316 18.4729 18.8679 18.3379 19.0839C18.2074 19.2999 18.0162 19.4731 17.7642 19.6036C17.5167 19.7341 17.2084 19.7994 16.8394 19.7994Z" />
                <path d="M5.75046 18.875V2.46874H17.188C17.6022 2.46874 17.938 2.80453 17.938 3.21874V10.7187C18.612 10.8912 19.0341 11.0216 19.6255 11.375V3.0313C19.6255 1.78866 18.6182 0.781297 17.3755 0.781293L3.50009 0.78125C1.84326 0.781245 0.500121 2.12435 0.500079 3.78117L0.5 6.87497C0.499989 7.28919 0.835779 7.62499 1.25 7.62499H2.37532H4.06296V5.74999H2.37532V3.84678C2.37532 2.97825 3.26927 2.39727 4.06296 2.74999L-nan -nanL4.06296 2.74999V3.49999V5.74999V7.62499V21.5008C4.06296 21.6136 4.18276 21.686 4.28264 21.6336L5.42297 21.0354C5.46661 21.0125 5.5187 21.0125 5.56234 21.0354L6.8452 21.7084C6.8929 21.7334 6.95036 21.7309 6.99574 21.7019L8.01339 21.0506C8.06268 21.0191 8.12581 21.0191 8.1751 21.0506L9.18528 21.6971C9.23457 21.7287 9.2977 21.7287 9.34699 21.6971L10.3616 21.0478C10.4086 21.0177 10.4684 21.0162 10.5168 21.0439L11.7507 21.7489C11.1241 21.0436 10.8651 19.8822 10.6257 18.9696L10.5093 18.9109C10.4647 18.8885 10.4119 18.8896 10.3684 18.914L9.33943 19.4902C9.29389 19.5157 9.23838 19.5157 9.19285 19.4902L8.16753 18.916C8.122 18.8905 8.06649 18.8905 8.02095 18.916L6.99564 19.4902C6.95011 19.5157 6.8946 19.5157 6.84906 19.4902L5.75046 18.875Z" />
              </svg>
          </ListItemIcon>
          <Typography className="listtile" data-test-id="task">
            Billing
          </Typography>
        </ListItem>

        <ListItem   onMouseEnter={() => this.setIsHovered(true)}
              className="listbox" data-test-id="document_library"  onClick={this.handleDocumentFolderMenu} onMouseLeave={() => this.setIsHovered(false)}>
          <ListItemIcon style={{ minWidth: 0 }} >
              <img src={this.state.isLibraryHover ? FilledDocLibrary :DocLibrary} 
               style={{ width: '24px', height: '24px' }} className="icondrawer2"/>
            </ListItemIcon>
          <Typography 
          className="listtile" 
          data-test-id="Library2">
          Documents
          </Typography>
        </ListItem>
        

      </List>
      <Typography style={{marginLeft:"25px", color:'#BFBFBF' , fontFamily:'DM Sans', marginBottom:"10px", marginTop:'12px'}}>Management</Typography>
      <List  className="MainListBox">
     
        <ListItem className="listbox" onClick={this.handleSettingIn}> <ListItemIcon style={{ minWidth: 0 }}>
          <SettingsOutlinedIcon className="icondrawer2" /></ListItemIcon>
          <Typography className="listtile" data-test-id="Setting">Settings
          </Typography> </ListItem>
        <ListItem className="listbox"> 
          <ListItemIcon style={{
             minWidth: 0 
             }}>
          <NotificationsNoneOutlined className="icondrawer2" /></ListItemIcon>
          <Typography className="listtile" data-test-id="Setting">Notifications
          </Typography> </ListItem>
      </List>
       <Box className="userProfile">
                <Box>
                 
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" data-test-id="staticdata">
                    <circle cx="50" cy="50" r="50" fill="#ccc"/>
                    <circle cx="50" cy="35" r="15" fill="white"/>
                    <path d="M20 80c0-15 15-25 30-25s30 10 30 25" fill="white"/>
                  </svg>
                 </Box>
                 <Box style={{display:'flex', justifyContent:'space-between', alignItems:"center", width:'80%', marginBottom:"18px"}}>
                 <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '8px' }}>
                <img src={userpic} style={{ width: '35px', height: "35px", borderRadius: '50%', border:'1px solid #355EE7' }} alt="" />
                <Typography className="userName">{this.state.userdata|| "User"} </Typography>
              </Box>
              <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.999517 9.87461L4.87952 5.99461L0.999517 2.11461C0.812264 1.92778 0.707031 1.67413 0.707031 1.40961C0.707031 1.14509 0.812264 0.891441 0.999517 0.704609C1.38952 0.314609 2.01952 0.314609 2.40952 0.704609L6.99952 5.29461C7.38952 5.68461 7.38952 6.31461 6.99952 6.70461L2.40952 11.2946C2.01952 11.6846 1.38952 11.6846 0.999517 11.2946C0.619517 10.9046 0.609517 10.2646 0.999517 9.87461Z" fill="#262626"/>
      </svg>
      
                 </Box>
             
              </Box>
    </DrawerBox>)
  buttonMenu = () => (
    <ButtonBox className="buttonbox"> <IconButton
        className="iconbtn"
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={this.handleDrawerToggle}
        style={{ marginRight: '24px', color: '#000' }} >
        <MenuIcon /></IconButton>
    </ButtonBox>)
  sidebarr = () => {
    return (this.state.mobileOpen &&
      <> <div className="offcanvascss" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
          {this.drawer()}  </div>
      </> )}

  sideBarr2 = () => {
    return (
      this.state.mobileOpen && <div className="offcanvasbackdrop" onClick={this.handleclosesidebar}></div> )
  }

  formatDate = () => {
    const createdAt = this.state.taskView.attributes?.created_at;
    const formattedDate = createdAt
      ? new Date(createdAt).toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      })
      : '';
    return formattedDate;
  };
  seeMore = () => {
    const description = this.state.taskView?.attributes?.description;
    return this.checkIfContentIsLong(description);
  };
  getEditIconStyle = () => {
    return this.state?.taskView?.attributes?.status === "draft"
      ? { display: "block" }
      : { display: "none" };
  };
 

  drawerContents = () => (
    <Box style={{
        width: "100vh",
        maxWidth: "812px",
        padding: "16px",
        paddingLeft: "31px",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "auto",
        display: "flex",
        fontSize: "Dm sans", }} >
      <Grid  container
        style={{ display: "flex", justifyContent: "space-between",marginBottom:"11px" }} >
        <Box style={{
          display:"flex"
        }}> 
          <IconButton style={{
            cursor: "pointer",padding:"7px"}} onClick={this.toggleDrawerClose}>
            <img src={backk} alt=""
             style={{
              width: "18px",
              height: "18px"}} />
          </IconButton>
          <IconButton style={this.getEditIconStyle()}
            onClick={this.handleEditProject}>
            <EditIcon style={{
              color: "#BFBFBF",
              width: "18px",
              height: "18px" }} />
          </IconButton> </Box>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Typography style={{ color: "#8C8C8C", fontSize: "14px",
           fontWeight: 400, 
           fontFamily: "Dm sans" }}>
            Created: {this.formatDate()}</Typography>
          <IconButton
            className={"closeButton"}
            onClick={this.toggleDrawerClose} >
            <CloseIcon style={{
              color: "#BFBFBF",
              width: "18px",
              height: "18px"  }} />
          </IconButton></Box>
      </Grid>

      <Box style={{ width: "100%",
       height: "105px", 
       gap: "12px" ,marginBottom:"9px"}}>
        <StyledChip 
  data-state={this.state.taskView?.attributes?.state?.toLowerCase() || ""} 
  label={this.state.taskView?.attributes?.state || "N/A"} 
/>
        <Grid container style={{ display: "flex", 
        marginTop: "24px" }}>
          <Grid item> <img src={file} alt="file" /> </Grid>
          <Grid item style={{ marginLeft: '10px' }}> <Typography
              variant="body1"
              style={{fontFamily: "DM Sans",
                fontSize: "18px",
                fontWeight: "400",
                lineHeight: "23.44px",
                textAlign: "left",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "70ch",
                textTransform: "capitalize",
                whiteSpace: "nowrap",}}
              title={this.state.taskView?.attributes?.task_name} >
              {this.state.taskView?.attributes?.task_name}
            </Typography>
            <StyledChip 
              style={{ 
                width: "auto",
                padding: "2px 12px",
                minWidth: 72,
                fontWeight: 400,
                backgroundColor: "#F5F5F5"
              }}
              label={this.state?.taskView?.attributes?.status?.replace(/_/g, " ")}
            />
            </Grid>
        </Grid>  </Box>
      <Box style={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around", }}  >
        <Box style={{ margin: "8px 0" }}>
          <Typography style={{ color: "#8C8C8C",
           fontSize: "12px", fontWeight: 400,
           fontFamily: "Dm sans" }}>
            Trademark Name </Typography>
          <Typography
            title={this.state.taskView?.attributes?.trademark_name}
            style={{ fontSize: "16px", 
            fontWeight: 500, fontFamily: "Dm sans",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "calc(30ch + 1em)",
              textTransform: "capitalize"}}>
            {this.state.taskView?.attributes?.trademark_name}</Typography>  </Box>
        <Box style={{ margin: "8px 0" }}>
          <Typography style={{ color: "#8C8C8C",
           fontSize: "12px", fontWeight: 400,
            fontFamily: "Dm sans" }}>
            Trademark Application Number  </Typography>

          <Typography title={this.state.taskView?.attributes?.trademark_application_number}
            style={{ fontSize: "16px", fontWeight: 500, fontFamily: "Dm sans", textTransform: "capitalize" }}>
            {this.state.taskView?.attributes?.trademark_application_number}
            </Typography>
        </Box><Divider style={{
          margin: "16px 0px"
        }} />
        <Box style={{ margin: "8px 0" }}>
          <Typography style={{ color: "#8C8C8C", fontSize: "12px", fontWeight: 400, 
          fontFamily: "Dm sans" }}>
            Jurisdiction
          </Typography>
          <Typography
            style={{ fontSize: "16px", fontWeight: 500,
             fontFamily: "Dm sans" }}
          >{this.state.taskView?.attributes?.jurisdiction}
          </Typography>
        </Box>
        <Box style={{ margin: "8px 0" }}>
          <Typography style={{ color: "#8C8C8C", fontSize: "12px", fontWeight: 400,
           fontFamily: "Dm sans" }}>
            Legal Service Type
          </Typography>
          <Typography style={{ fontSize: "16px",
           fontWeight: 500, fontFamily: "Dm sans",
            textTransform: "capitalize" }} >
              {this.state.taskView?.attributes?.legel_service_type?.name}</Typography>
        </Box> 
        {
          this.state.taskView.attributes.created_at && (
            <Box
              data-test-id={`task-${this.state.taskView.id}-deadline`}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                margin: "8px 0",}} >
              <Typography
                style={{ color: "#8C8C8C", fontSize: "12px", fontWeight: 400, fontFamily: "Dm sans" }} >
                Deadline:
              </Typography>
          <Box style={{ display: "flex", alignItems: "center", gap: "6px" }}>
  <DeadlineTypography
    data-state={new Date(this.state.taskView.attributes.deadline).getTime() < new Date().getTime()}
    style={{
      fontSize: "16px",
      fontWeight: 500,
      fontFamily: "Dm sans",
      color: new Date(this.state.taskView.attributes.deadline).getTime() < new Date().getTime() ? "#DC2626" : "#262626",
    }}
  >
    {this.state.taskView.attributes.deadline && 
    !isNaN(new Date(this.state.taskView.attributes.deadline).getTime()) 
      ? new Date(this.state.taskView.attributes.deadline).toISOString().split("T")[0] 
      : "Invalid Date"}
  </DeadlineTypography>

  <CalendarTodayIcon
    style={{
      color: new Date(this.state.taskView.attributes.deadline).getTime() < new Date().getTime() ? "#DC2626" : "#BFBFBF",
      width: "18px",
      height: "18px",
    }}
  />

  {new Date(this.state.taskView.attributes.deadline).getTime() < new Date().getTime() && (
    <Typography
      style={{
        fontSize: "14px",
        fontWeight: 500,
        fontFamily: "Dm sans",
        color: "#DC2626",
      }}
    >
    Overdue task
    </Typography>
  )}
</Box>

            </Box>
          )
        }

        {this.state.taskView.attributes.request?.data?.attributes?.price &&
<Box
            data-test-id={`task-${this.state.taskView.id}-deadline`}
            style={{
              display: "flex", flexDirection: "column",
              alignItems: "flex-start",margin: "8px 0",
              gap:'2px'
            }}
          >
            <Typography  style={{
                color: "#8C8C8C",  fontSize: "12px",
                fontWeight: 400, fontFamily: "sans-serif",
              }}
            > Price  </Typography>
            <Box
              style={{
                backgroundColor: "#ECF1FF",  borderRadius: "8px",
                padding: "4px 8px", fontFamily: "sans-serif",
                fontSize: "14px", color: "#1A1A1A",  }}
            >
              <span style={{ fontWeight: "bold" }}>€</span>
              <span style={{ margin: "0 4px" }}>  {this.state.taskView.attributes.request?.data?.attributes?.price}
              </span>  <span style={{ fontWeight: "bold" }}>EUR</span>
            </Box>
          </Box>
        }
        <Divider style={{
          margin: "16px 0px"
        }} />
        <Box style={{ width: "100%", marginTop: "5px" }}>
          <Typography
            style={{ color: "#8C8C8C", fontSize: "12px", fontWeight: 400, fontFamily: "Dm sans" }}
          >
            Description
          </Typography>
          <Typography
            variant="subtitle2"
            style={{
              color: "#8C8C8C",
              marginLeft: 4,
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "1vw",
              textTransform: "capitalize"
            }}
          >
            {this.formatDate()}
          </Typography>
          <Typography
            style={{
              fontSize: "16px",
              fontWeight: 500,
              fontFamily: "Dm sans",
              wordWrap: 'break-word',
              whiteSpace: 'normal',
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: this.state.isExpanded ? 'none' : 2,
              position: "relative",
            }}
            className="description-text"
          >
            {this.renderDescription()}
          </Typography>

        </Box>

        <Box style={{ width: "100%", marginTop: "16px" }}>
          <Typography
            style={{ color: "#8C8C8C", fontSize: "12px", fontWeight: 400, fontFamily: "Dm sans" }}
          >
            Attachments
          </Typography>
          <Box 
            data-test-id="documents-folder" 
            onClick={()=>this.handleDocumentFolderNavigation(this.state.taskView.id)}
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#4872FF",
              marginTop: "11px",
              cursor: "pointer",
              width: "30%",
              color: "white",
              padding: "10px",
              borderRadius: "12px",
            }}
          >
            <AttachFileIcon style={{ marginRight: 8 }} />
            <Typography>Documents Folder</Typography>
          </Box>
        

          {this.state.selectedLawyers && this.state.selectedLawyers.length > 0 && (
  <>
    <Divider style={{
      marginTop: "15px",
      marginBottom: "18px"}} />
    <Box>
      <Box 
      style={{
        display: "flex",
        justifyContent: "space-between"}}>
        <Typography
         style={{
          fontSize: "14px",
          fontFamily: "Dm sans",
          color: "#8C8C8C",
          marginTop: "18px"
        }}>
          Assigned Lawyers
          </Typography>
        <Typography 
        onClick={this.handleLawerDialog}
         style={{
          display: "flex",
          alignItems: "center",
          gap: "4px",
          cursor: "pointer",
          marginTop: "18px"}}>
          <span 
          style={{
            fontSize: "12px",
            fontWeight: 400,
            fontFamily: "Dm sans",
            color: "#355EE7"}}>
            See all
          </span><img src={request} alt="" />
        </Typography></Box>
      <Box style={{
        maxHeight: "85px",
        overflowY: "scroll" }}>
            {this.state.selectedLawyers?.map((lawyer, index) => (
                    <Box key={index}
                     mt={1} 
                     style={{
                      border: '1px solid #F0F0F0', borderRadius: '10px',
                      backgroundColor:"#FAFAFA",
                      padding:"6px", }}>
                  
                        <Box data-test-id={`mapFunction${index}`}
                          style={{ display: "flex",
                            flexDirection: "row",
                            border: '1px solid #C3D1FF',borderRadius: "10px",alignItems: "center",
                            gap: "4px",padding: "6px",fontFamily: "Dm sans", }}>
                          <img 
                          src={lawyer.attributes.associated_lawyer_details.data.attributes.profile_image?.url || userpic} 
                          style={{
                            width: "39px",
                            height: "39px",
                            marginLeft: "3px",
                            borderRadius:"50px"}} />
                          <Box style={{
                            width:"100%" }}>
                            <Box
                              style={{ 
                                display: "flex",alignItems: "center",
                                gap: "15px",
                                fontFamily: "Dm sans", width: "100%", justifyContent:"space-between" }}>
                              <Typography style={{ fontWeight: 400, fontSize: "14px",
                               fontFamily: "Dm sans", 
                               marginLeft: "2px" }}>{lawyer.attributes.associated_lawyer_details.data.attributes.first_name}</Typography>
                              <Typography 
                              style={{ fontWeight: 400, 
                              fontSize: "16px", 
                              fontFamily: "Dm sans", color: "#8C8C8C", gap: "3px", display: "flex" }}>
                                4.2 <Typography>
                                  <StarRateIcon style={{
                                  color: "#F59E0B",
                                  width: "19px",
                                  height: "25px"
                                }} /></Typography>
                              </Typography> </Box>
                            <Box style={{
                              display: "flex",justifyContent:"space-between"  }}>   
                            <Typography style={{
                              display:"flex",
                              gap:"4px"}}>
                               {lawyer?.attributes?.associated_lawyer_details?.data?.attributes?.legel_service_type?.slice(0, 2)?.map((service, index) => (
                                <Typography
                                  style={{
                                    color: "#355EE7",
                                    backgroundColor: "#ECF1FF",
                                    borderRadius: "6px",
                                    padding: "2px 6px",
                                    fontFamily: "Dm sans",
                                    marginTop: "3px",
                                    marginLeft: "2px",
                                    width: "auto",
                                    height: "auto",
                                    fontSize: "11px",
                                    fontWeight: 400,
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "4px",
                                  }}
                                  key={index}
                                >

                                  <img
                                    src={Services}
                                    style={{
                                      width: "14px",
                                      backgroundColor: "none",
                                      height: "14px",
                                      marginRight: "4px",
                                    }}
                                    alt=""
                                  />
                                  <span>{service.legal_service_name}</span>
                                </Typography>
                             ))}
                                   { lawyer && lawyer.attributes.associated_lawyer_details.data.attributes.legel_service_type?.length > 2 && (
                      <MuiThemeProvider theme={theme}>
                      <Tooltip
                        title={
                          <div  style={{ width:"100%"}} >
                            <div style={{display:"flex",gap:"20px",width:"100%"}}>
                              <div style={{display: "grid",gridTemplateColumns:" repeat(2, 1fr)",gap:"10px",width:"80%",marginTop:"40px"}}>
                                {lawyer?.attributes.associated_lawyer_details.data.attributes.legel_service_type?.slice(2).map((service, index) => (
                                  <Chip
                                    key={index}
                                    label={service.legal_service_name}
                                    className="popup-chip"
                                    style={{ backgroundColor: '#ECF1FF ',
                                    color: '#355EE7 ',fontSize:"14px",textTransform:"capitalize",wordWrap: "break-word"}}
                                  />
                                ))}
                              </div>
                                    <div className="popup-image" style={{ justifyContent: "flex-end", display: "flex"}}>
                                    <img
                                  src={tooltipIconpage}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    marginBottom:"1px",
                                  }}
                                  alt=""
                                />
                                    </div>
                            </div>
                          </div>
                        }
                      >
                        <Typography
                          style={{
                            color: "#355EE7",
                            backgroundColor: "#ECF1FF",
                            borderRadius: "6px",
                            padding: "2px 6px",
                            fontFamily: "Dm sans",
                            marginTop: "3px",
                            marginLeft: "2px",
                            width: "auto",
                            height: "auto",
                            fontSize: "11px",
                            fontWeight: 400,
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                          }}
                        >
                          <img
                            src={Services}
                            style={{
                              width: "14px",
                              backgroundColor: "none",
                              height: "14px",
                              marginRight: "4px",
                            }}
                            alt="Service"
                          />
                          <span>+{lawyer?.attributes.associated_lawyer_details.data.attributes?.legel_service_type.length - 2}</span>
                        </Typography>
                      </Tooltip>
                    </MuiThemeProvider>
                    )}
                              </Typography>   
                              <Typography
                                variant="body2" style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: "2px",
                                  fontFamily: "Dm sans",
                                  gap: "3px"}} >
                                <VpnLockIcon style={{
                                  height: "18px",
                                  width: "18px",
                                  color: "#355EE7",
                                }} />
                                 <span style={{
                                  fontSize: "11px",
                                  fontFamily: "Dm sans",
                                  fontWeight: 400 }}>
                                    {lawyer.attributes.associated_lawyer_details.data.attributes.jurisdiction}
                                    </span></Typography>
                              </Box></Box>
                        </Box> </Box>
                  ))} </Box>
          </Box>
           </>
)}
          {this.state.isTaskPosted  && (
          <Box style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "10px",
                top: "108%",
                right: "0%",
                gap: "12px",
                position:"relative" }} >  
              <button style={{
                  color: "#F87171",
                  width: "145px",
                  height: "44px",
                  textTransform: "none",
                  borderRadius: "8px",
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "DM Sans",
                  cursor: "pointer",
                  border: "1px solid #F87171",
                  backgroundColor: "white",}}
                onClick={this.handleRejectTask} >
                Reject Offer
              </button>
              <button onClick={this.handleAcceptTask}
                style={{ backgroundColor: "#355EE7",
                  color: "white",
                  width: "145px",
                  height: "44px",
                  textTransform: "none",
                  borderRadius: "8px",
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "DM Sans",
                  cursor: "pointer",
                  border: "1px"}}>
                Accept Offer
              </button></Box>)}
        </Box>
        <Box style={{width:'100%', justifyItems:"end",display:"flex",
          justifyContent:"flex-end"
        }}>
        <Box  data-test-id='additionalData'
        onClick={this.handleAdditionanlFunction}
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#4872FF",
              marginTop: "16px",
              cursor: "pointer",
              width: "30%",
              color: "white",
              padding: "10px",
              borderRadius: "12px",
            }}
          >
            <PlaylistAddIcon/>
            <Typography>Add additional information</Typography>
          </Box>
        
        </Box>
        {this.state.isInprogress  && (
        <Box  data-test-id='chatProgress'
        onClick={this.handleNavigationChat}
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#4872FF",
              marginTop: "11px",
              cursor: "pointer",
              width: "105px",
              height:"32px",
              color: "white",
              padding: "6px 10px 6px 10px",
              borderRadius: "12px",
            }}
          >
            <Typography style={{display:"flex",alignItems:"center",gap:"6px"}}> <img src={chatV.default} alt="" />Messages</Typography>
          </Box>
        )}
      </Box>
    </Box>
     );
  renderTableContent = () => {
    const { loading, taskProfile } = this.state;
    if (loading) {
      return (
        <TableRow><TableCell colSpan={7} align="center">
            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
              <CircularProgress />  </Box>
          </TableCell></TableRow>
      ); }
    if (taskProfile.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={7} 
          align="center">
            <Box style={{ display: "flex",
             justifyContent: "center",
              alignItems: "center",
               height: "50vh" }}>
              <Typography style={{ textAlign: 'center', padding: '20px', 
              fontWeight: 'bold', display: "flex", 
              flexDirection: "column", fontFamily: "Dm sans", }}>
                No current tasks
                <span style={{
                  fontSize: "12px",
                  fontWeight: "normal",
                  color: "grey",
                  marginTop: "10px",
                  fontFamily: "Dm sans",
                }}>
                  When you create a new task, you can find it here</span>
              </Typography> </Box>
          </TableCell> </TableRow>
      );}

    return taskProfile?.map((task, index) => (
      <TableRow
        component="tr"
        key={index}
        style={{ borderSpacing: '0px !important',
          borderCollapse: 'unset',}}
        data-test-id={`task-${task.id}`}
        onClick={() => this.toggleDrawer(task)} >
        <TableCell
          style={{
            width: "12%",
            fontSize: "12px",
            fontWeight: 700,
            fontFamily: "Dm sans",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "calc(17ch + 1em)",
            textTransform: "capitalize"}}
          className="tableFont"
          title={task.attributes.task_name} >
          {task.attributes.task_name}
        </TableCell>

        <TableCell style={{
            width: "15%",
            fontSize: "12px",
            fontWeight: 400,
            fontFamily: "Dm sans",
            color: "#0F172A",
            textTransform: "capitalize" }}
        >
          {task.attributes.legel_service_type.name}
        </TableCell>
        <TableCell
          title={task.attributes.trademark_name}
          style={{width: "15%",
            fontSize: "12px",
            fontWeight: 400,
            fontFamily: "Dm sans",
            color: "#0F172A",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "calc(17ch + 1em)",
            textTransform: "capitalize" }} >
          {task.attributes.trademark_name}
        </TableCell>

        <TableCell
          title={task.attributes.trademark_application_number}
          style={{ width: "13.6%",
            fontSize: "12px",
            fontWeight: 400,
            fontFamily: "Dm sans",
            color: "#0F172A",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "100px",
            textTransform: "capitalize"}} >
          {task.attributes.trademark_application_number}
        </TableCell>

        <TableCell style={{ width: "14.8%" }}>
          {task.attributes.state === 'new_task' && (
            <Chip
              label='NEW'
              className={'chipNew'}
              style={{ width: '102px',
                height: '22px',
                borderRadius: '40px',
                padding: '11px 8px 8px 8px', fontFamily: "Dm sans", }} />
          )}
          {task.attributes.state === 'pending' && (
            <Chip
              label='PENDING'
              className={'chipPending'}
              style={{
                width: '102px',
                height: '22px',
                borderRadius: '40px',
                fontFamily: "Dm sans",
                padding: '11px 8px 8px 8px',}} />
          )}
          {task.attributes.state === 'in_progress' && (
            <Chip
              label='IN PROGRESS'
              className={'chipInProgress'}
              style={{
                width: '102px',
                height: '22px',
                borderRadius: '40px',
                fontFamily: "Dm sans",
                padding: '11px 8px 8px 8px', }} />
          )}
          {task.attributes.state === 'closed' && (
            <Chip
              label='CLOSED'
              className={'chipClosed'}
              style={{
                width: '102px',
                height: '22px',
                borderRadius: '40px',
                fontFamily: "Dm sans",
                padding: '11px 8px 8px 8px', }} />
          )}
        </TableCell>

        <TableCell style={{ width: "24%" }}>
          <Chip
            label={this.getStatus(task).replace(/_/g, " ")}
            className="chipStatus"
            style={{
              textTransform: "uppercase", fontFamily: "Dm sans",}} />
        </TableCell>

        <TableCell>
          <IconButton className={'iconButton'}>
            <ArrowForwardIosOutlinedIcon />  </IconButton>
        </TableCell>
      </TableRow> ));
  };

  handleFormSubmission = (values : any) => {
   if 
   (this.state.isSaveDraft) { this.handleSaveDraft(values);
    } else { this.submitData(values, false);
    }
  };

  // Customizable Area End

  render() {
    // Customizable Area Start   
    return (
      <><MainDiv data-test-id="projectTemplatesProfiles" style={{
        maxHeight: this.state.mobileOpen ? '100vh' : '',
        overflow: this.state.mobileOpen ? "hidden" : "" }}>
        <Box className="sidebar">
           <Box className="navigationstyle"  >
                              <NavigationMenu navigation={this.props.navigation} id={""} />
                          </Box>
          
          {this.buttonMenu()}
          {this.sidebarr()}</Box>
        {this.sideBarr2()}
        <Box className="rightbar">
        {this.state.openTaskForm?<TaskForm   data-test-id='task-form'  handleNavigationProject={this.handleNavigationProject}

       task_name={this.state.taskView?.attributes?.task_name } trademark_application_number={this.state.taskView?.attributes?.trademark_application_number} 
       deadline={
        this.state.taskView?.attributes?.created_at
          ? new Date(this.state.taskView.attributes.created_at).toISOString().split("T")[0]
          : "N/A"
      }
        description={this.state.taskView?.attributes?.description} 
       trademark_name={this.state.taskView?.attributes?.trademark_name} jurisdiction={this.state.taskView?.attributes?.jurisdiction} 
       legalServiceType={this.state.taskView?.attributes?.legel_service_type?.name} saveDraft={(formData) => {

         this.setState({ isSaveDraft: true }); this.handleFormSubmission(formData);
       }}
       closeTab={()=>{
         this.setState({ openTaskForm:false
         })
       }}

       submit={(formData) => { this.setState({ isSaveDraft: false }); this.handleFormSubmission(formData);
       }}

       isDraft={this.state.isSaveDraft } setIsDraft={() => this.setState({ isSaveDraft: !this.state.isSaveDraft })}
     />:
        <>
          <Box className="topbox">
          <JobListing projectId={this.state.taskView.id} handleUpdateCall={this.handleUpdateCalls} closeLawyer={this.handleCloseLawyerDialog} handleClose={this.handleCloseAccept} rejectClose={this.handleRejectClose} taskId={this.state.taskViewId} rejectDialog={this.state.rejectTask} lawyerDialog={this.state.lawyerDialog} acceptDialog={this.state.acceptTask} navigation={this.props.navigation} id={""} handleNavigate={undefined}/>
            <Typography data-test-id="backbtn" className="titleuser2">
              <img src={file} className="imageBox2" alt="" /> Tasks</Typography>
          </Box>
          <Box style={{ width: "92.5%",
            display: "flex",
            marginLeft: "60px",
            marginBottom: "30px",
            marginTop: "20px",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "10px" }}>

            <TextField
              variant="outlined"
              placeholder="Search by task name or trademark name or number"
              value={this.state.taskName}
              onChange={this.handleChange}
              onKeyDown={this.handleKeyDown}
              data-test-id='searchFieldTest'
              style={{ width: "100%" }}
              InputProps={{
                style: {
                  height: "44px",
                  borderRadius: "12px",
                  fontSize: "16px",
                  fontWeight: 400,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "#BFBFBF", width: "24px", height: "24px" }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={this.handleOpenFilter}>
                      <img src={filter} alt="Filter" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {this.state.openFilter&&(
              <Box style={{position:"absolute",
               right:'70px', top:'150px',backgroundColor:'#FFFFFF', 
               width:'670px',height:'110px',zIndex:40, border:'2px solid', 
               borderRadius:'14px',boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                borderColor:'#F5F5F5', display:'flex', justifyContent:'start', flexDirection:'column',padding:'10px'}}>
                <Box style={{display:"flex", justifyContent:'space-between', alignItems:'center'}}><Typography style={{color:'#355EE7'}}>Filters</Typography>
                  <CloseIcon style={{color:'#8C8C8C'}} 
                  onClick={()=>{this.setState({openFilter:false})}}
                  />
                </Box>
                <Box 
                style={{ display: 'flex', justifyContent: "space-evenly", 
                alignItems: 'center', gap: "4px", marginTop:"30px" }}>
                  <Filter
                    filterData={this.state.filterServiceOption}
                    taskStates={this.state.filterStateOption}
                    taskStatuses={this.state.filterStatusOption}
                    onServiceSelect={this.handleTaskServiceChange}
                    onTaskStateSelect={this.handleTaskStateChange}
                    onTaskStatusSelect={this.handleTaskStatusChange}
                    selectedSerciceType={this.state.selectedTaskService}
                    selectedTaskState={this.state.selectedTaskState}
                    selectedTaskStatus={this.state.selectedTaskStatus}
                  />
                </Box>
              </Box>
            )}
            <button onClick={this.handleNavigationProject} data-test-id="signup" className="landingSignupBtn"  >
              Add New task </button>
          </Box>
          <TasksTableContent handlePopoverOpen={this.handleAuditPopoverOpen} handlePopoverClose={this.handleAuditPopoverClose} data-test-id="tasks-client" loading={this.state.loading} tasksProfile={this.state.taskProfile} toggleDrawer={this.toggleDrawer} searchMessage={this.state.searchMessage} filterTask={this.state.filterTask} filterState={this.state.filterState}  />
           {Boolean(this.state.anchorEl) && 
           <AuditTrail 
              navigation={null} id="" 
              handleAuditPopoverClose={this.handleAuditPopoverClose} 
              anchorEl={this.state.anchorEl}
              taskId = {this.state.auditTaskId}
            />}
          </>
       } 
       </Box>
        
             <Drawer  
              anchor="right"
              open={this.state.drawerOpen}
              onClose={this.toggleDrawerClose}
              data-test-id="task-view-drawer" 
            >
              {this.drawerContents()} </Drawer>
        <Dialog
          PaperProps={{ style: {
              width: '600px',
              maxWidth: '600px',
              height: "239px",
              borderRadius: "12px",
              padding: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center" }
          }} style={{}} open={this.state.openDialog} 
          onClose={this.handleCloseDelete}
          aria-labelledby="responsive-dialog-title" >
          <Box style={{ border: "1px solid #D1FAE5",
            width: "576px",
            height: "215px",
            borderRadius: "8px", }}>
            <DialogTitle id="responsive-dialog-title" 
            style={{
              textAlign: 'right',
              padding: "7px"
            }}>
              <CloseIcon style={{ cursor: 'pointer' }} data-test-id = 'closeDeleteID' onClick={this.handleCloseDelete} /></DialogTitle>
            <DialogContent
              style={{ padding: "0px"
              }} >
              <DialogContentText  >
                <Typography style={{
                  backgroundColor: '#ffffff',
                  color: '#0F172A',
                  fontSize: '19px',
                  fontWeight: 400,
                  fontFamily: 'DM Sans',
                  marginBottom: '20px',}}>
                  <Box style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center" }}>
                    <img src={right}
                     className="imageBox2" alt="success" />
                  </Box>
                  <Typography 
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "25px",
                    fontSize: "22px",
                    fontFamily: "Dm sans",
                    fontWeight: 400 }}>
                  </Typography>
                </Typography>
              </DialogContentText>
            </DialogContent>
            </Box></Dialog>
      </MainDiv>
      </>);
    // Customizable Area End
  }
}

// Customizable Area Start
const MainDiv = styled('div')({
 "& .MuiTypography-body1 ":{
    fontFamily: 'DM Sans !important',
  },
  
  "& .selectedUser": {
    border: '1px solid #F0F0F0', borderRadius: '10px', display: 'flex', flexDirection: 'column', gap: '10px',
    maxHeight: '200px',
    overflowY: 'auto',
    backgroundColor: " #FAFAFA",
    scrollbarWidth: 'thin',
    scrollbarColor: '#355EE7 #F0F0F0',
    fontFamily: "Dm sans",
    width: "97.5%",
    padding: "6px"
  },
  "& .nativeSelect1": {
    width: "120px",
    height: "48px",
    minWidth: "220px",
    borderRadius: "8px",
    border: "1px solid #C3D1FF",
    backgroundColor: "white",
    fontSize: "16px",
    color: "#333",
    outline: "none",
  },
  "& .drawerResp": {
    width: "100vh",
    maxWidth: "812px",
    padding: "0px 16px 16px 31px",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    fontSize: "Dm sans",
    "@media (max-width:900px)": {
      width: "30vh",
    },
  },
  "& .scrollable-box": {
    height: '54.5%',
    overflowY: 'auto',
    display: 'block',
    marginBottom: "10px",
    flexGrow: 1,
    paddingRight: '0px',
    "@media (max-width:800px)": {
      display: 'inline-block',
    },
  }, "& .scrollable-box::-webkit-scrollbar": {
    width: "8px",
  },
  "& .scrollable-box::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },
  "& .scrollable-box::-webkit-scrollbar-thumb": {
    backgroundColor: "#91AAFF",
    borderRadius: '10px',
    border: '2px solid #f1f1f1',
  },
  "& .scrollable-box::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#5D85FF",
  },
  "& .tableFont": {
    width: "12%",
    fontSize: "12px",
    fontWeight: 700,
    fontFamily: "Dm sans"
  },
  "& .custom-datepicker-input": {
    width: "100%",
    border: "1px solid #C3D1FF",
    borderRadius: "12px",
    fontSize: "16px",
    fontWeight: 400,
    outline: "none",
    height: "44px",
    padding: "10px",
  },
  "& .custom-datepicker-input.error": {
    border: "1px solid red"
  },

  "& .custom-datepicker-input::placeholder ": {
    color: '#BFBFBF',
    fontFamily: 'DM Sans',
    fontSize: "16px",
    fontWeight: 400
  },
 
  width: '100%',
  height: '100%',
  display: 'flex',
  "@media (max-width:991px)": {
    display: "block",
  },
  "& .selectCountry": {
    width: '100%',
    border: '1px solid #C3D1FF', borderRadius: "12px",
    marginBottom: '8px', outline: 'none', backgroundColor: '#fff',
    fontSize: '16px', height: "44px",
    backgroundRepeat: 'no-repeat', backgroundPositionX: '95%',
    backgroundImage: 'url("data:image/svg+xml;utf8,<svg fill=\'%23c5cbc6\' height=\'20\' viewBox=\'0 0 24 24\' width=\'20\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M7 10l5 5 5-5z\'/></svg>")',
    fontWeight: 400, fontFamily: "DM Sans",
    backgroundPositionY: 'center', appearance: 'none',
    '&:hover': {
      border: '1px solid #C3D1FF', borderRadius: "12px"
    },
    "&:focus": {
      border: '2px solid #C3D1FF;', borderRadius: "12px"
    },
  },
  "& .offcanvascss": {
    position: 'fixed',
    top: '0px !important',
    bottom: '0px !important',
    left: '0% !important',
    width: '252px !important',
    height: '100vh !important',
    backgroundColor: '#fff',
    zIndex: 9999,
  },
  "& .offcanvasbackdrop": {
    position: "fixed",
    top: '0px',
    left: '252px',
    width: 'calc(100% - 252px)',
    height: '100vh',
    zIndex: 999,
    backgroundColor: '#000',
    opacity: 0.5,
  },
  "& .selectlegal": {
    width: '100% !important',
    borderRadius: '12px', fontSize: '16px',
    fontWeight: 400,
    fontFamily: "DM Sans",
    height: "44px",
    "& .css-13cymwt-control": {
      borderColor: "#C3D1FF !important",
      border: "1px solid",
      borderRadius: "12px",
      fonSize: "16px",
      fontweight: 400,
      outline: "none",
      position: "relative",
      bottom: "3px",
      height: "44px"

    },
    "& .css-t3ipsp-control": {
      borderColor: "#C3D1FF !important",
      border: "1px solid",
      borderRadius: "12px",
      fonSize: "16px",
      fontweight: 400,
      outline: "none",
      position: "relative",
      bottom: "3px",
      height: "44px",
      boxShadow: "0px 0px 0px 0px"

    },
    "& .css-1u9des2-indicatorSeparator": {
      backgroundColor: "white"
    },
    "&:focus": {
      border: '1px solid #C3D1FF;', borderRadius: "12px"
    },
    "@media (max-width:500px)": {
      width: '100% !important',
      borderRadius: '12px', fontSize: '8px',
      fontWeight: 200,
      fontFamily: "DM Sans",
      height: "44px",
    },
  },

  "& .landingSignupBtn": {
    backgroundColor: "#355EE7",
    color: "white",
    width: "145px",
    height: '44px',
    textTransform: "none" as "none",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "DM Sans",
    cursor: "pointer",
    border: 'none',
    '&:hover': {
      border: '2px solid #B9C5FF',
      boxShadow: 'none !important'
    }
  },
  "& .document": {
    backgroundColor: "#355EE7",
    color: "white",
    width: "179px",
    height: '46px',
    textTransform: "none" as "none",
    borderRadius: "8px",
    padding: "10px",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "DM Sans",
    cursor: "pointer",
    border: 'none',

  },
  "& .landingLoginBtn": {
    backgroundColor: "#fff",
    width: "145px",
    height: '46px',
    textTransform: "none" as "none",
    borderRadius: "8px",
    padding: "10px",
    color: "#355ee7",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "DM Sans",
    cursor: "pointer",
    border: '1px solid #d9d9d9',
    boxShadow: 'none !important',
    '&:hover': {
      border: '2px solid #B9C5FF',
      boxShadow: 'none !important'
    }

  },
  "& .firstNamePlaceHolder": {
    fontSize: "16px",
    fontWeight: 400
  },
  "& .sidebar": {
    height: '100%',
    position: "fixed",

    margin: 0,
    padding: 0,
    top: '0',
    left: '0',
    zIndex: 9999,
    "@media (max-width: 1100px)": {
      width: "300px"
    },
    "@media (max-width: 991px)": {
      width: '100vw',
      height: '80px'
    }
  },
  "& .mainheader": {
    position: 'fixed',
    left: '350px',
    width: '100%'
  },
  "& .logtxtdialog": {
    backgroundColor: "#355EE7",
    color: '#ffffff',
    borderRadius: '8px',
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'DM Sans',
    marginLeft: '15px',
    border: 'none',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    '&:hover': {
      border: '2px solid #B9C5FF',
      boxShadow: 'none !important',
    },
  },
  "& .Header": {
    height: '50px',
    fontSize: '20px', padding: '20px',
  },
  "& .rightbar": {
    padding: "10px 0px",
    width: "100%",
    marginLeft: '253px',
    backgroundColor: "#fafafa",
    height: "auto",
    "@media (max-width: 1100px)": {
      padding: '20 40px', width: 'calc(100% - 300px)',
      marginLeft: '300px',
    },
    "@media (max-width: 991px)": {
      width: 'auto !important',
      marginLeft: '0px', marginTop: '50px',
      padding: "10px 50px",
    }
  },
  "& .userdetailbox": {
    width: '90%',
    padding: "20px",
    backgroundColor: "#ffffff",
    height: "auto",
    marginLeft: "61px",
    borderRadius: "20px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
    marginBottom: "15px",
    "@media (max-width: 1424px)": {
      width: '89%',
      height: "auto",
      marginLeft: "51px",
      borderRadius: "20px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
      padding: "20px",
      backgroundColor: "#ffffff",
    },
    "@media (max-width: 1366px)": {
      width: '87%',
      padding: "20px",
      backgroundColor: "#ffffff",
      marginLeft: "61px",
      borderRadius: "20px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
      height: "auto",
    },
    "@media (max-width: 1280px)": {
      width: '87%',
      height: "auto",
      marginLeft: "61px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
      borderRadius: "20px",
      fontSize: "20px",
      padding: "20px",
      backgroundColor: "#ffffff",

    },
    "@media (max-width: 780px)": {
      width: '87%',
      fontSize: "20px",
      height: "auto",
      marginLeft: "31px",
      borderRadius: "20px",
      padding: "20px",
      backgroundColor: "#ffffff",

      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
    },
    "@media (max-width: 600px)": {

      height: "auto",
      marginLeft: "24px",
      borderRadius: "20px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
      width: '87%',
      fontSize: "20px",
      padding: "20px",
      backgroundColor: "#ffffff",
    },
    "@media (max-width: 520px)": {
      width: '87%',
      height: "auto",
      marginLeft: "24px",
      borderRadius: "20px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
      fontSize: "20px",
      padding: "20px",
      backgroundColor: "#ffffff",
    },
  },
  "& .profilebox": {
    height: '156px',
    display: 'flex', borderBottom: '1px solid #D9D9D9',
    "@media (max-width: 450px)": {
      height: '120px',
    },
  },
  "& .profileImg": {
    display: 'flex',
    justifyContent: "center", width: "80%",
  },
  "& .profiletext": {
    fontSize: '16px',
    fontWeight: 400, color: '#8C8C8C', fontFamily: 'DM Sans',
  },
  "& .userimg": {
    height: '120px',
    width: '120px',
    border: '4px solid #ECF1FF',
    borderRadius: '50%',
    "@media (max-width: 450px)": {
      height: '80px',
      width: '80px',
    },
  },
  '& .topbox': {
    display: 'flex',
    width: "auto",
    height: '70px',
    justifyContent: 'space-between',
    "@media (max-width: 991px)": {
      width: "auto !important",
    },
  },
  "& .titleuser": {
    height: '30px',
    width: '230px',
    fontSize: '22px',
    fontWeight: 400,
    fontFamily: "DM Sans",
    color: '#000',
    "@media (max-width: 450px)": {
      width: '200px',
      fontSize: '16px',
    },
  },
  "& .titleuser2": {
    height: '30px',
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "13px",
    width: '230px',
    fontSize: '27px',
    fontWeight: 700,
    fontFamily: "DM Sans",
    color: '#0F172A',
    marginLeft: "60px",
    marginTop: "13px",
    "@media (max-width: 450px)": {
      width: '200px',
      fontSize: '16px',
    },
  },
  "& .titleuser3": {
    height: '30px',
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "5px",
    width: '230px',
    fontSize: '22px',
    fontWeight: 400,
    fontFamily: "DM Sans",
    marginLeft: "80px",
    color: '#000',
    "@media (max-width: 450px)": {
      width: '200px',
      fontSize: '16px',
    },
  },
  "& .titlbtn": {
    height: '44px',
    fontSize: '14px', fontWeight: 400, color: '#355EE7', fontFamily: 'DM Sans',
    width: '163px', border: '1px solid #355EE7', borderRadius: '8px', padding: '10px 12px',
    "@media (max-width: 450px)": {
      height: '40px',
      width: '140px', padding: '7px 9px',
    },
  },
  "& .detailsbox": {
    display: 'flex', marginTop: '20px', paddingBottom: '40px',
    borderBottom: '1px solid #D9D9D9', "@media (max-width: 600px)": {
      display: 'block'
    },

  },
  "& .detailsbox2": {
    display: 'flex', marginTop: '20px',
    "@media (max-width: 600px)": {
      display: 'block'
    },

  },
  "& .personaldetailtext": {
    width: '15%', fontSize: '20px', fontWeight: 700,
    fontFamily: 'DM Sans', color: 'black',
    "@media (max-width: 600px)": {
      width: '100%', marginBottom: '20px'
    },
  },
  "& .personalform": {
    width: '88%',
    position: 'relative',
    "@media (max-width: 600px)": { width: '100%' },
  },
  "& .fullName": {
    width: '100%', display: 'flex', paddingBottom: '30px',
    "@media (max-width: 650px)": {
      display: 'block', width: '100%'
    }
  },
  "& .firstname": {
    width: "49%", marginRight: "10px",
    "@media (max-width: 650px)": {
      width: "91% !important", marginBottom: '15px',
    },
  },
  "& .lastname": {
    width: "49%",

    "@media (max-width: 650px)": { width: "91% !important", },
  },
  "& .lawFirm": {
    width: "99%", marginBottom: '15px',
  },
  "& .country": {
    width: "91%", marginBottom: '15px',
  },
  "& .PhoneNumer": {
    width: "91%", marginBottom: '15px',
  },
  "& .Email": {
    width: "91%", marginBottom: '15px',
  },
  "& .Password": {
    width: "91%", marginBottom: '15px', height: "28px",
  },
  "& .passtext": {
    color: "#434343", fontSize: '14px',
    fontWeight: 400, fontFamily: 'DM Sans',
  },
  "& .changepasstext": {
    color: "#6200EA", fontSize: '14px',
    fontWeight: 700, fontFamily: 'DM Sans', cursor: 'pointer', marginTop: '4px',
  },
  "& .accountsbox": {
    display: 'flex', marginTop: '20px',
    "@media (max-width: 600px)": {
      display: 'block',
      marginTop: '10px',
    },
  },
  "& .accountdetailtext": {
    width: '20%', color: "#8C8C8C",
    fontSize: '16px', fontWeight: 400, fontFamily: 'DM Sans',
    "@media (max-width: 600px)": {
      width: '100%',
      marginBottom: '20px'
    },
  },
  "& .accountsactions": {
    width: '84.3%', marginBottom: '40px',
    display: "flex", justifyContent: "flex-end",
    "@media (max-width: 600px)": { width: '100%', },
  },
  "& .logouttxt": {
    display: 'flex', alignItems: 'center', margin: '0px 0px 20px 0px', gap: "20px",
    color: "#434343", fontSize: '16px', fontWeight: 400, fontFamily: 'DM Sans',
    cursor: 'pointer',
  },
  "& .deletetxt": {
    display: 'flex', alignItems: 'center',
    margin: '20px 0px', color: "#DC2626", fontSize: '16px', fontWeight: 400, fontFamily: 'DM Sans',
    cursor: 'pointer',
  },
  "& .errorOutline": {
    '& .MuiFormControl-root': {
      '& fieldset': {
        borderColor: 'red 1px solid',
      },
    },
  },
  "& .MuiInputBase-input": {
    padding: '18.5px 14px',
  },
  "& .tableContainer": {
    marginTop: " theme.spacing(4)",
    "& .MuiPaper-elevation1": {
      boxShadow: "none !important"
    }
  },

  "& .tableHeader": {
    color: "#8C8C8C",
    fontSize: "12px",
    fontWeight: "500"
  },
  "& .chipNew": {
    backgroundColor: "#D1FAE5",
    color: "#059669",
    fontWeight: 700,
    fontSize: "10px"
  },
  "& .chipPending": {
    backgroundColor: "#DCECFF",
    color: "#355EE7",
    fontWeight: 700,
    fontSize: "10px"
  },
  "& .chipInProgress": {
    backgroundColor: "#FEF3C7",
    color: "#D97706",
    fontWeight: 700,
    fontSize: "10px"
  },
  "& .chipClosed": {
    backgroundColor: "#FEE2E2",
    color: "#DC2626",
    fontWeight: 700,
    fontSize: "10px"
  },
  "& .chipStatus": {
    fontSize: "10px",
    padding: '10px 8px 8px 8px',
    display: "flex",
    backgroundColor: ' #FAFAFA',
    width: '164px',
    height: '22px',
    borderRadius: '40px',
    fontWeight: 700,
    justifyContent: "flex-start"
  },
  "& .iconButton": {
    width: "24px",
    height: "24px",
    color: "#355EE7"
  },
  "& .iconButton2": {
    width: "24px",
    height: "24px",
    color: "#BFBFBF"

  },
  "& .drawer": {
    width: "50%",
    minWidth: "812px",
  },
  "& .closeButton": {
    marginLeft: "auto",
  },
  "& .calendarIcon": {
    verticalAlign: "middle",
  },
})


const DrawerBox = styled('div')({
  minHeight: '100vh',
  width: "252px",
  borderRight: '1px solid #E2E8F0',
  "& .logobox": {
     padding:'10px 117px 0px 22px',
    borderBottom:'2px solid #F5F5F5',
    display: 'flex', justifyContent: "start",
    height: "76px",
  },
  "& .logoimag":
  {
    height: '42px',
    width: '118px',
  },
  "& .settingbox":
  {
    display: 'flex',
    height: "65px",
  },
  "& .MainListBox": {
    padding: '0px 16px 8px 16px',
    position: 'relative'
  },
  "& .listbox": {
    height: '56px',
    borderRadius: '8px',
    "&:hover": {
      backgroundColor: "#ECF1FF",
      color: "#355EE7",
      "& .icondrawer2":
      {
        color: "#355EE7",
      },
    },
  },
  "& .listbox1": {
    height: '56px',
    backgroundColor: '#ECF1FF',
    display: 'flex',
    gap:"8px",
    marginBottom:"5px",
    textAlign: 'center',
    borderRadius: '8px',
    marginTop:"5px",
    
  },
  "& .icondrawer1": {
    color: '#355EE7'
  },
  "& .icondrawer2": {
    marginBottom: '2px',
  },
  "& .listtile1": {
    fontSize: "16px",
    color: '#355EE7',
    fontFamily: 'DM Sans',
    fontWeight: 700,
    cursor: 'pointer',
    marginRight: '38px'
  },
  "& .listtile":
  {
    fontSize: "16px",
    fontFamily: 'DM Sans',
    fontWeight: 400,
    cursor: 'pointer',
    marginLeft: '10px'
  },
  "& .userProfile": {
    display: "flex",
    width: '252px',
    position: 'absolute',
    bottom: '10px',
    left: '32px'
  },
  "& .imageBox": {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    border: '1px solid #F0E5FF',
    marginRight: '10px',
  },
  "& .imageBox2": {
    width: '90px',
    height: '90px',
    borderRadius: '50%',
  },
  "& .userName": {
    fontFamily: 'DM Sans',
    fontSize: '14px',
    fontWeight: 700,
    color: '#0F172A'
  },
  "& .userContent": {
    fontFamily: 'DM Sans',
    fontSize: '11px',
    fontWeight: 400,
    color: '#8C8C8C'
  },
  "@media (max-width: 1100px)":
  {
    zIndex: 999,
    width: '252px',
  },
  "@media (max-width: 991px)":
  {
    zIndex: 999,
    width: '252px',
    display: 'none',
  },
});
const ButtonBox = styled('div')(
  {
    display: 'none',
    "@media (max-width: 991px)":
    {
      width: '100%',
      display: 'block', minHeight: '70px',
      "& .buttonbox":
      {
        height: '70px', width: '100vw'
      },

      "& .iconbtn":
      {
        marginTop: '10px', marginLeft: '10px'
      }
    }
  })
// Customizable Area End
