export const insertPhoto = require("../assets/insert_photo.png");
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const threeDots = require('../assets/threedots.png');
export const folder = require('../assets/folder.png');
export const file = require('../assets/file.png');
export const backButton = require('../assets/Backbtn.png');
export const folderThreeDot = require('../assets/folderThreeDots.png');
export const logo = require("../assets/image_logo.svg");
export const pdfI = require("../assets/pdf.png")
export const docI = require("../assets/doc.png")
export const xlsI = require("../assets/xls.png")
export const pptI = require("../assets/ppt.png")
export const jpeg = require("../assets/jpeg.png")
export const pngFI = require("../assets/pngF.png")
export const mp4I = require("../assets/mp4.png")
export const userProfile = require("../assets/userProfile.png")
export const dropIcons = require("../assets/dropIcon.png")
export const userP = require("../assets/userPr.png")
export const noFile = require("../assets/vector.png")
export const iconFiles = require("../assets/iconFile.png")
export const DragIcons = require("../assets/DragIcon.png")
export const archieveFolders = require("../assets/archieveFolder.png")
export const archive = require("../assets/vectorFile.png")
export const filelogo = require("../assets/filelogo.png")
export const secondLogo = require("../assets/secondlogo.png")
export const arrowpng = require("../assets/arrow.png");
export const active = require("../assets/active.png");
export const close = require("../assets/closesvg.svg");
export const chattab = require("../assets/chattab.png");
export const filetab = require("../assets/filetab.png");
export const weighttab = require("../assets/weighttab.png");
export const threefolder = require("../assets/threefolder.png");
export const rightarrow = require("../assets/rightarrow.png");
export const hometab = require("../assets/hometab.png");
export const settingtab = require("../assets/settingtab.png");
export const paymenttab = require("../assets/paymenttab.png");
export const filtericon = require("../assets/filtericon.png");
export const group = require('../assets/group_.png')
export const group1 = require('../assets/group_ (1).png')
export const group2 = require('../assets/group_ (2).png')
export const arrowDown = require('../assets/arrowDown.png')
export const newgroup = require('../assets/newgroup.png')
export const chatG = require('../assets/chatG.png')
export const errorIcon = require('../assets/errorF.png')
export const taskIcon = require("../assets/taskicon.png");
export const filterLiveIcon = require("../assets/filterliveIcon.png");
export const searchIcon = require("../assets/searchicon.png");
export const messageIcon = require("../assets/messageicon.png");
export const userIcon = require("../assets/user.jpg");
export const attachmentIcon = require("../assets/attachment.png");
export const sendmessageIcon = require("../assets/sendmessage.png");
export const dateIcon = require("../assets/dateicon.png");
export const countryIcon = require("../assets/country.png");
export const archivepng = require('../assets/archive.png')
export const leftarrowIcon = require('../assets/leftarrow.png')
export const downlaodIcon = require('../assets/download.png')
export const docxx = require('../assets/bin.png')
export const refresh = require('../assets/refresh.svg')
export const doc = require('../assets/docxxx.png')

