import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ITask, ITaskList, TaskProfile } from "./types";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";

export interface LegalServiceType {
  id: number,
  name: string
}
export interface AssignedLawyers {
  data: LawyerData[];
}

export interface LawyerData {
  id: string;
  type: string;
  attributes: LawyerAttributes;
}

export interface LawyerAttributes {
  associated_lawyer_details: AssociatedLawyerDetails;
  legal_service_count: number;
}

export interface AssociatedLawyerDetails {
  data: LawyerDetailData;
}

export interface LawyerDetailData {
  id: string;
  type: string;
  attributes: LawyerDetailAttributes;
}

export interface LawyerDetailAttributes {
  first_name: string;
  last_name: string;
  website: string;
  firm: string;
  jurisdiction: string;
  full_phone_number: string;
  country_code: number;
  email: string;
  user_role: string;
  accomplishments: string;
  lawyer_status: string;
  legel_service_type: LegalServiceType[];
  years_of_experience: number;
  profile_image?: {
    url: string;
  };
  attachment: Attachment;
}

export interface LegalServiceType {
  id: number;
  legal_service_name: string;
  legel_service_description: string | null;
  created_at: string;
  updated_at: string;
}

export interface ProfileImage {
  url: string;
}

export interface Attachment {
  data: any[];
}
export interface ApiResponse {
  attributes: {
    associated_lawyer_details: {
      data: {
        id:number,
        attributes: {
          first_name: string;
          last_name: string;
          website: string;
          firm: string;
          jurisdiction: string;
          full_phone_number: string;
          country_code: number;
          email: string;
          user_role: string;
          accomplishments: string;
          lawyer_status: string;
          legel_service_type: [{
            id: string,
            legal_service_name: string,
            legel_service_description: string|null,
            created_at: string,
            updated_at: string
          }];
          years_of_experience: number;
          profile_image: {
            url?: string | null; 
          } | null;
        };
      }
    };
    legal_service_count: number;
  };
}
export interface TodoListResponse{
  id:string,
          attributes: {
              id: string,
              to_do_name: string,
              to_do_description: string,
              priority: string,
              status: string,
              deadline: string,
              account_id: 1,
              assign_lawyer: "",
              project_template_id: 1,
              created_at: "",
              updated_at: "",
              assigned_lawyers:{
                data:LawyerData[];
              }
          }
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleClose:boolean
  handleRejectClose:boolean;
  taskhandle: boolean;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  id: string;
  title: string;
  description: string;
  priority: string;
  status: string;
  assign_to: string;
  assign_to_type: string;
  taskList: ITask[];
  isVisibleAssignModal: boolean;
  dropdownGroup: boolean;
  dropdownStatus: boolean;
  dropdownAccount: boolean;
  dropdownPriority: boolean;
  isLawyer:boolean,
  name: string;
  editMode: boolean;
  token: string;
  taskLists: ITaskList[];
  taskProfile:TaskProfile[],
  taskView:{
  id: string;
  attributes: {
    task_name: string;
    legel_service_type: LegalServiceType;
    trademark_name: string;
    trademark_application_number: string;
    state: string;
    status: string;
    jurisdiction:string,
    deadline:string,
    description:string
    created_at:string,
    lawfirm_as_lawyer:boolean,
    request: {
      data: { id: string, 
        type: string,
          attributes: {
           price: string, }
  }}};
  },
  tasksData: ITask[];
  selectedTasks: ITask[];
  isVisibleModal: boolean;
  dropdownTasks: boolean;
  mobileOpen: boolean;
  open: boolean;
  openDialog: boolean
  drawerOpen: boolean,
  taskViewId:string ,
  isExpanded: boolean,  
  shouldShowSeeMore: boolean, 
  acceptTask:boolean,
  rejectTask:boolean,
  isTaskPosted:boolean,
  lawyerDialog:boolean,
  selectedLawyers:ApiResponse[],
  loading:boolean,
  dataLoad:boolean,
  todoList:TodoListResponse[];
  responseShow:{
    success:string,
    errors:string
  },
  taskName:string,
  searchMessage:boolean,
  filterState:boolean,
  filterTask:TaskProfile[],
  filterStateOption:{
    name:string,
    bgColor:string,
    textColor:string
  }[],
  filterStatusOption:string[],
  filterServiceOption:string[],
  selectedTaskStatus:string,
  selectedTaskState:string,
  selectedTaskService:string,
  openFilter:boolean
  finishTaskPopupOpen: boolean;
  isLibraryHover: boolean,
  openTaskForm:boolean,
  isSaveDraft:boolean,
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TasksController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getTasksApiCallId = "";
  postTaskApiCallId = "";
  putTaskApiCallId = "";
  deleteTaskApiCallId = "";
  getGroupsApiCallId = "";
  assignGroupApiCallId = "";
  getAccountsApiCallId = "";
  getTaskListsApiCallId = "";
  postTaskListApiCallId = "";
  putTaskListApiCallId = "";
  deleteTaskListApiCallId = "";
  getTasksApiCallIdd="";
  assignLawyerCallId="";
  getTodoListId="";
  patchFinishTaskId="";
  finishTaskId="";
  getServiceApiCallId = "";
  apiCreateProjectCallId: string = "";

  areToDosCompleted: boolean = false;

  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationTaskAllocatorMessage),
      getName(MessageEnum.NavigationTaskAllocatorId),
      getName(MessageEnum.NavigationProjectTrackingMessage),
      getName(MessageEnum.NavigationProjectTrackingId),
      getName(MessageEnum.ProjectTrackingMessage),
      getName(MessageEnum.NavigationChatMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: "",
      title: "",
      description: "",
      priority: "",
      status: "",
      assign_to: "",
      assign_to_type: "",
      editMode: false,
      token: "",
      taskList: [],
      isVisibleModal: false,
      isVisibleAssignModal: false,
      dropdownGroup: false,
      dropdownStatus: false,
      dropdownAccount: false,
      dropdownPriority: false,
      name: "",
      taskLists: [],
      tasksData: [],
      selectedTasks: [],
      dropdownTasks: false,
      mobileOpen: false,
      open: false,
      openDialog: false,
      drawerOpen: false,
      taskProfile:[],
      taskView:{
        id:"",
        attributes: {
          task_name: "",
          legel_service_type: {
            id: 1,
            name: "",
            legal_service_name: "",
            legel_service_description: "",
            created_at: "",
            updated_at: ""
          },
          trademark_name: "",
          trademark_application_number: "",
          state: "",
          lawfirm_as_lawyer:false,
          status: "",
          jurisdiction:"" ,
          deadline:"",
          description:"",
          created_at:"",
          request: {

            data: {
              id: '',
              type: '',
              attributes: {
                price: '',
              }
            }
          }
            }
        },
      taskViewId:"",
      isExpanded: false, 
      shouldShowSeeMore: false, 
      acceptTask:false,
      rejectTask:false,
      isTaskPosted:false,
      lawyerDialog:false,
      selectedLawyers:[],
      loading:true,
      dataLoad:false,
      todoList:[],
      taskName:'',
      filterState:false,
      searchMessage:false,
      finishTaskPopupOpen:false,
      isLawyer:false,
      filterTask:[],
      openFilter:false,
      filterStateOption:[
        { name: "new_task", bgColor: "#DFFFE1", textColor: "#008000" },

        { name: "in_progress", 
          bgColor: "#FFF4C2", 
          textColor: "#D89A00" },

        { name: "pending", 
          bgColor: "#DCE8FF", 
          textColor: "#0055FF" },

        { name: "closed", 
          bgColor: "#FFE3E3", 
          textColor: "#D30000" },
      ],
      filterStatusOption:['complete','assigned','digip_assessment','no_legal_case','draft','pending_client_approval','task_posted'],
      filterServiceOption:[],
      selectedTaskState: "", 
      selectedTaskStatus: "",
      selectedTaskService:"",
      responseShow:{
        success:"",
        errors:""},
      isLibraryHover: false , openTaskForm:false ,  isSaveDraft:false,
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getTaskLists();
    this.getServicesList();
    
  }
  
  async componentDidUpdate(prevProps: Readonly<Props>) {
    if(prevProps.taskhandle !== this.props.taskhandle){
      this.taskLoadData()
    }
  }

  taskLoadData = ()=>{
    this.setState({dataLoad:false})
  }

  navigateToTaskList = () => {
    this.props.navigation.navigate("TaskList");
  };

  navigateToTask = () => {
    this.props.navigation.navigate("Task");
  };
  handleSeeMoreClick = () => {
    this.setState((prevState) => ({
      isExpanded: !prevState.isExpanded,
    }));
  };
 

  getTaskLists = async() => {
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);
    const header = {
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTaskListsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksApiLawfirmEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handleNavigationProject = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "TaskListLawyer");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);

    this.send(message);
  }

  handleNavigateDashboard = () => {
    console.log("vaclled")
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "DashBoard");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);

    this.send(message);
  }

  handlegetTask = (responseJson: {data: TaskProfile[]}) => {
    if (responseJson.data) {
      this.setState({ 
        taskProfile: responseJson.data,
        loading:false
      },
        
      );
    } else {
      this.setState({ taskProfile: [], });
    }
  }

  
  receive = async (from: string, message: Message) => {

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

  const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)
  );
    if (apiRequestCallId === this.getTaskListsApiCallId) {
    this.handlegetTask(responseJson)
    } 
    if (apiRequestCallId === this.getServiceApiCallId) {
      if (Array.isArray(responseJson)) {
          const legalServiceNames = responseJson.map(service => service.legal_service_name || "");
          this.setState({ 
              filterServiceOption:legalServiceNames
          });
      }
      
  }
    if (apiRequestCallId === this.getTasksApiCallId) {
      if (responseJson.data) {
        const status = responseJson.data.attributes.status;
        const lawyerTrue = responseJson.data.attributes.lawfirm_as_lawyer;
        this.setState({ taskView: responseJson.data,
          isTaskPosted: status === "task_posted",
          isLawyer: lawyerTrue === true
        });
      } else{
        this.setState({ 
          isTaskPosted: false,
          isLawyer:false
          
        });
      }
      
    }
    if(apiRequestCallId === this.assignLawyerCallId){
      if (responseJson) {
        this.setState({ selectedLawyers: responseJson.data,
         
        });
      } 
  }
  if(apiRequestCallId === this.finishTaskId){
    this.handleFinishTask(responseJson)
}
  if(apiRequestCallId === this.getTodoListId){
    this.handleTodoApiResponse(responseJson)
 }  

 if(apiRequestCallId === this.apiCreateProjectCallId) {  

  this.setState({ openTaskForm:false
  })
} 

  };

  submitData = async (formValues:any, isSavedraft:boolean) => {   

    const signupTokens: string = await getStorageData("userdetails") ;const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens); const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpToken, 
      metaLoginToken);


    const header = { "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    };

    const formData = new FormData();
    formData.append("description", formValues.description);
    formData.append("additional_info",'true')

    if (isSavedraft) { formData.append("save_as_draft", "true");
    }


    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

   let endpoint = 
   `${configJSON.updateTask}`;
    
    if (!this.state.isSaveDraft) { endpoint = `${configJSON.updateTask}/
    ${this.state.taskViewId}`;
    }
    
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
     endpoint); this.apiCreateProjectCallId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
     formData);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), 
    JSON.stringify(header));

    const method = this.state.isSaveDraft ? 
    configJSON.postApiMethodPost : configJSON.putApiMethodPut;
    
    requestMessage.addData
    (getName(MessageEnum.RestAPIRequestMethodMessage),
     method);

    runEngine.sendMessage(requestMessage.id, 
    requestMessage);
};


handleSaveDraft = async (values:any) => { await this.submitData(values, true); 
};

handleAdditionanlFunction=() => { const taskId = sessionStorage.getItem("TaskID");
  
  this.setState(prevState => ({ drawerOpen: !prevState.drawerOpen, taskViewId: taskId||"", openTaskForm:true,
  }));
 
};
handleNavigationChat = () => {
  const message: Message = new Message(getName(MessageEnum.NavigationChatMessage));
  message.addData(getName(MessageEnum.NavigationChatMessage), true);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationChatMessageId), this.state.taskViewId);
     console.log(this.state.taskViewId,"meriID");
     
  this.send(message);
};

  handleTodoApiResponse = (responseJson:any) => {
    if (responseJson.data) {
      this.setState({
        todoList: responseJson.data,
        loading: false
      });
    }
};

handleLawyerfirmChange = (event:any) => {
  this.setState({ taskName: event.target.value 
  }, () => {
    this.handleSearchAndFilter();
  });
};

handleLawyerfirmKeyDown = (event:any) => {
  if (event.key === 'Enter') { this.handleSearchAndFilter();
  }
};

handleTaskStateChange = (stateValue: string) => {
  this.setState({ selectedTaskState: stateValue }, () => {
    this.handleSearchAndFilter();
  });
};

handleTaskStatusChange = (statusValue: string) => {
  this.setState({ selectedTaskStatus: statusValue }, () => {
    this.handleSearchAndFilter();
  });
};

handleTaskServiceChange = (serviceValue: string) => {
  this.setState({ selectedTaskService: serviceValue }, () => {
    this.handleSearchAndFilter(); 
  });
}


searchLawyerfirmAttribute =(task:any,taskName:string)=>{
  const taskNameAttr = task.attributes.task_name?.toLowerCase() || "";
  const trademarkName = task.attributes.trademark_name?.toLowerCase() || "";
  const applicationNumber = task.attributes.trademark_application_number?.toLowerCase() || "";
  const searchLawyerfirmQuery = taskName?.toLowerCase() || "";
  const matchesLawyerfirmSearch = searchLawyerfirmQuery === "" || 
  taskNameAttr.includes(searchLawyerfirmQuery) ||
  trademarkName.includes(searchLawyerfirmQuery) ||
  applicationNumber.includes(searchLawyerfirmQuery);
  return {matchesLawyerfirmSearch,searchLawyerfirmQuery}
}

handleSearchAndFilter = () => {
  const { taskName, selectedTaskState, selectedTaskStatus, selectedTaskService, taskProfile } = this.state;

  const filteredLawyerfirmResults = taskProfile.filter(task => {
      if (!task || !task.attributes) return false;

      const { matchesLawyerfirmSearch, searchLawyerfirmQuery } = this.searchLawyerfirmAttribute(task, taskName);
      const matchesAttributes = this.filterByLawyerfirmAttributes(task, selectedTaskState, selectedTaskStatus, selectedTaskService);

      if (searchLawyerfirmQuery && (selectedTaskState || selectedTaskStatus || selectedTaskService)) {
          return matchesLawyerfirmSearch && matchesAttributes;
      } else if (searchLawyerfirmQuery) {
          return matchesLawyerfirmSearch;
      } else if (selectedTaskState || selectedTaskStatus || selectedTaskService) {
          return matchesAttributes;
      }

      return true;
  });

  this.setState({
    filterState: Boolean(taskName) || Boolean(selectedTaskState) || Boolean(selectedTaskStatus) || Boolean(selectedTaskService),
    filterTask: filteredLawyerfirmResults,
    searchMessage: true
  });
};

filterByLawyerfirmAttributes = (task:any, selectedState:any, selectedStatus:any, selectedService:any) => {
  const stateOption = task.attributes.state?.toLowerCase() || "";
  const statusOption = task.attributes.status?.toLowerCase() || "";
  const serviceOption = task.attributes.legel_service_type?.name?.toLowerCase() || "";

  const matchesState = !selectedState || stateOption === selectedState.toLowerCase();
  const matchesStatus = !selectedStatus || statusOption === selectedStatus.toLowerCase();
  const matchService = !selectedService || serviceOption === selectedService.toLowerCase();

  return matchesState && matchesStatus && matchService;
};

handleLawyerfirmOpenFilter =()=>{
   this.setState({openFilter:true})
}

handleFinishTask = (responseJson: { meta: { message: any; }; errors?: { message: string }[]; }) => {
  if (responseJson?.errors ){
    this.setState({
      openDialog: true,
      responseShow: { errors: responseJson.errors[0].message, success: "" } 
    });
  } else if (responseJson?.meta?.message) {
    this.setState({
      openDialog: true,
      responseShow: { errors: "", success: responseJson.meta.message }
    });
  }

  setTimeout(() => {
    this.setState({ openDialog: false });
  }, 700);
 
  this.setState({
    finishTaskPopupOpen:false,drawerOpen:false});
};
  
  handleAcceptTask = () => {
    this.setState((prevState) => ({
      acceptTask: !prevState.acceptTask,
      drawerOpen: false
    }));
  };  
  handleLawerDialog = () => {
    this.setState((prevState) => ({
      lawyerDialog: !prevState.lawyerDialog,
      drawerOpen: false
    }));
    // this.assignLawyer()
  };
  handleCloseLawyerDialog = () => {
    this.setState({lawyerDialog:false});
  };
  handleEditProject = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationPropsMessage));
  
    message.addData(getName(MessageEnum.NavigationProjectTemplatesWithId), this.state.taskViewId);

    this.send(message);

  }

  handleOpenFinishTaskPopup=()=>{
    this.areToDosCompleted = this.state.todoList.every((todo) => todo.attributes.status.toLowerCase() === "completed")
    this.setState({finishTaskPopupOpen:true,drawerOpen:false})
  }

  handleCloseFinishTaskPopup=()=>{
    this.setState({finishTaskPopupOpen:false})
  }

  handleNavigateAllocator = () => {
 
    const message: Message = new Message(getName(MessageEnum.NavigationTaskAllocatorMessage));
      message.addData(getName(MessageEnum.TaskAllocatorMessage), true);
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationTaskAllocatorId), this.state.taskViewId);
  
      this.send(message);
}
  handleNavigationProjectTrack = (shouldShowPopup: boolean) => () => {
    const message: Message = new Message(getName(MessageEnum.NavigationProjectTrackingMessage));
      message.addData(getName(MessageEnum.ProjectTrackingMessage), true);
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationProjectTrackingId), this.state.taskViewId);
      sessionStorage.setItem("taskViewId",this.state.taskViewId)
      sessionStorage.setItem("showAddToDoPopup", `${shouldShowPopup}`)  
      this.send(message);
  }
  handleUpdateCalls = () => {
    this.getTaskLists();
  } 
  handleDocumentFolderNavigation = (taskID:string|number) =>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "Documentation");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);

    sessionStorage.setItem("taskID", `${taskID}`)
    this.send(message);
  }

 handleRejectTask = () => {
    this.setState((prevState) => ({
      rejectTask: !prevState.rejectTask,
      drawerOpen: false
    }));
  };

  handleClosePopup = () => {
    this.setState({ acceptTask: false});
}
handleClosed = () => {
  this.setState({rejectTask: false });
}
  
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: true });
  }

  handleclosesidebar = () => {
    this.setState({ mobileOpen: false });

  }

  handleClickOpen = () => {
    this.setState({ open: true })

  };

  handleClose = () => {
    this.setState({ open: false });

  };

  toggleDrawer = (task:{id:string}) => { const taskId = String(task.id); sessionStorage.setItem("TaskID",taskId)
    
    this.setState(prevState => ({
      drawerOpen: !prevState.drawerOpen, 
      taskViewId: taskId, 
    }));
    this.lawfirmTasks()
    this.assignLawyer()
    this.todoList()
  };

  toggleDrawerClose = () => {
    this.setState({ drawerOpen: false ,isExpanded:false}); 
  };



  handleSettingIn = async () => {
    const ctoken: string = await getStorageData("clientTokes");

    const message = new Message(getName(MessageEnum.NavigationMessage));

    if (ctoken) {
      message.addData(getName(MessageEnum.NavigationTargetMessage), "ClientSettings");
    } else {
      message.addData(getName(MessageEnum.NavigationTargetMessage), "Settings");
    }

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  determineMainToken1 = (
    metaSignUpToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } },
    metaLoginToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } }
  ): { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } } | null => {
    if (metaSignUpToken?.data?.id) {
      if (metaLoginToken?.id && metaSignUpToken.data.id !== metaLoginToken.id) {
        return metaLoginToken;
      }
      return metaSignUpToken;
    }

    if (metaLoginToken?.id) {
      return metaLoginToken;
    }

    return null; 
  };

  handleCloseDelete = () => {
    this.setState({ openDialog: false });
  };
 
  lawfirmTasks = async () => {
    const signupTokens: string = await getStorageData("userdetails");
    
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);

    const header: { "Content-Type": string; token: string | undefined } = {
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTasksApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksApiEndPoint}/${this.state.taskViewId}`
    );
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  assignLawyer = async () => {
    const signupToken: string = await getStorageData("userdetails");
    
    const loginToken: string = await getStorageData("token");
    const metaSignUpTokens = JSON.parse(signupToken);
    const metaLoginTokens = JSON.parse(loginToken);

    const maintoken = this.determineMainToken1(metaSignUpTokens, metaLoginTokens);

    const header: { "Content-Type": string; token: string | undefined } =
     {  "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token, "Content-Type": "application/json",
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.assignLawyerCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.assignLawyerApiEndPoint}${this.state.taskViewId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };  


  getServicesList= async () => 
    {const header = 
      {"Content-Type": "application/json"};
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getServiceApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.serviseListApi}`);
    requestMessage.addData( getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
    );
    requestMessage.addData( getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod );

    runEngine.sendMessage(requestMessage.id, 
      requestMessage);
  };

  todoList = async () => {
    const signupTokens: string = await getStorageData("userdetails");
    
    const loginTokens: string = await getStorageData("token");
    const metaSignUpTokens = JSON.parse(signupTokens);
    const metaLoginTokens = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpTokens, metaLoginTokens);

    const header: { "Content-Type": string; token: string | undefined } = {
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTodoListId = requestMessage.messageId;
    requestMessage.addData( getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.todoListApilist}${this.state.taskViewId}`
    );
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  finishTodo = async  ()=>{
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpTokenn = JSON.parse(signupTokens);
    const metaLoginTokenn = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpTokenn, metaLoginTokenn);

    const header: { "Content-Type": string; token: string | undefined } = {
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    "Content-Type": "application/json", };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage) );
    this.finishTaskId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.finishTaskApiEndPoint}${this.state.taskViewId}`
    );
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethod);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setIsHovered = (isHover:boolean) => {
    this.setState({isLibraryHover:isHover})
  }

  handleDocumentFolderMenu = async() => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Documentation");   
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
    sessionStorage.setItem("Document", 
      "ture")

  };

   


  // Customizable Area End
}
