import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { setStorageData ,getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  drawerContent?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  mobileOpen: boolean;
  mainName:string;
  userdata: {
    first_name: string;
    last_name: string;
    profile_image: {
      url:string
    };
  };
  isLibraryHover:boolean
  isLawfirmAdmin: boolean
  isClientRole: boolean
  currentPath: string
  clientToken:string
  openCollapse:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NavigationMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserProfileApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      mobileOpen: false,
      mainName:"",
      userdata: {
        first_name: "",
        last_name: "",
        profile_image:{
          url:""
        }
      },
      isLibraryHover: false,
      isLawfirmAdmin: false,
      isClientRole: false,
      currentPath:"",
      clientToken:'',
      openCollapse:true
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.getUserProfileApiCallId) {
      this.handleResponceData(responseJson)

    }
    
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const isAdmin = await this.isLyafirmAdmin();
    const isClient = await this.isClientRoleToggle();
    this.setState({ 
      isLawfirmAdmin: isAdmin,
      isClientRole:isClient ,
      currentPath: window.location.pathname});
     
   
    this.setState({
      clientToken:await getStorageData("clientTokes")
    })

    this.handleAPIcondition()

  
  }

  handleResponceData =(responseJson:any)=>{
    
    if (responseJson) {
      const userData = responseJson?.user?.data?.attributes;
      localStorage.setItem('username', `${userData?.first_name} ${userData?.last_name}`);
      this.setState({ userdata: userData });
    }
    if(responseJson?.errors?.[0]?.token === "Invalid token"){
      this.handleclientLawyer()
    }
  }
  handleclientLawyer = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLogin");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
  }

  handleAPIcondition=()=>{
    if(this.state.isLawfirmAdmin === false){
      this.getClientUserProfile()
    }else{
      this.getUserProfile()
    }
  }
  handleDrawerToggle = () => {this.setState({ mobileOpen: true }); }
  determineMainToken = (
    metaSignUpToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } },
    metaLoginToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } }
  ): { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } } | null => {
    if (metaSignUpToken?.data?.id) {
      if (metaLoginToken?.id && metaSignUpToken.data.id !== metaLoginToken.id) {
        return metaLoginToken;
      }
      return metaSignUpToken;
    }

    if (metaLoginToken?.id) {
      return metaLoginToken;
    }

    return null; 
  };

  handleclosesidebar = () => { this.setState({ mobileOpen: false }); }
  handleSettingIn = async() => {
    const ctoken: string = await getStorageData("clientTokes");
  const message = new Message(getName(MessageEnum.NavigationMessage));
    if (ctoken) { message.addData(getName(MessageEnum.NavigationTargetMessage), "ClientSettings");
    } else {  message.addData(getName(MessageEnum.NavigationTargetMessage), "Settings");
    }
     message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handleTaskIn = async () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    if (this.state.isLawfirmAdmin) {
      message.addData(getName(MessageEnum.NavigationTargetMessage), "TaskListLawfirm");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    } else if (this.state.clientToken) {
      message.addData(getName(MessageEnum.NavigationTargetMessage), "TaskList");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    } else {
      message.addData(getName(MessageEnum.NavigationTargetMessage), "Task");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    }
    this.send(message);
  };

  handleCatalogRoute =()=>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ClientLawyerCatalogue");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
  };

  handleDashboardIn = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    if (this.state.isClientRole) {
      message.addData(getName(MessageEnum.NavigationTargetMessage), "ClientDashboard");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    }
    else if (this.state.isLawfirmAdmin) { 
    message.addData(getName(MessageEnum.NavigationTargetMessage), "dashboard");
     
     message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    }else  { 
      message.addData(getName(MessageEnum.NavigationTargetMessage), "LawyerDashboard");
       
       message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      }
  
  this.send(message);
  }

  handleRedirectLiveChat = async() => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "LiveChat");   
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handleCollectionFees = async() => 
    { const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 
    "CollectTransactionFees");   
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handleDocumentFolderMenu = async() => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Documentation");   
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    sessionStorage.setItem("Document", "ture")
    this.send(message);
  };

  handleRedirectPayment = async () => 
    {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Payment");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);};
    

      handleService = () => {
        const Notimessage1: Message = new Message(getName(MessageEnum.NavigationMessage));
        Notimessage1.addData(getName(MessageEnum.NavigationTargetMessage),
          "ServicesManagement");
    
        Notimessage1.addData(getName(MessageEnum.NavigationPropsMessage),
          this.props);
        this.send(Notimessage1);
      }
      handleLawFirmManagement = () => {
        const message: Message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "lawfirmManagement");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
      }
       
      handleAdminLawyer = () => {
        const message: Message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "AdminLawyerManagement");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
      }
    
     
          handleProjectManagement =  () =>  {
            const message = new Message(getName(MessageEnum.NavigationMessage));
            message.addData(getName(MessageEnum.NavigationTargetMessage), "ProjectTracking");
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message)};

  getClientUserProfile = async () => {
    const clientToken = localStorage.getItem('clientTokes')
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken(metaSignUpToken, metaLoginToken);
    const header = {
      "Content-Type": "application/json",
      "token":  clientToken   || maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserProfileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userDetalsEndPoint}${ maintoken?.id || maintoken?.data?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getUserProfile = async () => {
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    let data: any = localStorage.getItem("token");
    let tokenObject = JSON.parse(data);

    let token = tokenObject?.serialized_data?.meta?.token;

    const maintoken = this.determineMainToken(metaSignUpToken, metaLoginToken);
    const header = {
      "Content-Type": "application/json",
      "token":  token    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserProfileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userDetalsEndPoint}${ maintoken?.id || maintoken?.data?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };



  isLyafirmAdmin = async () => {
    const ctoken: string = await getStorageData("clientTokes");
    const loginTokens: string = await getStorageData("token");
    const metaLoginToken = JSON.parse(loginTokens);
    if (!ctoken && metaLoginToken?.user_role === "lawfirm_admin") {
      return true
    }
    return false
  }


  setIsHovered = (isHover:boolean) => {
    this.setState({
      isLibraryHover:isHover
    })
  }
  isClientRoleToggle = async() => {
    const ctoken: string = await getStorageData("clientTokes");
    if(ctoken){
      return true
    }
    return false
  }

  // Customizable Area End
}
