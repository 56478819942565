import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import { serviceRequest } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
interface Details {
  Title: string;
  Data: string;
  Imag: string;
  date:string
}
interface Task {
  dotColor:string,
  spantext:string,
  title:string,
  data:string,
  backgroundData:string,
  colordata:string,
  checkedFirst:boolean,
  checkedSecond:boolean
}

export interface IGroupAccount {
  id: number;
  type: "account"; first_name: string;
  last_name: string;
}
export interface IGroup {
  id: string; type: "group";
  attributes: {
    name: string;
    accounts: IGroupAccount[];
  };
}

export interface IAccount {
  isSelected: boolean;
  id: string;
  type: "account";
  attributes: { first_name: string;
    last_name: string;};
}


export type Status = {
  id: number; name: "to_do" | "in_progress" | "complete";
};

export type Priority = {
  id: number; name: "low" | "medium" | "high";
};
export interface ITask {
  isSelected: boolean; id: string;
  attributes: {
    id: number;
    account_id: number;
    title: string;
    description: string;
    status: Status["name"];
    priority: Priority["name"];
    created_at: Date;
    updated_at: Date;
    assigned_to: IAccount | IGroup;
  };
}

export interface TaskProfile { isSelected: boolean;
  id: string;
  attributes: {  task_name: string;
    legel_service_type: any; trademark_name: string;
    trademark_application_number: string; state: string;
    status: string;
    id: string;   account_id: number;
    name: string;
    created_at: Date;
    updated_at: Date;
    tasks: ITask[];
    deadline:string;
    message_count:number
  };
}
interface LegalServiceType {
  id: number,
  name: string
}
interface Account {
  id: string;
  type: string;
  attributes: {
      activated: boolean;
      country_code: string;
      email: string;
      full_phone_number: string;
      first_name: string;
      last_name: string;
      phone_number: string;
      type: string | null;
      created_at: string;
      updated_at: string;
      device_id: string | null;
      unique_auth_id: string;
      firm: string | null;
      user_role: string;
  };
}

interface LegalServiceType {
  id: number;
  name: string;
}

interface RootFolder {
  id: number;
  gallery_id: number | null;
  folder_name: string;
  folder_type: string | null;
  created_at: string;
  updated_at: string;
  is_archived: boolean;
  parent_id: number | null;
  account_id: number;
  task_id: number;
  archived_at: string | null;
  restore_id: string | null;
}

interface ProjectTemplate {
  id: string;
  type: string;
  attributes: {
      task_name: string;
      description: string;
      trademark_name: string;
      trademark_application_number: string;
      deadline: string;
      jurisdiction: string;
      state: string;
      status: string;
      account_id: number;
      parent_task_id: number | null;
      created_at: string;
      request: string | null;
      account: {
          data: Account;
      };
      legel_service_type: LegalServiceType;
      root_folder: RootFolder;
      chat_rooms: any[];
      lawfirm_as_lawyer: boolean;
      message_count: number;
  };
}

interface ResponseData {
  data: ProjectTemplate[];
}

interface FormData {
  legal_service_type_id: number | string; 
  prices: number; 
}
interface OptionType {
  value: string;
  label: string;
  id: number;
}
interface ServiceData {
  id: string;
  legal_service_name: string;
}
export interface ServicesDataType{
  id: string,
  legal_service_name: string,
  legel_service_description: string|null,
  created_at: string,
  updated_at: string
}
interface ServiceDataReason {
  id: string;
  reason_content: string;
}
export interface ServicesReason{
  id: string,
  reason_content: string,
  created_at: string,
  updated_at: string
}
interface OptionTypeReason {
  value:string,
  label:string,
  id:number,
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: { "id": string, "type": string, "attributes": { "total_candidates": number, "sub_attributres": { "type": string, "quantity": string }[] } };
  token: string;
  errorMsg: string;
  loading: boolean;
  
  details: Details;
  DetailsUser: Details[]
  task:Task;
  TaskAll:Task[]
  taskProfile:TaskProfile[]
  drawerOpen:boolean
  taskViewId:string
  isTaskPosted:boolean
   taskView:{
        id: number;
        attributes: {
          task_name: string;
          legel_service_type: LegalServiceType;
          trademark_name: string;
          trademark_application_number: string;
          state: string;
          status: string;
          jurisdiction:string,
          deadline:string,
          description:string
          created_at:string,
          request: {
    
            data: {
              id: string,
              type: string,
              attributes: {
                price: string,
              }
            }
          }
        };
      }
      isInprogress:boolean
      lastFiles:{
        id: number,
        name: string,
        byte_size: number,
        record_type: string,
        record_id: number,
        created_at: string,
        filename: string,
        content_type: string,
        url: string
      }[]
      recentMessages: {
        task_name: string;
        user_name: string;
        profile: string;
        last_message: string;
      }[];
      upcomingTask:ResponseData | null;
      acceptTask:boolean
      rejectTask:boolean
      formData:{
        legal_service_type_id: number | string; 
      prices: number; 
       };
       optionsShow: OptionType[],
       servicesData: ServiceData[];
       responseShow:{
        success:string,
        errors:string},
        selectedTaskID:string,
        serviceReason:ServiceDataReason[],
        optionsReason: OptionTypeReason[],
        selectedOption: string,  
        formData2:{
          rejection_description: string,
          task_reject_reason_id: number|null,
         };
         rejectOption:string
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  getTaskListsApiCallId: string= "";
  getTasksApiCallId:string = "";
  getLasetFilesAPIcallID:string ="";
  getRecentmessagesAPIcallID:string ="";
  getUpcomingTaskCallID:string="";
  getServiceApiCallId:string="";
  postAcceptCallId:string="";
  postRejectCallId:string="";
  getReasonApiCallId:string=""
  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      dashboardData: {"id":"0","type":"candidate","attributes":{"total_candidates":0,"sub_attributres":[{"type":"Interview with client","quantity":"0"}]}},      
      errorMsg: "",
      token: "",
      loading: true,
      details: {
        Title: "",
        Data: "",
        Imag: "",
        date:''
      },
      DetailsUser: [],
      task:{
        dotColor:"",
        spantext:"",
        title:"",
        data:"",
        backgroundData:"",
        colordata:"",
        checkedFirst:false,
        checkedSecond:false,
      },
      TaskAll:[],
      taskProfile:[],
      drawerOpen:false,
      taskViewId:'',
      isTaskPosted:false,
      taskView:{
        id:1,
        attributes: {
          task_name: "",
          legel_service_type: {
            id:1,
            name:""
          },
          trademark_name: "",
          trademark_application_number: "",
          state: "",
          status: "",
          jurisdiction:"" ,
          deadline:"",
          description:"",
          created_at:"",
          request: {
  
            data: {
              id: "",
              type: "",
              attributes: {
                price: "",
              }
            }
          }
            }
        },
        isInprogress:false,
        lastFiles:[],
        recentMessages: [],
        upcomingTask:null,
        acceptTask:false,
        rejectTask:false,
        formData:{
          legal_service_type_id: 3,
          prices: 1500,
      },
      optionsShow: [
        {
          value: "",
          label: "string",
          id: 1,
        },
      ],
      servicesData: [],
      responseShow:{
        success:"",
        errors:""},
      selectedTaskID:"",
      serviceReason:[],
      optionsReason: [
        {
          value: "",
          label: "string",
          id: 1,
        }
      ],
      selectedOption: "",  
      formData2:{
        rejection_description: "",
        task_reject_reason_id:0,
       },
       rejectOption:""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getDashboardData();
    this.getTaskLists()
    this.getLastFiles()
    this.getRecentMessage()
    this.getUpcomingTask()
    this.getServicesList()
    this.getReasonList()
    this.setState({
      DetailsUser: [
        { Title: "Digip has approved your task", 
          Data: "Your task is now posted, please finish the last de...",
          Imag: `${serviceRequest}`,date:'16/11/2024' },
        {  Title: "Digip has approved your task",  Data: "Your task is now posted, please finish the last de...",
          Imag: `${serviceRequest}`,
          date:'16/11/2024' },
        { Title: "Digip has approved your task", Data: "Your task is now posted, please finish the last de...",
          Imag: `${serviceRequest}`,
          date:'16/11/2024'},
        {Title: "Digip has approved your task", 
         Data: "Your task is now posted, please finish the last de...",
          Imag: `${serviceRequest}`, 
          date:'16/11/2024' },
        { Title: "Digip has approved your task", 
          Data: "Your task is now posted, please finish the last de...",
          Imag: `${serviceRequest}`,date:'16/11/2024'},
        {Title: "Digip has approved your task", Data: "Your task is now posted, please finish the last de...",
          Imag: `${serviceRequest}`,  
          date:'16/11/2024'},
        {Title: "Digip has approved your task",
          Data: "Your task is now posted, please finish the last de...",
          Imag: `${serviceRequest}`,date:'16/11/2024'},
        { Title: "Digip has approved your task",Data: "Your task is now posted, please finish the last de...",
          Imag: `${serviceRequest}`,
          date:'16/11/2024' },],
      TaskAll: [
        {
          dotColor: "#355EE7",
          spantext: "12",
          title: "In Progress",
          data: "High",
          backgroundData: "#FEE2E2",
          colordata: "#DC2626",
          checkedFirst: true,
          checkedSecond: false
        },
        {
          dotColor: "#8C8C8C",
          spantext: "5",
          title: "Overdue",
          data: "Normal",
          backgroundData: "#FEF3C7",
          colordata: "#D97706",
          checkedFirst: true,
          checkedSecond: false
        }, {
          dotColor: "#059669",
          spantext: "20",
          title: "Completed",
          data: "Low",
          backgroundData: "#F5F5F5",
          colordata: "#8C8C8C",
          checkedFirst: true,
          checkedSecond: true
        }, {
          dotColor: "#F87171",
          spantext: "0",
          title: "Declined",
          data: "Low",
          backgroundData: "#F5F5F5",
          colordata: "#8C8C8C",
          checkedFirst: false,
          checkedSecond: false
        },

      ]
    })
    // Customizable Area End
  }
  
  getToken=()=>{
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.dashboarContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.apiDashboardItemCallId) {
          this.setState({ loading: false, dashboardData: responseJson.data, errorMsg: ''})
        }
      }
      else if (responseJson && responseJson.errors) {
        if (apiRequestCallId === this.apiDashboardItemCallId) {
          this.setState({ loading: false, dashboardData: {"id":"0","type":"candidate","attributes":{"total_candidates":0,"sub_attributres":[{"type":"Interview with client","quantity":"0"}]}}, errorMsg : errorReponse})
        }
      }
       this.handleAPIresponse(apiRequestCallId,responseJson)
       this.handleTaskDataresponse(apiRequestCallId,responseJson)
       this.handleLastFileResponse(apiRequestCallId,responseJson)
       this.handleRecentmessage(apiRequestCallId,responseJson)
       this.handleUpcomingTask(apiRequestCallId,responseJson)
      
       
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleAPIresponse =(apiRequestCallId:any,responseJson:any)=>{
    if (apiRequestCallId === this.getTaskListsApiCallId) {
      if (responseJson?.data?.length > 0) {
        this.setState({ taskProfile: responseJson?.data, loading: false });
      } else {
        this.setState({ taskProfile: [] });
      }
    }

    if (apiRequestCallId === this.getServiceApiCallId) {
      this.setState({ servicesData: responseJson });

      const servicesOptions = responseJson.map((e: ServicesDataType) => ({
        id: e.id,
        label: e.legal_service_name,
        value: e.legal_service_name,
      }));

      this.setState({ optionsShow: servicesOptions });
    }
    if(apiRequestCallId === this.postAcceptCallId ||apiRequestCallId === this.postRejectCallId){
      this.handleProjectCreationResponse(responseJson)
      }
      if (apiRequestCallId === this.getReasonApiCallId) {
            this.setState({ serviceReason: responseJson });
            const reasonsOptions = responseJson?.map((e: ServicesReason) => ({
                id: e.id,
                label: e.reason_content, 
                value: e.reason_content,  
            }));
            
            this.setState({ optionsReason: reasonsOptions });
        }
  }

  handleLastFileResponse =(apiRequestCallId:any,responseJson:any)=>{
    if (apiRequestCallId === this.getLasetFilesAPIcallID) {
      if (responseJson) {
        this.setState({ lastFiles: responseJson, loading: false });
      }
    }
  }

  getTaskRejectReasonId = (value: string, optionsReason: any[]) => {
    if (value === "no_legal_case") {
      return null;
     }
   const selectedOption = optionsReason.find((option: { value: any; }) => option.value === value);
   return selectedOption ? selectedOption.id : null;
 };

  handleChange2 = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    const taskRejectReasonId = this.getTaskRejectReasonId(value, this.state.optionsReason);
    this.setState(prevState => ({
        ...prevState,
        selectedOption: value,
        rejectOption:value,
        formData2: {
            ...prevState.formData2,
            task_reject_reason_id: taskRejectReasonId,
        },
        hasError: false,
    }));
  };
  handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const description = e.target.value;
  
    this.setState({
      formData2: {
        ...this.state.formData2,
        rejection_description: description,
      },
     
    });
  };
  handleRecentmessage =(apiRequestCallId:any,responseJson:any)=>{
    if (apiRequestCallId === this.getRecentmessagesAPIcallID) {
      if (responseJson ) {
        this.setState({ recentMessages: responseJson, loading: false });
      }
    }
  }
  handleUpcomingTask =(apiRequestCallId:any,responseJson:any)=>{
    if (apiRequestCallId === this.getUpcomingTaskCallID) {
      if (responseJson ) {
        this.setState({ upcomingTask: responseJson, loading: false });
      }
    }
  }

  handleProjectCreationResponse = (responseJson: { meta: { message: any; }; errors?: { message: string }[]; }) => {
    if (responseJson?.errors ){
      this.setState({
        responseShow: { errors: responseJson?.errors[0]?.message, success: "" } 
      });
    } else if (responseJson?.meta?.message) {
      this.setState({
        responseShow: { errors: "", success: responseJson?.meta?.message },
      });
    }
    this.setState({
        acceptTask:false
    })
  };
  

  accepTaskDrawerOpen =(id:any)=>{
    this.setState({
      acceptTask:true,
      rejectTask:false,
      selectedTaskID:id
    })
  }

  handleRedirectLiveChat = async() => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "LiveChat");   
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handleCloseDialog=()=>{
    this.setState({
      acceptTask:false,
      rejectTask:false,
      formData:{
        legal_service_type_id: 3,
        prices: 1500,
    }
    })
  }
  handleAccept=()=>{
    this.acceptedRequest();
  }

  rejectTaskDrawerOpen=(id:any)=>{
    this.setState({
      rejectTask:true,
      acceptTask:false,
      selectedTaskID:id
    })
  }
  handleTaskDataresponse = (apiRequestCallId:any,responseJson:any) =>{
    if (apiRequestCallId === this.getTasksApiCallId) {
      if (responseJson?.data) {
        const status = responseJson?.data?.attributes?.status;
        const state = responseJson?.data?.attributes?.state;
        this.setState({ 
          taskView: responseJson?.data,
          isTaskPosted: status === "pending_client_approval",
          isInprogress:state ==="in_progress"
        });
      } else {
        this.setState({ isTaskPosted: false });
      }
    }
  }

  getPercentageFun = (percentage: number) => {
    return (percentage * 100).toFixed(0) + '%'
  }
  getPercentageColor= (type: string) => {
   switch(type) {
    case 'Interview with client':
      return '#D52CD0'
    case 'Submitted for feedback':
      return '#F0E517'
    case 'Candidates expecting offer':
      return '#F44D8E'
    case 'Candidates accepted':
      return '#35C95E'
    }
  }

  handleLawfirm = () => {
    const Notimessage: Message  = new Message(getName(MessageEnum.NavigationMessage));
    Notimessage.addData(getName(MessageEnum.NavigationTargetMessage),
      "lawfirmManagement");

    Notimessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(Notimessage);
  }
  handleLawyer = () => {
    const Notimessage2: Message
      = new Message(getName(MessageEnum.NavigationMessage));
    Notimessage2.addData(getName(MessageEnum.NavigationTargetMessage),"lawyerManagement");

    Notimessage2.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);  this.send(Notimessage2);
  }
  handleService = () => {
    const Notimessage1: Message = new Message(getName(MessageEnum.NavigationMessage));
    Notimessage1.addData(getName(MessageEnum.NavigationTargetMessage),
      "serviceManagement");

    Notimessage1.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);
    this.send(Notimessage1);
  }

  determineMainToken1 = (
    metaSignUpToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } },
    metaLoginToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } }
  ): { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } } | null => {
    if (metaSignUpToken?.data?.id) {
      if (metaLoginToken?.id && metaSignUpToken.data.id !== metaLoginToken.id) {
        return metaLoginToken;
      }
      return metaSignUpToken;
    }

    if (metaLoginToken?.id) {
      return metaLoginToken;
    }

    return null; 
  };

  getServicesList= async () => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getServiceApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     "bx_block_productdescription/service_managements/legal_services_list"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getReasonList= async () => {
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);
    const header = {
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getReasonApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     "bx_block_request_management/requests/reject_request_reason"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  incrementPrice = () => {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        prices: Math.min(prevState.formData.prices + 50, 2500), 
      },
    }));
  };

  decrementPrice = () => {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        prices: Math.max(prevState.formData.prices - 50, 1500), 
      },
    }));
  };

  
  handlePriceChange = (event: React.ChangeEvent<{}>, value: number | number[]) => {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        prices: value as number, 
      },
    }));
  };
  getValue = (trueSta:any, falseSta: any) => {
    if(trueSta){
      return trueSta;
    }else{
      return falseSta
    }

  }
  acceptedRequest= async()=>{
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);
    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);
    const requestBody = {
      "request":{
        "legel_service_type_id": this.state.formData?.legal_service_type_id,
        "price": this.state.formData?.prices,
        "assigned_lawyer_ids": this.getValue(maintoken?.id , maintoken?.id),
        "project_template_id":this.state.selectedTaskID
      }
     
    };
    

   
    const header = {
      "token": this.getValue(maintoken?.serialized_data?.meta?.token , maintoken?.meta?.token),
      "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postAcceptCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_request_management/requests/accept_request`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  rejectRequest= async()=>{
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);
    const header = {
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
      "Content-Type": "application/json",
    };
    const { task_reject_reason_id ,rejection_description} = this.state.formData2;

const requestBody = {
  request: {
    task_reject_reason_id:task_reject_reason_id,
    project_template_id: this.state.selectedTaskID,
    ...(this.state.rejectOption !== "NO LEGAL CASE" ? { rejection_description } : {})
  }
};



    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postRejectCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_request_management/requests/reject_request`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getTasks = async () => {
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);
    const header = {
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTasksApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_projecttemplates/project_templates/${this.state.taskViewId}`
    );
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getLastFiles = async () => {
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);
    const header = {
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getLasetFilesAPIcallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getLawyerLastFile}`
    );
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getRecentMessage = async () => {
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);
    const header = {
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRecentmessagesAPIcallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getLawyerRecentMessage}`
    );
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getUpcomingTask = async () => {
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);
    const header = {
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUpcomingTaskCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUpcomingTask}`
    );
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  toggleDrawer = (task: { id: string }) => {
    const taskId = String(task.id);
    sessionStorage.setItem("TaskID" , taskId)
    this.setState(prevState => ({  drawerOpen: !prevState.drawerOpen, taskViewId: taskId}));
    this.getTasks()
  };

  toggleDrawerClose = () => {
    this.setState({ drawerOpen: false }); 
  };

  getTaskLists = async() => {
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);
    const header = {
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTaskListsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),`bx_block_tasks/task_lists/lawyer_task_lists`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleDocumentFolderNavigation = (taskId:string|number) =>{
    sessionStorage.setItem("taskID", `${taskId}`)
    const navMsg = new Message(getName(MessageEnum.NavigationMessage));
    navMsg.addData(getName(MessageEnum.NavigationTargetMessage),
      "Documentation");
      navMsg.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);
    this.send(navMsg);
  }
  handleNavigationTask = () => {    
    const Notimessage1: Message = new Message(getName(MessageEnum.NavigationMessage));
    Notimessage1.addData(getName(MessageEnum.NavigationTargetMessage),
      "TaskListLawyer");

    Notimessage1.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);
    this.send(Notimessage1);
  }

  // Customizable Area End

}
