import React from "react";

// Customizable Area Start
import { Grid, Typography, Box, styled, IconButton, TextField, InputAdornment } from '@material-ui/core';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { logo, sideImage } from "./assets";
import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";
import { ToastContainer } from "react-toastify";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from "@material-ui/lab/Alert";
// Customizable Area End



export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start


  renderLoginForm = () => {
    const validationSchema = Yup.object().shape({
      email: Yup.string().email('Invalid email address').required('Fill all mandatory fields to continue'),
      password: Yup.string()
        .required('Fill all mandatory fields to continue')
        
    });

    return (
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={validationSchema}
        onSubmit={(values, { setStatus, setSubmitting }) => {
          setStatus(null); // Reset status

          // Check if there are any errors based on validation schema
          validationSchema.validate(values, { abortEarly: false })
            .then(() => {
              localStorage.clear();
              setSubmitting(true);
              this.loginPostApi(values)
            })
            .catch(errors => {
              // If validation fails, set a single status message for empty fields
              if (errors.inner.some((error: any) => error.message === 'Fill all mandatory fields to continue')) {
                setStatus('Fill all mandatory fields to continue');
              }
              setSubmitting(false);
            });
        }}
      >
        {({ values, errors, touched, handleSubmit, handleChange }) => {
          const hasEmptyFieldErrors = (errors.email && touched.email && errors.email === 'Fill all mandatory fields to continue') || (errors.password && touched.password && errors.password === 'Fill all mandatory fields to continue')
          const combinedErrors = hasEmptyFieldErrors ? 'Fill all mandatory fields to continue' : '';


          return (
            <form className="thirdForm" onSubmit={handleSubmit}>
              {combinedErrors && <Box className="statusErrorLogin" >{combinedErrors}</Box>}
              {this.state.loginError && (
                <Snackbar data-test-id="loginError" open={this.state.open} autoHideDuration={3000} onClose={this.handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  className="snack">

                  <Alert icon={false} className="statusErrorLoginAlert" >
                    {this.state.loginError}
                  </Alert>
                </Snackbar>
              )}
              {this.state.loginSuccess && (
                <Snackbar data-test-id="loginSuccess" open={this.state.open} autoHideDuration={3000} onClose={this.handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  className="snack">
                  <Alert severity="success" icon={false} className="statusSuccessLoginAlert" >
                    {this.state.loginSuccess}
                  </Alert>
                </Snackbar>
              )}
              <Box className="firstNameField">

                <Typography variant="subtitle1" className="firstNamePlaceHolder">
                  Email
                </Typography>
                <TextField
                  inputProps={{ "data-test-id": "email" }}
                  InputProps={{
                    style: {
                      borderRadius: '8px'
                    }
                  }}
                  name="email"
                  type="email"
                  fullWidth
                  variant="outlined"
                  placeholder="Your email"
                  value={values.email}
                  onChange={handleChange}
                  className={errors.email ? "errorOutline" : ''}
                />
                {errors.email && errors.email !== 'Fill all mandatory fields to continue' && (
                  <Box className="statusErrorCustom" style={{ color: 'red' }}>
                    {errors.email}
                  </Box>
                )}
                <Box className="passwordBoxField">
                  <Typography variant="subtitle1" className="firstNamePlaceHolder">
                    Password
                  </Typography>
                  <Field
                    as={TextField}
                    name="password"
                    type={this.state.showPassword ? "text" : "password"}
                    fullWidth
                    variant="outlined"
                    placeholder="Your password"
                    className={errors.password ? "errorOutline" : ''}
                    onFocus={() => this.setState({ showPasswordCriteria: true })}
                    onBlur={() => !values.password && this.setState({ showPasswordCriteria: false })}
                    inputProps={{ "data-test-id": "password" }}
                    InputProps={{
                      style: {borderRadius: '8px'},
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            data-test-id="toggleNewPasswordVisibility"
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPasswords}
                            onMouseDown={this.handleMouseDownPassword}
                            edge="end"
                          >
                            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  {errors.password && errors.password !== 'Fill all mandatory fields to continue' && errors.password.length > 0 && (
                    <Typography className="statusErrorCustom" style={{ color: 'red' }}>
                      {errors.password}
                    </Typography>
                  )}
                  <Typography data-test-id="forgot" onClick={() => this.handleForgotPassword()} className="forgot">Forgot password?</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" mt={6}>
                  <button
                    type="submit"
                    className="nextBtn"
                    color="primary"
                    data-test-id="next2"
                  >
                    Log in
                  </button>
                </Box>
              </Box>
            </form>
          );
        }}
      </Formik>
    );
  };
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <>
        <LandingPageAboutHeading data-test-id="EmailAccountLogin">
          <ToastContainer />
          <Grid container data-test-id="LandingPage">
            <Grid item xs={12} md={6} className="landingPageFirstGrid">
              <Box className="logoLanding">
                <img src={logo.default} alt="Logo1" 
                className="landingPageLogo" />
              </Box>
              <Box className="landingPageAbout" >
                <Typography className="landingPageAboutHeadingText">Log in</Typography>
                <Box
                  className="formBox"
                >
                  {this.renderLoginForm()}
                </Box>
                <Box className="accountCreate">
                  <Typography className="accountQuestion">Don’t have an account?  </Typography>
                  <Typography data-test-id="signup" onClick={() => this.handleNavigationSignIn()} className="sign">Sign up</Typography>
                </Box>

              </Box>
            </Grid>
            <Grid item xs={12} md={6} >
              <img src={sideImage} alt="Example" className="landingPageImage" />
            </Grid>
          </Grid>
        </LandingPageAboutHeading>
      </>
      // Customizable Area End
    );
  }

}
// Customizable Area Start
const LandingPageAboutHeading = styled(Box)({
  "& .landingPageAbout": {
    width: "440px"
  },
  "& .landingPageAboutHeadingText": {
    fontSize: "33px",
    fontWeight: "700",
    marginBottom: "45px",
    width: "336px",
    fontFamily: "DM Sans",
    textAlign: "left"
  },
  "& .landingPageText": {
    width: "391px",
    fontSize: "22px",
    fontWeight: "400",
    fontFamily: "DM Sans",
    lineHeight: "28.6px",
    marginBottom: "28px"
  },
  "& .landingSignupBtn": {
    backgroundColor: "#355EE7",
    color: "white",
    width: "391px",
    textTransform: "none",
    borderRadius: "8px",
    padding: "10px",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "DM Sans",
    cursor: "pointer"
  },
  "& .landingLoginBtn": {
    width: "391px",
    textTransform: "none",
    marginBottom: "26px",
    borderRadius: "8px",
    padding: "10px",
    color: "#355EE7",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "DM Sans",
    cursor: "pointer",
    '&:hover': {
      border:'3px solid #87CEEB',
      boxShadow:'none !important'
    }
  },
  "& .landingPageImage": {
    width: "100%",
    height: "100vh"
  },
  "& .landingPageLogo": {
    position: "absolute", top: "20px", left: "20px", width: "100px", height: "auto", marginBottom: "30px"
  },
  "& .landingPageFirstGrid": {
    display: "flex", justifyContent: "center", alignItems: "center"
  },
  "& .firstNamePlaceHolder": {
    textAlign: "left"

  },
  
  "& .nextBtn": {
    backgroundColor:"#355EE7",
    color:"white",
    width:"100%",
    height:'50px',
    textTransform:"none" as "none",
    borderRadius:"8px",
    padding:"13px",
    fontSize:"16px",
    fontWeight:"400",
    fontFamily:"DM Sans",
    cursor:"pointer",
    border:'none',
    '&:hover': {
      border:'2px solid #B9C5FF',
      boxShadow:'none !important'
    },
    "&:disabled": {
      backgroundColor: "#D9D9D9",
      color: "white"
    }
  },
  "& .statusErrorCustom": {
    marginTop: "6px"
  },
  "& .forgot": {
    textAlign: "right",
    color: "#355EE7",
    fontSize: "14px",
    fontWeight: 700,
    fontFamily: "DM Sans",
    cursor: "pointer",
    marginTop: "10px"

  },
  "& .snack": {
    position: "relative",
    marginTop: "55px !important",
    marginBottom: "15px",
    top: "10px",
    left: '190px',
    backgroundColor: "transparent !important",
    boxShadow: "none"

  },
  "& .statusError": {
    color: "red"

  },
  "& .statusErrorLogin": {
    backgroundColor: "#FEE2E2",
    padding: "20px",
    marginBottom: "20px",
    borderLeft: "4px solid #F87171",
    color: "#F87171",
    borderRadius: '4px',
    fontFamily: "DM Sans",
    fontSize: "14px",
    fontWeight: 400

  },
  "& .statusErrorLoginAlert": {
    backgroundColor: "#FEE2E2",
    borderLeft: "4px solid #F87171",
    color: "#DC2626",
    borderRadius: '4px',
    fontFamily: "DM Sans",
    fontSize: "14px",
    fontWeight: 400,
    width: "404px",
    position: "absolute",
    left: "30px",
    bottom: "8px"


  },
  "& .statusSuccessLoginAlert": {
    backgroundColor: "#D1FAE5",
    borderLeft: "4px solid #34D399",
    color: "#059669",
    borderRadius: '4px',
    fontFamily: "DM Sans",
    fontSize: "14px",
    fontWeight: 400,
    width: "404px",
    position: "absolute",
    left: "30px",
    bottom: "8px"

  },
  "& .passwordErrorBox": {
    display: "flex !important",
    alignItems: "left !important",
    // justifyContent:"center !important"

  },
  "& .passErrorText":{
    fontFamily:"DM Sans",
  },
  "& .accountCreate": {
    display: "flex",
    marginTop: "40px",
    alignItems:"center"
  },
  "& .accountQuestion": {
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "DM Sans"
  },
  "& .sign": {
    marginLeft: "10px",
    color: "#355EE7",
    cursor: "pointer",

  },
  "& .passwordBoxField": {
    marginTop: "20px"

  },

  "@media (max-width: 900px)": {
    "& .landingPageFirstGrid": {
      flexDirection: "column",
      alignItems: "flex-start",
      textAlign: "center"
    },
    "& .landingPageAboutHeadingText": {
      width: "100%"
    },
    "& .landingPageLogo": {
      position: "relative",
      top: "0",
      left: "0",
      margin: "20px"
    },
    "& .landingPageAbout": {
      width: "90%",
      padding: "0 20px"
    },
    "& .landingPageText": {
      width: "100%",
      fontSize: "18px"
    },
    "& .landingSignupBtn, & .landingLoginBtn": {
      width: "100%"
    },
    "& .landingPageImage": {
      width: "100%",
      height: "auto"
    }
  }
});

// Customizable Area Start

