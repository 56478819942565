import React from "react";

// Customizable Area Start
import {
  Box,
  TextField,
  InputAdornment,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  CircularProgress,
  Checkbox,
  Chip,
  Avatar,
  Tooltip
} from "@material-ui/core";
import { MuiThemeProvider, createTheme, styled } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Select, { components,DropdownIndicatorProps } from "react-select";
import CloseIcon from '@material-ui/icons/Close';
import { logo, userpic, right, filter,Services, grp, tooltipIcon, tableIcon} from "./assets";
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import SearchIcon from '@material-ui/icons/Search';
import JobListing from "../../../blocks/joblisting/src/JobListing.web";
import VpnLockIcon from '@material-ui/icons/VpnLock';
import StarRatings from 'react-star-ratings';
import TaskAllocatorController, {Props } from "./TaskAllocatorController";
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
// Customizable Area End



// Customizable Area Start
const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "2em",
        backgroundColor: "#fff",
        zIndex:999,
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        width:"450px",
        borderRadius:"12px",
        padding:"10px"
      }
    }
  }
});
// Customizable Area End


export default class TaskAllocator extends TaskAllocatorController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  drawer = () => (
    <DrawerBox style={{ display: this.state.mobileOpen ? 'block' : '' }}>
      <div className="toolbar" />
      <Divider />
      <div className="logobox">
        <img src={logo.default} alt="logoj1" className="logoimag" data-test-id="logoImg" />
      </div>
      {this.state.mobileOpen && <CloseIcon style={{ position: 'fixed', top: '30px', left: '210px', cursor: 'pointer' }} onClick={this.handleclosesidebar} />}
      <div className="settingbox">
      </div>
      <List className="MainListBox">
        <ListItem onClick={this.handleNavigateDashboard} className="listbox">
          <ListItemIcon><HomeOutlinedIcon className="icondrawer2" /></ListItemIcon>
          <ListItemText className="listtile" data-test-id="Dashboard">
            Dashboard
          </ListItemText>
        </ListItem>
        <ListItem className="listbox1" onClick={this.handleNavigateAllocator}>
          <ListItemIcon><DescriptionOutlinedIcon className="icondrawer1" /></ListItemIcon>
          <ListItemText className="listtile1" data-test-id="Setting">
            Tasks list
          </ListItemText>
        </ListItem>
        <ListItem className="listbox" onClick={this.handleSettingIn}>
          <ListItemIcon><SettingsOutlinedIcon className="icondrawer2" /></ListItemIcon>
          <ListItemText className="listtile" data-test-id="Setting">
            Settings
          </ListItemText>
        </ListItem>
      </List>
      <Box className="userProfile">
        <Box><img src={userpic} className="imageBox" /></Box>
        <Box>
          <Typography className="userName">sass</Typography>
          <Typography className="userContent">Side subnav item</Typography>
        </Box>
      </Box>
    </DrawerBox>
  )
  buttonMenu = () => (
    <ButtonBox className="buttonbox">
      <IconButton
        className="iconbtn"
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={this.handleDrawerToggle}
        style={{ marginRight: '24px', color: '#000' }}
      >
        <MenuIcon />

      </IconButton>
    </ButtonBox>
  )
  sidebarr = () => {
    return (this.state.mobileOpen &&
      <>
        <div className="offcanvascss" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
          {this.drawer()}
        </div>
      </>

    )
  }

  sideBarr2 = () => {
    return (
      this.state.mobileOpen && <div className="offcanvasbackdrop" onClick={this.handleclosesidebar}></div>
    )
  }
  getColorForCheckbox = (lawyerId:number) => {
    const { selectedLawyersID, updatedSelectedID } = this.state;
  
    if (selectedLawyersID.includes(lawyerId)) {
      return 'green';
    } else if (updatedSelectedID.includes(lawyerId)) {
      return '#355EE7';
    } else {
      return 'initial';
    }
  }

  renderTableRows = () => {
    const { loading, associatedLawyer, selectedLawyersID, updatedSelectedID ,searchResults} = this.state;
    console.log("searchResults", associatedLawyer, searchResults)
    if (loading) {
      return (
        <TableRow>
          <TableCell colSpan={7} align="center">
            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
              <CircularProgress />
            </Box>
          </TableCell>
        </TableRow>
      );
    }

    if ( associatedLawyer.length === 0  ) {
      return (
        <TableRow>
          <TableCell colSpan={7} align="center">
            <Box style={{
              display: "flex",
              justifyContent: "center",
              height: "50vh",
              alignItems: "center"
            }}>
              <Typography style={{
                textAlign: 'center',
                padding: '20px',
                fontWeight: 'bold',
                display: "flex",
                flexDirection: "column",
              }}>
                No results found
               
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
      );
    }
    
    const rowsToRender = searchResults.length > 0 ? searchResults : associatedLawyer;
    return rowsToRender?.map((task, index) => (
      <TableRow  
        component={Paper}
        elevation={5}
        style={{
          borderSpacing: '0px !important',
          borderCollapse: 'unset',
        }}
        key={task?.attributes?.associated_lawyer_details.data.id}
      >
        <TableCell
          style={{
            fontSize: "12px",
            fontWeight: 700,
            fontFamily: "Dm sans",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textTransform: "capitalize",
            display: "flex",
            alignItems: "center",
          }}
          title={task.attributes.associated_lawyer_details.data.attributes.first_name}
        >
          <Checkbox
            data-test-id="onChangeClick"
            style={{
              marginBottom: "4%",
              color: this.getColorForCheckbox(task?.attributes?.associated_lawyer_details.data.id), 
            }}
            checked={selectedLawyersID.includes(task?.attributes?.associated_lawyer_details.data.id) ||
              updatedSelectedID.includes(task?.attributes?.associated_lawyer_details.data.id)}
            onChange={() => this.handleLawyerSelect(task?.attributes?.associated_lawyer_details.data.id)}
          />
         <div style={{ position: "relative", display: "flex", alignItems: "center" }}>
    { (selectedLawyersID.includes(task?.attributes?.associated_lawyer_details.data.id) ||
      updatedSelectedID.includes(task?.attributes?.associated_lawyer_details.data.id)) && (
      <img
        src={grp} 
        alt="Badge"
        style={{
          position: "absolute",
          top: "-5",
          right: "0",
          width: "20px",  
          height: "20px", 
          borderRadius: "50%",
          zIndex: 1, 
        }}
      />
    )}
    <Avatar
      src={task.attributes.associated_lawyer_details.data.attributes?.profile_image?.url || ""}
      style={{
        width: "44px",
        backgroundColor: "none",
        height: "44px",
        borderRadius: "50px",
        marginRight: "4px",
        gap: "3px"
      }}
      alt=""
    />
  </div>
          {task.attributes.associated_lawyer_details.data.attributes.first_name}
          {task.attributes.associated_lawyer_details.data.attributes.last_name}
        </TableCell>
        <TableCell>
          <Typography style={{
            display: "flex",
            gap: "6px",
            alignItems: "center",
            fontSize: "12px",
            fontWeight: 400,
            fontFamily: "Dm sans",
            color: "#0F172A",
            textTransform: "capitalize",
          }}>
            <VpnLockIcon style={{ height: "18px", width: "18px", color: "#355EE7", }} />
            {task.attributes.associated_lawyer_details.data.attributes.jurisdiction}
          </Typography>
        </TableCell>
        <TableCell
          style={{
            display: "flex",
            borderBottom: "none"
          }}
          key={index}
        >
          {task.attributes.associated_lawyer_details.data.attributes.legel_service_type?.slice(0,2)?.map((service, index) => (
            <Typography
              key={index}
              style={{
                marginLeft: '2px',
                width: 'auto',
                height: 'auto',
                fontSize: '11px',
                fontWeight: 400,
                display: 'flex',
                color: '#355EE7',
                backgroundColor: '#ECF1FF',
                borderRadius: '6px',
                padding: '2px 6px',
                fontFamily: 'Dm sans',
                marginTop: '3px',
                alignItems: 'center',
                gap: '4px',
              }}
            >
              <img
                src={Services}
                style={{
                  width: '14px',
                  height: '14px',
                }}
                alt=""
              />
              <span>{service.legal_service_name}</span>
            </Typography>
          ))}
              { task && task.attributes.associated_lawyer_details.data.attributes.legel_service_type?.length > 2 && (
                      <MuiThemeProvider theme={theme}>
                      <Tooltip
                        title={
                          <div  style={{ width:"100%"}} >
                            <div style={{display:"flex",gap:"20px",width:"100%"}}>
                              <div style={{display: "grid",gridTemplateColumns:" repeat(2, 1fr)",gap:"10px",width:"80%",marginTop:"40px"}}>
                                {task?.attributes.associated_lawyer_details.data.attributes.legel_service_type?.slice(2).map((service, index) => (
                                  <Chip
                                    key={index}
                                    label={service.legal_service_name}
                                    className="popup-chip"
                                    style={{ backgroundColor: '#ECF1FF ',
                                    color: '#355EE7 ',fontSize:"14px",textTransform:"capitalize",wordWrap: "break-word"}}
                                  />
                                ))}
                              </div>
                                    <div className="popup-image" style={{ justifyContent: "flex-end", display: "flex"}}>
                                    <img
                                  src={tooltipIcon}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    marginBottom:"1px",
                                  }}
                                  alt=""
                                />
                                    </div>
                            </div>
                          </div>
                        }
                      >
                        <Typography
                          style={{
                            color: "#355EE7",
                            backgroundColor: "#ECF1FF",
                            borderRadius: "6px",
                            padding: "2px 6px",
                            fontFamily: "Dm sans",
                            marginTop: "3px",
                            marginLeft: "2px",
                            width: "auto",
                            height: "auto",
                            fontSize: "11px",
                            fontWeight: 400,
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                          }}
                        >
                          <img
                            src={Services}
                            style={{
                              width: "14px",
                              backgroundColor: "none",
                              height: "14px",
                              marginRight: "4px",
                            }}
                            alt="Service"
                          />
                          <span>+{task?.attributes.associated_lawyer_details.data.attributes?.legel_service_type.length - 2}</span>
                        </Typography>
                      </Tooltip>
                    </MuiThemeProvider>
                    )}

        </TableCell>
        <TableCell
          style={{
            fontSize: "12px",
            fontWeight: 400,
            fontFamily: "Dm sans",
            color: "#0F172A;",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "100px",
            textTransform: "capitalize"
          }}
        >
          {task.attributes.associated_lawyer_details.data.attributes.years_of_experience} Years
        </TableCell>
        <TableCell
          style={{
            fontSize: "12px",
            fontWeight: 400,
            fontFamily: "Dm sans",
            color: "#0F172A;",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "100px",
            textTransform: "capitalize"
          }}
        >
          <Typography style={{ display: "flex", whiteSpace: 'nowrap', gap: "5px", alignItems: "center" }}>
            4.2
            <StarRatings
              rating={4.403}
              starSpacing="1px"
              starDimension="14px"
              starRatedColor="#F59E0B"
              starEmptyColor="#8C8C8C"
            />
          </Typography>
        </TableCell>
      </TableRow>
    ));
  };
   getBadgeCount = () => {
    const { selectedLawId, selectedCountry, searchYearExp } = this.state; 
  
    return [selectedLawId, selectedCountry, searchYearExp]
      .map(value => String(value)) 
      .filter(value => value.trim() !== '') 
      .length;
  };
 
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {optionsShow} = this.state; 
  
    
    
    return (
      <><MainDiv data-test-id="projectTemplatesProfiles" style={{
        maxHeight: this.state.mobileOpen ? '100vh' : '',
        overflow: this.state.mobileOpen ? "hidden" : ""
      }}>
        <Box className="sidebar">
          {this.drawer()}
          {this.buttonMenu()}
          {this.sidebarr()}
        </Box>
        {this.sideBarr2()}
        <Box className="rightbar">
          <Box className="topbox">
          <JobListing projectId={this.state.taskView.ids} closeLawyer={undefined} handleUpdateCall={()=>{}} handleClose={undefined} rejectClose={undefined} taskId={this.state.taskViewId} rejectDialog={false} lawyerDialog={false} handleNavigate={this.handleNavigateAllocator} acceptDialog={false} navigation={this.props.navigation} id={""}/>
            <Typography  data-test-id="backbtn" className="titleuser2">
            <ArrowBackIcon onClick={this.handleNavigationTaskList} style={{
                color: "blue",
                cursor: "pointer"
              }} /> Assign Lawyer
            </Typography>
          
         
          </Box>
          <Box className="topbox2">
            <Typography className="titleuser3">
              <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                <Link 
               onClick={this.handleNavigationTaskList} 
                style={{
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "#475569",
                  cursor: "pointer"
                }}  >
                Assigned Lawyers
                </Link>
                <Typography onClick={this.handleNavigateList}
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#0F172A"
                  }}
                  color="textPrimary"
                >
               Lawyers Catalogue
                </Typography>

              </Breadcrumbs>

            </Typography>

          </Box>
         <Box style={{
          position:"relative"
         }}>
          <Box style={{
            width: "92.5%",
            display: "flex",
            marginLeft: "60px",
            marginTop: "20px",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "10px"
          }}>

            <TextField
              onChange={this.handleSearchChange}
              variant="outlined"
              placeholder="Search by lawyer name "
              style={{
                width: "100%"
              }}
              InputProps={{
                style: {
                  height: '44px',
                  borderRadius: '12px',
                  fontSize: '16px',
                  fontWeight: 400,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{
                      color: "#BFBFBF",
                      width: "24px",
                      height: "24px"
                    }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end" style={{ position: 'relative' }}>
      <div style={{ position: 'relative' }}>
        <IconButton onClick={this.handleFilterPopupOpen}>
          <img src={filter} alt="Filter" style={{ width: '24px', height: '24px' }} />
        </IconButton>

        {this.getBadgeCount() > 0 && (
      <div style={{
        position: 'absolute',
        top: '4px', 
        right: '4px', 
        backgroundColor: '#355EE7', 
        width: '17px', 
        height: '17px', 
        borderRadius: '50%', 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white', 
        fontSize: '8px', 
      }}>
        {this.getBadgeCount()}
      </div>
    )}
      </div>
    </InputAdornment>
                ),
              }}
            />
            <button
            style={{
              backgroundColor: this.state.isAnyLawyerSelected ? "#355EE7" : "#D3D3D3", 
              color: this.state.isAnyLawyerSelected ? "white" : "#A9A9A9", 
              width: "175px",
              height: '44px',
              textTransform: "none" as "none",
              borderRadius: "8px",
              fontSize: "16px",
              fontWeight: "400",
              fontFamily: "DM Sans",
              cursor: "pointer",
              border: 'none',
             gap:"3px",
             display:"flex",
             justifyContent:"center",
             alignItems:"center",
            }}
             disabled={!this.state.isAnyLawyerSelected}  onClick={() => this.updateLawyers()} data-test-id="signup" className="landingSignupBtn"  >
              <GroupAddIcon/>  Assign Lawyer
            </button>
           
          </Box>
          {this.state.isFilterPopupOpen &&(  <Box
           className="filterpopupbox"      
            >
         Filters
<Box 
className="filterSearchBox" >
<Box className="selectBoxes">                    
<Select
      id="customSelect"
      data-test-id="countrySelect"
      className={`selectlegal customSelect`}
      placeholder="Legal Service Type"
      classNamePrefix="custom-scroll"
      onChange={this.handleSelected} 
      options={optionsShow.map(option => ({
        value: option.value,
        id:option.id,
        label: option.label,
      }))}
   
      inputValue={this.state.selectedOp} 
      onInputChange={this.handleSearchChange2} 
    />
                    </Box>
                    <Box className="selectBoxes">
                    <Select
  id="customSelect"
  data-test-id="countrySelect2"
  placeholder="Country"
  onChange={this.handleCountrySelected}
  className={`selectlegal customSelect`}
  classNamePrefix="custom-scroll"
  components={{
    DropdownIndicator: (props: DropdownIndicatorProps<any, any>) => (
      <components.DropdownIndicator {...props}>
        <SearchIcon style={{ fontSize: 24, color: '#BFBFBF' }} />
      </components.DropdownIndicator>
    ),
  }}
  options={this.state.countryData?.map((item) => ({
    value: item?.attributes?.name, 
    label: (
      <div style={{ display: 'flex', alignItems: 'center',gap:"1px"}}>
        <span style={{width:'17px',height:"auto",marginRight:"4px",display:"flex",alignItems:"center"}}>{item?.attributes?.emoji_flag}</span>
        <span style={{display:"flex",alignItems:"center"}}>  {item?.attributes?.name}</span>
      </div>
    ), 
  }))}
  inputValue={this.state.selectedCountry} 
   onInputChange={this.handleSearchCountry} 
/>
                    </Box>
                    <Box className="selectBoxes">
                      <Select
                       id="customSelect"
                       data-test-id="countrySelect2"
                       classNamePrefix="custom-scroll"
                       placeholder="Ratings"
                       className={`selectlegal customSelect`}
                      />
                    </Box>
                    <Box className="selectBoxes">
                      <Select
                        id="customSelect"
                        data-test-id="countrySelect2"
                        classNamePrefix="custom-scroll"
                        className={`selectlegal customSelect`}
                        placeholder="Years of Experience"
                        onChange={this.handleSelectedYear}
                      
                        options={this.state.experienceData?.map((item) => ({
                          value: item.name,
                          label: `${item.name} years`
                        }))}
                        inputValue={this.state.searchYearExp} 
                        onInputChange={this.handleSeachYearExp} 
                     />
                    
                    </Box>
</Box>
            </Box>)}
            <Box style={{
  display: "flex",
  alignItems: "center",  
  gap: "4px",  marginLeft:"60px"
}}>
  {this.state.selectedService && (
    <Chip
      label={this.state.selectedService}
      data-test-id="handleClick-0"
      onDelete={() => this.handleRemoveFilter("selectedService")}
      style={{
        color: "#355EE7",
        background: "#DCECFF",
        border: "none",
        marginTop: "24px", 
        width: "auto",
        display: "flex", 
        alignItems: "center", 
        justifyContent: "center",  
      }}
      variant="outlined"
    />
  )}

  {this.state.selectedCountryChip && (
    <Chip
    data-test-id="handleClick-1"
      label={this.state.selectedCountryChip}
      onDelete={() => this.handleRemoveCountry("selectedCountryChip")}
      style={{
        color: "#355EE7",
        background: "#DCECFF",
        border: "none",
        marginTop: "24px", 
        width: "auto",
        display: "flex",  
        alignItems: "center",  
        justifyContent: "center",  
      }}
      variant="outlined"
    />
  )}
   {this.state.searchYearExpChip && (
    <Chip
      label={this.state.searchYearExpChip}
      data-test-id="handleClick-2"
      onDelete={() => this.handleRemoveYear("searchYearExpChip")}
      style={{
        color: "#355EE7",
        background: "#DCECFF",
        border: "none",
        marginTop: "24px", 
        width: "auto",
        display: "flex",  
        alignItems: "center",  
        justifyContent: "center",  
      }}
      variant="outlined"
    />
  )}
</Box>
            
            </Box>
          <Box className="userdetailbox">
            <Typography style={{
              fontSize: "18px",
              fontFamily: "Dm sans",
              fontWeight: 700,
            }}>Lawyers</Typography>
            <TableContainer className="scrollable-box"
          
          
              style={{
                borderRadius: '12px',
                border: '1px solid #F0F0F0',
                marginTop: '25px',
                boxShadow: 'none',
              }}
            >
              <Table   stickyHeader style={{
                top:0,
                zIndex:100
              }}>
                <TableHead >
                  <TableRow>
                  
                    <TableCell
                      style={{
                        color: '#8C8C8C',
                        fontSize: '14px',
                        fontWeight: '500',
                        position: 'sticky',
          top: 0,
          backgroundColor: '#FFF', 
          zIndex: 100,
                      }}
                    >      <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                      fontFamily:"DM Sans" 
                    }}
                  >
                    <img src={tableIcon} />
                    Lawyer
                  </div>
                    </TableCell>
                    <TableCell
                      style={{
                        color: '#8C8C8C',
                        fontSize: '14px',
                        fontWeight: '500',
                        fontFamily:"DM Sans"
                 
                      }}
                    >
                      Jurisdiction
                    </TableCell>
                    <TableCell style={{
                      color: "#8C8C8C",
                      fontSize: "14px",
                      fontWeight: "500",
                      fontFamily:"DM Sans"
                   
                    }}>Legal Services</TableCell>
                    <TableCell style={{
                      color: "#8C8C8C",
                      fontSize: "14px",
                      fontWeight: "500",
                      fontFamily:"DM Sans"
                   
                    }}>Experience</TableCell>
                    <TableCell style={{
                      color: "#8C8C8C",
                      fontSize: "14px",
                      fontWeight: "500",
                      fontFamily:"DM Sans"
                     
                    }}>Rate</TableCell>
                   
                  
                  </TableRow>
                </TableHead>
            
          
                <TableBody  style={{
                    borderSpacing:0,
                }}>
             {this.renderTableRows()}
                </TableBody>
              
                </Table>
            </TableContainer>
          </Box>
        </Box>
        <Dialog
          PaperProps={{
            style: {
              width: '600px',
              maxWidth: '600px',
              height: "239px",
              borderRadius: "12px",
              padding: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }
          }} style={{}} open={this.state.openDialog} onClose={this.handleCloseDelete}
          aria-labelledby="responsive-dialog-title" >

          <Box style={{
            border: "1px solid #D1FAE5",
            width: "576px",
            height: "215px",
            borderRadius: "8px",

          }}>
            <DialogTitle id="responsive-dialog-title" style={{
              textAlign: 'right',
              padding: "7px"
            }}><CloseIcon style={{ cursor: 'pointer' }} onClick={this.handleCloseDelete} /></DialogTitle>
            <DialogContent
              style={{
                padding: "0px"
              }}
            >
              <DialogContentText  >
                <Typography style={{
                  backgroundColor: '#ffffff',
                  color: '#0F172A',
                  fontSize: '19px',
                  fontWeight: 400,
                  fontFamily: 'DM Sans',
                  marginBottom: '20px',
                }}>
                  <Box style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}>
                    <img src={right} className="imageBox2" alt="success" />
                  </Box>
                  <Typography style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "25px",
                    fontSize: "22px",
                    fontFamily: "Dm sans",
                    fontWeight: 400
                  }}>
                   
                    Lawyer assigned to task
                  </Typography>
                </Typography>
              </DialogContentText>
            </DialogContent></Box>


        </Dialog>


      </MainDiv>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const MainDiv = styled('div')({
  overflow:"visible",
  "& .customSelect .MuiSelect-placeholder":{
    color: "#BFBFBF",
    fontFamily: 'DM Sans',
    fontSize: "16px",
    fontWeight: 400,
  },
  "& .selectedUser": {
    border: '1px solid #F0F0F0', borderRadius: '10px', display: 'flex', flexDirection: 'column', gap: '10px',
    maxHeight: '200px',
    overflowY: 'auto',
    backgroundColor: " #FAFAFA",
    scrollbarWidth: 'thin',
    scrollbarColor: '#355EE7 #F0F0F0',
    fontFamily: "Dm sans",
    width: "97.5%",
    padding: "6px"
  },
  "& .drawerResp": {
    width: "100vh",
    maxWidth: "812px",
    padding: "16px",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    fontSize: "Dm sans",
    "@media (max-width:900px)": {
      width: "30vh",
    },
  },
  "& .scrollable-box": {
    height: '54.5%',
    overflowY: 'auto',
    marginBottom: "10px",
    flexGrow: 1,
    paddingRight: '0px',
    "@media (max-width:800px)": {
      display: 'inline-block',
    },
  }, "& .scrollable-box::-webkit-scrollbar": {
    width: "8px",
  },
  "& .scrollable-box::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },
  "& .scrollable-box::-webkit-scrollbar-thumb": {
    backgroundColor: "#91AAFF",
    borderRadius: '10px',
    border: '2px solid #f1f1f1',
  },
  "& .scrollable-box::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#5D85FF",
  },

"& .custom-scroll__menu-list": {
  maxHeight: "240px", 
  flexGrow: 1,
  paddingRight: '0px',
  overflowY: 'auto',
  marginBottom: "10px",
 
  "@media (max-width:800px)": {
    display: 'inline-block',
  },
  scrollbarWidth: "thin",
  scrollbarColor: "#ccc transparent"},

"& .custom-scroll__menu-list::-webkit-scrollbar" :{
  width: "8px",},

"& .custom-scroll__menu-list::-webkit-scrollbar-thumb":{
  backgroundColor: "#91AAFF",
  borderRadius: '10px',
  border: '2px solid #f1f1f1',
},
"& .custom-scroll__menu-list::-webkit-scrollbar-thumb:hover":{
  backgroundColor: "#5D85FF",
},
"& .custom-scroll__menu-list::-webkit-scrollbar-track" : {
  background: "#f1f1f1",
},

  "& .tableFont": {
    fontSize: "12px",
    fontWeight: 700,
    fontFamily: "Dm sans",
    display:"flex",
    alignItems:"center"
  },
  "& .custom-datepicker-input": {
    width: "100%",
    border: "1px solid #C3D1FF",
    borderRadius: "12px",
    fontSize: "16px",
    fontWeight: 400,
    outline: "none",
    height: "44px",
    padding: "10px",
  },
  "& .custom-datepicker-input.error": {
    border: "1px solid red"
  },

  "& .custom-datepicker-input::placeholder ": {
    color: '#BFBFBF',
    fontFamily: 'DM Sans',
    fontSize: "16px",
    fontWeight: 400
  },
  "& .offcanvascss": {
    position: 'fixed',
    top: '0px !important',
    bottom: '0px !important',
    left: '0% !important',
    width: '252px !important',
    height: '100vh !important',
    backgroundColor: '#fff',
    zIndex: 9999,
  },
  "& .offcanvasbackdrop": {
    position: "fixed",
    top: '0px',
    left: '252px',
    width: 'calc(100% - 252px)',
    height: '100vh',
    zIndex: 999,
    backgroundColor: '#000',
    opacity: 0.5,
  },
  width: '100%',
  height: '100%',
  display: 'flex',
  "@media (max-width:991px)": {
    display: "block",
  },
  "& .selectCountry": {
    width: '280px',
    border: '1px solid #C3D1FF', borderRadius: "12px",
    marginBottom: '8px', outline: 'none', backgroundColor: '#fff',
    fontSize: '16px', height: "48px",
    backgroundRepeat: 'no-repeat', backgroundPositionX: '95%',
    backgroundImage: 'url("data:image/svg+xml;utf8,<svg fill=\'%23c5cbc6\' height=\'20\' viewBox=\'0 0 24 24\' width=\'20\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M7 10l5 5 5-5z\'/></svg>")',
    fontWeight: 400, fontFamily: "DM Sans",
    backgroundPositionY: 'center', appearance: 'none',
    '&:hover': {
      border: '1px solid #C3D1FF', borderRadius: "12px"
    },
    "&:focus": {
      border: '2px solid #C3D1FF;', borderRadius: "12px"
    },
  },
  "& .selectlegal": {
    width: '100% !important',
    borderRadius: '12px', fontSize: '16px',
    fontWeight: 400,
    fontFamily: "DM Sans",
    height: "38px",
    color:"black",
    textWrap:"nowrap",

    "& .css-13cymwt-control": {
      borderColor: "#C3D1FF !important",
      border: "1px solid",
      borderRadius: "12px",
      fonSize: "16px",
      fontweight: 400,
      outline: "none",
      position: "relative",
      bottom: "3px",
      height: "44px"

    },
    "& .css-t3ipsp-control": {
      borderColor: "#C3D1FF !important",
      border: "1px solid",
      borderRadius: "12px",
      fonSize: "16px",
      fontweight: 400,
      outline: "none",
      position: "relative",
      bottom: "3px",
      height: "44px",
      boxShadow: "0px 0px 0px 0px"

    },
    "& .css-1u9des2-indicatorSeparator": {
      backgroundColor: "white"
    },
    "&:focus": {
      border: '1px solid #C3D1FF;', borderRadius: "12px"
    },
    "@media (max-width:500px)": {
      width: '100% !important',
      borderRadius: '12px', fontSize: '8px',
      fontWeight: 200,
      fontFamily: "DM Sans",
      height: "44px",
    },
  },

  "& .landingSignupBtn": {
    backgroundColor: "#355EE7",
    color: "white",
    width: "145px",
    height: '44px',
    textTransform: "none" as "none",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "DM Sans",
    cursor: "pointer",
    border: 'none',
    '&:hover': {
      border: '2px solid #B9C5FF',
      boxShadow: 'none !important'
    }
  },
  "& .document": {
    backgroundColor: "#355EE7",
    color: "white",
    width: "179px",
    height: '46px',
    textTransform: "none" as "none",
    borderRadius: "8px",
    padding: "10px",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "DM Sans",
    cursor: "pointer",
    border: 'none',

  },
  "& .landingLoginBtn": {
    backgroundColor: "#fff",
    width: "145px",
    height: '46px',
    textTransform: "none" as "none",
    borderRadius: "8px",
    padding: "10px",
    color: "#355ee7",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "DM Sans",
    cursor: "pointer",
    border: '1px solid #d9d9d9',
    boxShadow: 'none !important',
    '&:hover': {
      border: '2px solid #B9C5FF',
      boxShadow: 'none !important'
    }

  },
  "& .firstNamePlaceHolder": {
    fontSize: "16px",
    fontWeight: 400
  },
  "& .sidebar": {
    height: '100%',
    position: "fixed",

    margin: 0,
    padding: 0,
    top: '0',
    left: '0',
    zIndex: 9999,
    "@media (max-width: 1100px)": {
      width: "300px"
    },
    "@media (max-width: 991px)": {
      width: '100vw',
      height: '80px'
    }
  },
  "& .mainheader": {
    position: 'fixed',
    left: '350px',
    width: '100%'
  },
  "& .logtxtdialog": {
    backgroundColor: "#355EE7",
    color: '#ffffff',
    borderRadius: '8px',
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'DM Sans',
    marginLeft: '15px',
    border: 'none',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    '&:hover': {
      border: '2px solid #B9C5FF',
      boxShadow: 'none !important',
    },
  },
  "& .Header": {
    height: '50px',
    fontSize: '20px', padding: '20px',
  },
  "& .rightbar": {
    padding: "10px 0px",
    width: "100%",
    marginLeft: '253px',
    backgroundColor: "#fafafa",
    height: "auto",
    "@media (max-width: 1100px)": {
      padding: '20 40px', width: 'calc(100% - 300px)',
      marginLeft: '300px',
    },
    "@media (max-width: 991px)": {
      width: 'auto !important',
      marginLeft: '0px', marginTop: '50px',
      padding: "10px 50px",
    }
  },
  "& .filterpopupbox":{
    borderRadius: "12px",
    display: "flex",
    transform: "translate(-50%,1%)",
    zIndex: 1300,
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
    backgroundColor: "white",
    height: "90px",
    padding:"24px",
    width: "79.7%",
    position:"absolute",
    left:"45%",
    flexDirection:"column",
    fontFamily:"Dm sans",
    fontWeight:500,
    color:"355EE7",
    "@media (max-width: 1876px)": {
      width: "79.6%"},
    "@media (max-width: 1844px)": {width: "79.2%",
    },
    "@media (max-width: 1800px)":
     {
      width: "79%"
    },

    "@media (max-width: 1795px)": 
    {
      width: "77%",},
    "@media (max-width: 1596px)": {
      width: "79.3%",
      height: "90px",
    },
    "@media (max-width: 1500px)": 
    {
      width: "76.4%",
      fontFamily:"Dm sans",
    },
    
    "@media (max-width: 1440px)": {
      width: "75.7%",},
    "@media (max-width: 1400px)": {
      width: "75.5%",
    },
    "@media (max-width: 1385px)": {
      width: "75.5%",
      display: "flex",
    },
    "@media (max-width: 1365px)": {
      width: "74.5%",
    },
    "@media (max-width: 1300px)": {
      width: "73.7%",
    },
    "@media (max-width: 1263px)": {
      width: "73%",
      borderRadius: "12px",
    },
    "@media (max-width: 1200px)": {
      width: "71.7%",
    },
    "@media (max-width: 1120px)": {
      width: "70.1%",
    },
    "@media (max-width: 1100px)": {
      width: "67.7%",
    },
    "@media (max-width: 1000px)": {
      width: "71.7%",
    },
    "@media (max-width: 991px)": {
      width: "69.7%",
    },
    "@media (max-width: 1025px)": {
      width: "66.7%",
    },
    "@media (max-width: 885px)": {
   height:"242px"
    }
  },
  "& .filterSearchBox":{
    display:"flex",
    gap:"67px",
    alignItems:"center",
    justifyContent:"center",
    "@media (max-width: 1167px)": {
      gap:"22px",
    },
    "@media (max-width: 1050px)": {
      gap:"15px",
    },
    "@media (max-width: 1025px)": {
      gap:"7px",
    },
    "@media (max-width: 885px)": {
     display:"flex",
     flexDirection:"column"
    }
  },
  "& .selectBoxes" :{
    display: 'flex', alignItems: 'center', position: 'relative', width: '280px', marginTop: "10px" ,
    "@media (max-width: 500px)": {
      width:"200px"
       }
  },
  "& .userdetailbox": {
    width: '90%',
    padding: "20px",
    marginTop: "30px",
    backgroundColor: "#ffffff",
    height: "auto",
    marginLeft: "61px",
    borderRadius: "20px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
    marginBottom: "15px",
    "@media (max-width: 1424px)": {
      width: '89%',
      height: "auto",
      marginLeft: "51px",
      borderRadius: "20px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
      padding: "20px",
      backgroundColor: "#ffffff",
    },
    "@media (max-width: 1366px)": {
      width: '87%',
      padding: "20px",
      backgroundColor: "#ffffff",
      marginLeft: "61px",
      borderRadius: "20px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
      height: "auto",
    },
    "@media (max-width: 1280px)": {
      width: '87%',
      height: "auto",
      marginLeft: "61px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
      borderRadius: "20px",
      fontSize: "20px",
      padding: "20px",
      backgroundColor: "#ffffff",

    },
    "@media (max-width: 780px)": {
      width: '87%',
      fontSize: "20px",
      height: "auto",
      marginLeft: "31px",
      borderRadius: "20px",
      padding: "20px",
      backgroundColor: "#ffffff",

      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
    },
    "@media (max-width: 600px)": {

      height: "auto",
      marginLeft: "24px",
      borderRadius: "20px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
      width: '87%',
      fontSize: "20px",
      padding: "20px",
      backgroundColor: "#ffffff",
    },
    "@media (max-width: 520px)": {
      width: '87%',
      height: "auto",
      marginLeft: "24px",
      borderRadius: "20px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
      fontSize: "20px",
      padding: "20px",
      backgroundColor: "#ffffff",
    },
  },
  "& .profilebox": {
    height: '156px',
    display: 'flex', borderBottom: '1px solid #D9D9D9',
    "@media (max-width: 450px)": {
      height: '120px',
    },
  },
  "& .profileImg": {
    display: 'flex',
    justifyContent: "center", width: "80%",
  },
  "& .profiletext": {
    fontSize: '16px',
    fontWeight: 400, color: '#8C8C8C', fontFamily: 'DM Sans',
  },
  "& .userimg": {
    height: '120px',
    width: '120px',
    border: '4px solid #ECF1FF',
    borderRadius: '50%',
    "@media (max-width: 450px)": {
      height: '80px',
      width: '80px',
    },
  },
  '& .topbox': {
    display: 'flex',
    width: "100%",
    height: '70px',
    justifyContent: 'space-between',
    overflow:"visible",
    "@media (max-width: 991px)": {
      width: "auto !important",
    },
  },
  '& .topbox2': {
    display: 'flex',
    width: "100%",
    alignItems:'center',
    height: '57px',
    justifyContent: 'space-between',
    overflow:"visible",
    "@media (max-width: 991px)": {
      width: "auto !important",
    },
  },
  "& .titleuser": {
    height: '30px',
    width: '230px',
    fontSize: '22px',
    fontWeight: 400,
    fontFamily: "DM Sans",
    color: '#000',
    "@media (max-width: 450px)": {
      width: '200px',
      fontSize: '16px',
    },
  },
  "& .titleuser2": {
    height: '30px',
    width:"100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "13px",
    fontSize: '27px',
    fontWeight: 700,
    fontFamily: "DM Sans",
    color: '#0F172A',
    marginLeft: "60px",
    marginTop: "13px",
    "@media (max-width: 450px)": {
      width: '200px',
      fontSize: '16px',
    },
  },
  "& .titleuser3": {
    height: '30px',
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "5px",
    width: '100%',
    fontSize: '22px',
    fontWeight: 400,
    fontFamily: "DM Sans",
    marginLeft: "97px",
    color: '#000',
    "@media (max-width: 450px)": {
      width: '200px',
      fontSize: '16px',
    },
  },
  "& .titlbtn": {
    height: '44px',
    fontSize: '14px', fontWeight: 400, color: '#355EE7', fontFamily: 'DM Sans',
    width: '163px', border: '1px solid #355EE7', borderRadius: '8px', padding: '10px 12px',
    "@media (max-width: 450px)": {
      height: '40px',
      width: '140px', padding: '7px 9px',
    },
  },
  "& .detailsbox": {
    display: 'flex', marginTop: '20px', paddingBottom: '40px',
    borderBottom: '1px solid #D9D9D9', "@media (max-width: 600px)": {
      display: 'block'
    },

  },
  "& .detailsbox2": {
    display: 'flex', marginTop: '20px',
    "@media (max-width: 600px)": {
      display: 'block'
    },

  },
  "& .personaldetailtext": {
    width: '15%', fontSize: '20px', fontWeight: 700,
    fontFamily: 'DM Sans', color: 'black',
    "@media (max-width: 600px)": {
      width: '100%', marginBottom: '20px'
    },
  },
  "& .personalform": {
    width: '88%',
    position: 'relative',
    "@media (max-width: 600px)": { width: '100%' },
  },
  "& .fullName": {
    width: '100%', display: 'flex', paddingBottom: '30px',
    "@media (max-width: 650px)": {
      display: 'block', width: '100%'
    }
  },
  "& .firstname": {
    width: "49%", marginRight: "10px",
    "@media (max-width: 650px)": {
      width: "91% !important", marginBottom: '15px',
    },
  },
  "& .lastname": {
    width: "49%",

    "@media (max-width: 650px)": { width: "91% !important", },
  },
  "& .lawFirm": {
    width: "99%", marginBottom: '15px',
  },
  "& .country": {
    width: "91%", marginBottom: '15px',
  },
  "& .PhoneNumer": {
    width: "91%", marginBottom: '15px',
  },
  "& .Email": {
    width: "91%", marginBottom: '15px',
  },
  "& .Password": {
    width: "91%", marginBottom: '15px', height: "28px",
  },
  "& .passtext": {
    color: "#434343", fontSize: '14px',
    fontWeight: 400, fontFamily: 'DM Sans',
  },
  "& .changepasstext": {
    color: "#6200EA", fontSize: '14px',
    fontWeight: 700, fontFamily: 'DM Sans', cursor: 'pointer', marginTop: '4px',
  },
  "& .accountsbox": {
    display: 'flex', marginTop: '20px',
    "@media (max-width: 600px)": {
      display: 'block',
      marginTop: '10px',
    },
  },
  "& .accountdetailtext": {
    width: '20%', color: "#8C8C8C",
    fontSize: '16px', fontWeight: 400, fontFamily: 'DM Sans',
    "@media (max-width: 600px)": {
      width: '100%',
      marginBottom: '20px'
    },
  },
  "& .accountsactions": {
    width: '84.3%', marginBottom: '40px',
    display: "flex", justifyContent: "flex-end",
    "@media (max-width: 600px)": { width: '100%', },
  },
  "& .logouttxt": {
    display: 'flex', alignItems: 'center', margin: '0px 0px 20px 0px', gap: "20px",
    color: "#434343", fontSize: '16px', fontWeight: 400, fontFamily: 'DM Sans',
    cursor: 'pointer',
  },
  "& .deletetxt": {
    display: 'flex', alignItems: 'center',
    margin: '20px 0px', color: "#DC2626", fontSize: '16px', fontWeight: 400, fontFamily: 'DM Sans',
    cursor: 'pointer',
  },
  "& .errorOutline": {
    '& .MuiFormControl-root': {
      '& fieldset': {
        borderColor: 'red 1px solid',
      },
    },
  },
  "& .MuiInputBase-input": {
    padding: '18.5px 14px',
  },
  "& .tableContainer": {
    marginTop: " theme.spacing(4)",
    "& .MuiPaper-elevation1": {
      boxShadow: "none !important"
    }
  },

  "& .tableHeader": {
    color: "#8C8C8C",
    fontSize: "12px",
    fontWeight: "500"
  },
  "& .chipNew": {
    backgroundColor: "#D1FAE5",
    color: "#059669",
    fontWeight: 700,
    fontSize: "10px"
  },
  "& .chipPending": {
    backgroundColor: "#DCECFF",
    color: "#355EE7",
    fontWeight: 700,
    fontSize: "10px"
  },
  "& .chipInProgress": {
    backgroundColor: "#FEF3C7",
    color: "#D97706",
    fontWeight: 700,
    fontSize: "10px"
  },
  "& .chipClosed": {
    backgroundColor: "#FEE2E2",
    color: "#DC2626",
    fontWeight: 700,
    fontSize: "10px"
  },
  "& .chipStatus": {
    backgroundColor: ' #FAFAFA',
    width: '164px',
    height: '22px',
    borderRadius: '40px',
    fontWeight: 700,
    fontSize: "10px",
    padding: '10px 8px 8px 8px',
    display: "flex",
    justifyContent: "flex-start"
  },
  "& .iconButton": {
    width: "24px",
    height: "24px",
    color: "#355EE7"
  },
  "& .iconButton2": {

    height: "24px",
    width:"0px",
    color: "#BFBFBF"

  },
  "& .drawer": {
    width: "50%",
    minWidth: "812px",
  },
  "& .closeButton": {
    marginLeft: "auto",
  },
  "& .calendarIcon": {
    verticalAlign: "middle",
  },
})


const DrawerBox = styled('div')({
  minHeight: '100vh',
  width: "252px",
  borderRight: '1px solid #E2E8F0',
  "& .logobox": {
    display: 'flex', justifyContent: "start",
    height: "76px",
    padding: '17px 117px 17px 35px'
  },
  "& .logoimag":
  {
    height: '42px',
    width: '118px',
  },
  "& .settingbox":
  {
    display: 'flex',
    height: "65px",
  },
  "& .MainListBox": {
    padding: '0px 16px 8px 16px',
    position: 'relative'
  },
  "& .listbox": {
    height: '56px',
    borderRadius: '8px',
    "&:hover": {
      backgroundColor: "#ECF1FF",
      color: "#355EE7",
      "& .icondrawer2":
      {
        color: "#355EE7",
      },
    },
  },
  "& .listbox1": {
    height: '56px',
    backgroundColor: '#ECF1FF',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: '8px',
    marginBottom: '10px'
  },
  "& .icondrawer1": {
    marginBottom: '7px',
    marginLeft: '32px',
    color: '#355EE7'
  },
  "& .icondrawer2": {
    marginLeft: '32px',
    marginBottom: '2px',
  },
  "& .listtile1": {
    fontSize: "12px",
    color: '#355EE7',
    fontFamily: 'Inter',
    fontWeight: 700,
    cursor: 'pointer',
    marginRight: '38px'
  },
  "& .listtile":
  {
    fontSize: "12px",
    fontFamily: 'Inter',
    fontWeight: 700,
    cursor: 'pointer',
    marginLeft: '10px'
  },
  "& .userProfile": {
    display: "flex",
    width: '252px',
    position: 'absolute',
    bottom: '10px',
    left: '32px'
  },
  "& .imageBox": {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    border: '1px solid #F0E5FF',
    marginRight: '10px',
  },
  "& .imageBox2": {
    width: '90px',
    height: '90px',
    borderRadius: '50%',
  },
  "& .userName": {
    fontFamily: 'DM Sans',
    fontSize: '14px',
    fontWeight: 700,
    color: '#0F172A'
  },
  "& .userContent": {
    fontFamily: 'DM Sans',
    fontSize: '11px',
    fontWeight: 400,
    color: '#8C8C8C'
  },
  "@media (max-width: 1100px)":
  {
    zIndex: 999,
    width: '252px',
  },
  "@media (max-width: 991px)":
  {
    zIndex: 999,
    width: '252px',
    display: 'none',
  },
});
const ButtonBox = styled('div')(
  {
    display: 'none',
    "@media (max-width: 991px)":
    {
      width: '100%',
      display: 'block', minHeight: '70px',
      "& .buttonbox":
      {
        height: '70px', width: '100vw'
      },

      "& .iconbtn":
      {
        marginTop: '10px', marginLeft: '10px'
      }
    }
  })
// Customizable Area End
