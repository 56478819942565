import React from "react";
// Customizable Area Start
import CompanyInformationController, { Props } from "./ComapnyInformationController.web";
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import { styled } from '@material-ui/core/styles';
import { logo } from "./assets";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import InfoIcon from '@material-ui/icons/Info';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { Box, Typography, Switch } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
// Customizable Area End


export default class NotificetionWeb extends CompanyInformationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  drawer = () => (
    <DrawerBox style={{ display: this.state.mobileOpenNotification ? 'block' : '' }}>
      <div className="toolbar" />
      <Divider />
      <div className="logobox">
        <img src={logo.default} alt="logos3" className="logoimag" />
      </div>
      {this.state.mobileOpenNotification && <CloseIcon style={{ position: 'fixed', top: '30px', left: '260px', cursor: 'pointer' }} onClick={this.handleclosesidebar} />}
      <div className="settingbox" onClick={this.handleDashboard}>
        <ChevronLeftIcon className="lefticon" />
        <Typography className="settingtext" style={{cursor:'pointer'}}>Setting</Typography>
      </div>
      <List>
        <ListItem className="listbox" onClick={this.handleUserProfile}>
          <ListItemIcon><PermIdentityIcon className="icondrawer1" /></ListItemIcon>
          <ListItemText className="listtile">
            User Profile
            <Typography className="listtext">
              Settings related to your personal information and account credentials
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem className="listbox" onClick={this.handleCompanyInfo}>
          <ListItemIcon><InfoIcon className="icondrawer2" /></ListItemIcon>
          <ListItemText className="listtile">
            Company information
            <Typography className="listtext">
              View Digip’s Terms and Conditions and Privacy Policy
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem className="listbox1" onClick={this.handleNotifications}>
          <ListItemIcon><NotificationsIcon className="icondrawer3" /></ListItemIcon>
          <ListItemText className="listtile1">
            Notifications
            <Typography className="listtext">
              All settings related to notifications
            </Typography>
          </ListItemText>
        </ListItem>
      </List>
    </DrawerBox>
  )
  // Customizable Area End

  render() {
    // Customizable Area Start
    const buttonMenu = (
      <ButtonBox className="buttonbox">
        <IconButton
          className="iconbtn"
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={this.handleDrawerToggle}
          style={{ marginRight: '24px', color: '#000' }}
        >
          <MenuIcon />

        </IconButton>
      </ButtonBox>
    )
    return (
      <>
        <MainDiv data-test-id="SettingUserProfile"
          style={{
            maxHeight: this.state.mobileOpenNotification ? '100vh' : '',
            overflow: this.state.mobileOpenNotification ? "hidden" : ""
          }}>
          <Box className="sidebar">
            {this.drawer()}
            {buttonMenu}
            {this.state.mobileOpenNotification &&
              <>
                <div className="offcanvascss" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                  {this.drawer()}
                </div>
              </>
            }
          </Box>
          {this.state.mobileOpenNotification && <div className="offcanvasbackdrop" onClick={this.handleclosesidebar}></div>}
          <Box className="rightbar">
            <Box className="notifcationbox">
              <Typography className="TitleNotification">Notification settings</Typography>
              <Box className="notificationlist">
                <Box className="list">
                  <Typography className="contenttxt">General notifications</Typography>
                  <CustomSwitch
                    defaultChecked
                    color="primary"
                    name="checkedB"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </Box>
                <Box className="list">
                  <Typography className="contenttxt">Enquiry notifications</Typography>
                  <CustomSwitch
                    color="primary"
                    name="checkedB"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </Box>
                <Box className="list">
                  <Typography className="contenttxt">Priority notifications</Typography>
                  <CustomSwitch
                    color="primary"
                    name="checkedB"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </MainDiv>    </> );
    // Customizable Area End
  }}

// Customizable Area Start
const MainDiv = styled('div')(
  {
  width: '100%', height: '100%',
  display: 'flex',
  "@media (max-width:991px)": 
  {
    display: "block",
  },
  "& .sidebar": {
    width: '350px',height: '100vh',
    position: 'fixed',
    left: '0',
    borderRight: "1px solid #C3D1FF",
    top: '0',
    "@media (max-width: 1100px)": 
    {
      width: "300px"
    },
    "@media (max-width: 991px)": {
      width: '100vw',height: '80px'
    }
  },
  "& .rightbar": {
    width: 'calc(100% - 350px)',
    marginLeft: '350px',
    padding: "20px 70px",
    marginTop: '100px',
    "@media (max-width: 1100px)": 
    {
      padding: '20 40px',
      marginLeft: '300px',
      width: 'calc(100% - 300px)',
    },
    "@media (max-width: 991px)": {
      marginLeft: '0px',
      width: 'auto !important',
      padding: "10px 50px",
      marginTop: '50px',
    },
    "@media (max-width: 450px)": {
      marginLeft: '0px',
      width: 'auto !important',
      padding: "10px 30px",
      marginTop: '80px',
    }
  },
  "& .offcanvascss": {
    top: '0px !important',
    position: 'fixed',
    left: '0% !important',
    bottom: '0px !important',
    height: '100vh !important',
    width: '300px !important',
    backgroundColor: '#fff',
    zIndex: 9999,
  },
  "& .offcanvasbackdrop": {
    top: '0px',
    position: "fixed",
    width: 'calc(100% - 300px)',
    left: '300px',
    height: '100vh',backgroundColor: '#000',
    opacity: 0.5,zIndex: 999,
  },
  "& .TitleNotification": {
    fontSize: "22px",fontWeight: 400,
    color: '#000',
    fontFamily: "DM Sans",
    "@media (max-width: 991px)": 
    {
      paddingTop: "10px",
    },
    "@media (max-width: 450px)": {
      paddingTop: "0px",
    }
  },
  "& .notifcationbox": {
    paddingLeft: "50px",
    "@media (max-width: 991px)": 
    {
      paddingLeft: "30px",
    }
  },
  "& .notificationlist": {
    marginTop: '65px',
    "@media (max-width: 991px)": 
    {
      marginTop: '50px',
    }
  },
  "& .list": {
    justifyContent: "space-between",
    display: 'flex',
    alignItems: "center",
    borderBottom: '1px solid #E2E8F0',
    padding: '16px 8px'
  },
  "& .contenttxt":{
    fontWeight:400,
    fontSize:'16px',
    fontFamily: "DM Sans",
    color:'#434343',
  },

})

const DrawerBox = styled('div')(
  {
    minHeight: '100vh',
  width: "350px",
  "& .logoimag": {
    height: '42px',
    width: '118px',
  },
  "& .logobox": {
    marginTop: '20px',
    height: "76px",display: 'flex',
    marginLeft: '20px',
    justifyContent: "start",
  },
  "& .settingbox": {
    display: 'flex',
    height: "65px",
    cursor:"pointer"
  },
  "& .settingtext": {
    color: '#262626',cursor:"pointer",fontSize: '22px',
    fontWeight: 400,fontFamily: 'DM Sans',
    marginLeft: '6px',
  },
  "& .lefticon": {
    marginTop: '4px',
    cursor:"pointer",
    marginLeft: '30px',
  },
  "& .listbox": {
    height: '110px',
    "&:hover": {
      color: "#355EE7",
      backgroundColor: "#DCECFF",
      "& .icondrawer2": {
        backgroundColor: "#DCECFF",
        color: "#355EE7",
      },
      "& .icondrawer1": 
      {
        color: "#355EE7",
        backgroundColor: "#DCECFF",
      },
    },
  },
  "& .listbox1": {
    height: '110px',
    backgroundColor: '#DCECFF',
  },
  "& .icondrawer1": {
    marginBottom: '45px',
    marginLeft: '18px',
  },
  "& .icondrawer2": {
    marginBottom: '45px',
    marginLeft: '18px',
  },
  "& .icondrawer3": {
    marginLeft: '18px',
    color: "#355EE7",
    marginBottom: '25px',
  },
  "& .listtext": {
    color: '#334155',
    fontWeight: 400,
    fontSize: '14px',fontFamily: 'DM Sans'
  },
  "& .listtile1": {
    fontSize: "16px",
    color: '#355EE7',
    fontFamily: 'DM Sans',
    fontWeight: 400,
    cursor:'pointer'
  },
  "& .listtile": {
    fontSize: "16px",
    fontFamily: 'DM Sans',
    fontWeight: 400,
    cursor:'pointer'
  },
  "@media (max-width: 1100px)": {
    zIndex: 999,
    width: '300px',
  },
  "@media (max-width: 991px)": {
    zIndex: 999,
    width: '300px',
    display: 'none'
  },
});

const ButtonBox = styled('div')({
  display: 'none',
  "@media (max-width: 991px)": {
    display: 'block', minHeight: '70px',
    width: '100%',
    "& .buttonbox": 
    {
      width: '100vw',
      height: '70px',
    },
    "& .iconbtn": 
    {
      marginLeft: '10px',
      marginTop: '10px',
    }
  }
})
const CustomSwitch = styled(Switch)(
  {
"& .MuiSwitch-switchBase":
{
  color: "#ECF1FF",
},
"& .MuiSwitch-colorPrimary.Mui-checked":
{
  color:"#355EE7"
}
})
// Customizable Area End

