import { IBlock } from "../../../framework/src/IBlock";

import { Message } from "../../../framework/src/Message";

import { BlockComponent } from "../../../framework/src/BlockComponent";

import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";


// Customizable Area End


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  mobileOpen: boolean;
  open: boolean;
  passwordError1: string,
  oldpassword1:string,
  opendelete: boolean;
  openchangepass: boolean;
  oldchangepassword: boolean;
  changepassword: boolean;
  showPasswordCriteria: boolean;
  errormsg: string;
  userdata: {
    first_name: string;
    last_name: string;
    email: string;
    country_code: string;
    jurisdiction: string;
    firm: string;
    full_phone_number: string;
    profile_image: {
      url: string
    };
  };
  componentId: string;
  TokenMain: string | undefined;
  loadingImg:boolean;
  userDetails: {data:{attributes:{user_role:string}}};
  navigationlawyer:string;
  error_shown:boolean;
  popUpShow:boolean;
  responseShow:{
    success:string,
    error:string},
  DeletePop:boolean,
  noUserData:string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  ChangepasswordApiCallId: string = "";
  DeleteApiCallId: string = "";
  getUserProfileApiCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      mobileOpen: false,
      open: false,
      passwordError1: "",
      opendelete: false,
      oldpassword1:'',
      openchangepass: false,
      oldchangepassword: false,
      changepassword: false,
      showPasswordCriteria: false,
      errormsg: "",
      userdata: {
        first_name: "",
        last_name: "",
        email: "",
        country_code: "",
        full_phone_number: "",
        jurisdiction: "",
        firm: "",
        profile_image: {
          url: ''
        }
      },
      componentId: '1',
      TokenMain: "",
      loadingImg:false,
      navigationlawyer:"",
      error_shown:false,
      userDetails: {data:{
      attributes:{
        user_role:""
      }
      }},
      popUpShow:false,
      responseShow:{
        success:"",
        error:""},
      DeletePop:false,
      noUserData:""
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.ChangepasswordApiCallId) {
      if(responseJson?.errors){
        this.asyncChangeApiMethod1(responseJson?.errors[0]?.profile)
      }
      
      if(responseJson?.message){
        this.setState({ popUpShow:true,responseShow:{error:"",success:responseJson.message},
          passwordError1: "",oldpassword1:'', openchangepass: false, errormsg: "", showPasswordCriteria: false ,error_shown:false })
          this.setState({changepassword:false,oldchangepassword:false,})
      }
    }

    if (apiRequestCallId === this.DeleteApiCallId) 
      
      {
        this.asyncDeleteApiMethod1(responseJson?.errors,responseJson?.meta?.message)
      
    }
    if (apiRequestCallId === this.getUserProfileApiCallId) {

      this.handleRoute(responseJson)

    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {

    this.getUserProfile()
    
  }

  asyncChangeApiMethod1=(errors:string)=>{
    if (errors) {
      this.setState({ errormsg: errors })
    } 
    if (errors=="New password cannot match with old password"){
      this.setState({ passwordError1: errors })
    }
    if (errors =="Account not found"){
      this.setState({popUpShow:true,responseShow:{error:errors,success:""}})
    }
  }
  asyncDeleteApiMethod1=(errors:string,message:string)=>{
    if (errors) {
      this.setState({ opendelete: false, popUpShow:true,responseShow:{error:errors,success:""}});
    }

    else {
      this.setState({ opendelete: false ,popUpShow:true,responseShow:{error:"",success:message},DeletePop:true});
      removeStorageData("token");
      removeStorageData("userdetails");

    }
  }

  handleRoute = (responseJson:any) => {
    if (responseJson?.errors) {
      this.setState({popUpShow:true,
        responseShow:{error:responseJson?.errors?.[0]?.token,
          success:""}}) 
      if(responseJson?.errors?.[0]?.token === "Invalid token"){
        this.handleclientLawyer()
      }

      }else{
        this.setState({ userdata: responseJson?.user?.data?.attributes,loadingImg:true,})
      }
  }

  handleclientLawyer = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLogin");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
  }

  handleRemoveError1=()=>{
    this.setState({passwordError1:""})
  }
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: true });
  }

  handleIncrease=()=>{
    this.setState({passwordError1:""})
  }
 

  handleclosesidebar = () => 
    {this.setState({ mobileOpen: false });

  }

  showoldpassicon = () => 
    {
    this.setState({ oldchangepassword: !this.state.oldchangepassword })

  }


  showchangepassicon = () => { this.setState({ changepassword: !this.state.changepassword });
  }

  handleClickOpen = () => {this.setState({ open: true })

  };

  handleClose = () => 
    { this.setState({ open: false });

  };
  handleErrorBox=(a:boolean,b:any,c:string)=>{
    return a?b:c
  }
  handleErrorChange=(errors:{ password?: string; email?: string })=>{
    if(errors.password){
    this.setState({error_shown:true})
    }
    else{
      this.setState({error_shown:false})
    }
  }
  handleFocusError=()=>{
    this.setState({ showPasswordCriteria: true , error_shown:false })
  }


  handleClickOpendelete = () =>
     { this.setState({ opendelete: true });
  };

  handleClickOpenchangepass = () => 
    {
    this.setState({ openchangepass: true });

  };


  handleCloseDelete = () => {
    this.setState({ opendelete: false });
  };

  handleClosechangepass = () => {
    this.setState({ openchangepass: false, errormsg: "", showPasswordCriteria: false, error_shown:false,oldchangepassword:false,changepassword:false });
  };
  handlelogout = () => {
    removeStorageData("token");
    removeStorageData("roleData");
    window.location.href = "/email-account-login";
  }

  handleLogIn = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);

    this.send(message);
  }

  handleSetting = async() => {
    let user_role = null;
    if (this.state.navigationlawyer=="lawyer") {
      user_role="lawyer";
    }
    let navigationTarget = 'DashBoard';
  
    if (user_role === 'lawyer') {
      navigationTarget = 'LawyerDashboard';
    }
    const message1: Message = new Message(getName(MessageEnum.NavigationMessage));
    message1.addData(getName(MessageEnum.NavigationTargetMessage),
    navigationTarget);
    message1.addData(getName(MessageEnum.NavigationPropsMessage),this.props);

    this.send(message1);
  }

  handleCompanyInfo1 = () => 
    {const message1: Message =
      new Message(getName(MessageEnum.NavigationMessage));


    message1.addData(getName(MessageEnum.NavigationTargetMessage),
      "CompanyInformation");

    message1.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);

    this.send(message1);
  }

  handleNotifications1 = () => 
    {
    const Notimessage: Message
      = new Message(getName(MessageEnum.NavigationMessage));

    Notimessage.addData(getName(MessageEnum.NavigationTargetMessage),
      "SettingsNotification");

    Notimessage.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);

    this.send(Notimessage);

  }

  handleEditProfile1 = () => 
    {
    const Notimessage: Message
      = new Message(getName(MessageEnum.NavigationMessage));

    Notimessage.addData(getName(MessageEnum.NavigationTargetMessage),
      "CustomisableUserProfile");

    Notimessage.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);

    this.send(Notimessage);
  }


  handleUserProfile1 = () => 
    {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));

    message.addData(getName(MessageEnum.NavigationTargetMessage), "Settings");


    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(message);

  }
  handleStateChange=(signup:string,login:string)=>{
    this.setState({
      navigationlawyer: signup || login
    })
  }

  handleChangePassword1 = async (values: { oldpassword: string, password: string }) => {

    const header = {
      "Content-Type": "application/json",
      "token": this.state.TokenMain
    };


    const httpBody = {
      "data": {
        "current_password": values.oldpassword,
        "new_password": values.password
      }
    };

    this.setState({oldpassword1:values.password})


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.ChangepasswordApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_profile/passwords"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handleDeleteUser1 = async () => 
    {
    const header = {
      "Content-Type": "application/json",
      "token": this.state.TokenMain
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.DeleteApiCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteUserApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiDeleteMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  };
  determineMainToken1 = (
    metaSignUpToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } },
    metaLoginToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } }
  ): { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } } | null => {
    if (metaSignUpToken?.data?.id) {
      if (metaLoginToken?.id && metaSignUpToken.data.id !== metaLoginToken.id) {
        return metaLoginToken;
      }
      return metaSignUpToken;
    }

    if (metaLoginToken?.id) {
      return metaLoginToken;
    }

    return null; 
  };

  getUserProfile = async () => {
    const signupTokens: string = await getStorageData("userdetails");
    
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);
    this.handleStateChange(metaSignUpToken?.data?.attributes?.user_role,metaLoginToken?.user_role)

    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);

    const header: { "Content-Type": string; token: string | undefined } = {
      "Content-Type": "application/json",
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getUserProfileApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUserDataEndPoint}${maintoken?.id || maintoken?.data?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ TokenMain: maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token });
  };

  // Customizable Area End
}
